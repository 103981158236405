import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import { inject, observer } from "mobx-react";
import purchaseService from "./Purchase.service";
import { format } from "../../libs/react-mpk/services/number.service";
import {
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
  TextField,
  Button,
  Text,
} from "react-md";
import {
  defaultQueryParameter,
  defauultQuery,
  sort,
} from "../../libs/react-mpk/config/constant";
import { toast } from "../../libs/react-mpk/services";

const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const Purchase = ({
  className = "",
  showCommandbar = true,
  navigationStore,
  modalStore,
  envStore,
  ...props
}) => {
  const outletId = props.match.params.outletId;
  const npwpHeadId = props.match.params.npwpHeadId;
  const baseId = "purchase-table";
  const date = new Date();

  const [query2, setQuery2] = useState(
{
      tanggalPembelianAwal: "",
      tanggalPembelianAkhir: "",
      tanggalPenerimaanAwal: "",
      tanggalPenerimaanAkhir: "",
    }
  );

  const updatePembelian = (item) => {
    modalStore.showConfirm({
      title: t.translate("words.submit"),
      children: t.translate("words.confirmSelesai"),
      onSubmit: async(callback) => {
        try {
          await purchaseService.updatePembelian(item.id);
          document.getElementsByClassName('mdi-reload')[0].click() 
          callback("success", false);
        } catch (error) {
          callback(error, true, false);
        }
        callback();
      },
    });
  };

  const changeDate = (e) => {
    setQuery2({ ...query2, [e.target.name]: e.target.value });
    console.log(query2);
  };

  return (
    <>
      <TableWrapper
        baseId="purchase-table"
        title={t.translate("modules.pembelian.title")}
        className={className}
        hintMessage={t.translate("modules.pembelian.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.pembelian.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        extraFilter={[
        
                <TextField
                  id="startDatePembelian"
                  label="Tanggal Pembelian Awal"
                  type="date"
                  name="tanggalPembelianAwal"
                  key="tanggalPembelianAwal"
                  value={query2.tanggalPembelianAwal}
                  onChange={(e) => changeDate(e)}
                  max={moment(date).format("YYYY-MM-DD")}
                  style={{ marginBottom: "10%" }}
                />,
                <TextField
                  id="endDatePembelian"
                  label="Tanggal Pembelian Akhir"
                  type="date"
                  name="tanggalPembelianAkhir"
                  key="tanggalPembelianAkhir"
                  value={query2.tanggalPembelianAkhir}
                  onChange={(e) => changeDate(e)}
                  min={query2.tanggalPembelianAwal}
                  max={new Date()}
                  style={{ marginBottom: "15%" }}
                />,
                <TextField
                  id="startDatePenerimaan"
                  label="Tanggal Penerimaan Awal"
                  type="date"
                  name="tanggalPenerimaanAwal"
                  key="tanggalPenerimaanAwal"
                  value={query2.tanggalPenerimaanAwal}
                  onChange={(e) => changeDate(e)}
                  max={moment(date).format("YYYY-MM-DD")}
                  style={{ marginBottom: "10%" }}
                />,
                <TextField
                  id="endDatePenerimaan"
                  label="Tanggal Penerimaan Akhir"
                  type="date"
                  name="tanggalPenerimaanAkhir"
                  key="tanggalPenerimaanAkhir"
                  value={query2.tanggalPenerimaanAkhir}
                  onChange={(e) => changeDate(e)}
                  min={query2.tanggalPenerimaanAwal}
                  max={new Date()}
                />
              
        ]}
        columns={[
          {
            label: "Nama Supplier",
            searchable: true,
            key: "lawanTransaksiNama",
            render: (item) => item.lawanTransaksi.nama,
          },
          {
            label: "No Pembelian",
            searchable: true,
            key: "nomorPo",
            render: (item) => item.nomorPo,
          },
          {
            label: "Tanggal",
            searchable: true,
            sortable: true,
            key: "tanggalDibuat",
            definition: {
              criterias: ["equals", "greaterThan", "lessThan"],
              inputType: TableWrapper.inputTypes.DATE,
            },
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },
          {
            label: "Total",
            searchable: false,
            key: "total",
            render: (item) => "Rp. " + format(item.total),
          },
          {
            label: "Status",
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.status == 1
                    ? TableWrapper.Status.types.ERROR
                    : TableWrapper.Status.types.SUCCESS
                }
              >
                {item.status == 1 ? "Belum Diterima" : "Sudah  Diterima"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Tanggal Penerimaan",
            searchable: true,
            sortable: true,
            key: "tanggalPenerimaan",
            definition: {
              criterias: ["equals", "greaterThan", "lessThan"],
              inputType: TableWrapper.inputTypes.DATE,
            },
            render: (item) => item.tanggalPenerimaan || "-",
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              navigationStore.redirectTo(
                `/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/pembelian/new`
              );
              //   setSelectedItem(null);
              //   setShowForm(true);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Terima",
            "mdi mdi-plus",
            (item) => {
              if (item.status == 1) {
                updatePembelian(item);
               
              } else {
                alert("Pembelian sudah diterima");
              }
            },
            item => item.status==1?true:false
          ),
          new TableWrapper.action(
            "Detail",
            "mdi mdi-magnify",
            (item) => {
              navigationStore.redirectTo(
                `/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/pembelian/${item.id}/detail`
              );
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          // if (query["tanggalDibuat.lessThan"]){
          //   query["tanggalDibuat.lessThan"] = moment(new Date(query["tanggalDibuat.lessThan"])).format('YYYY-MM-DD HH:mm:ss')
          // }

          for (let tgl of Object.keys(query)) {
            if (
              tgl.includes("tanggalDibuat") ||
              tgl.includes("tanggalPenerimaan")
            ) {
              query[tgl] = new Date(
                moment(new Date(query[tgl])).format("YYYY-MM-DD HH:mm:ss")
              );
            }
          }

          query = {
            ...query,
            ...query2,
          };

          console.log(query);
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "posOutletId.equals": outletId,
              };
              let page = await purchaseService.purchaseGetPage(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];
              resolve(page);
            } catch (err) {
              // resolve(err);
            }
          });
        }}
        showCommandbar={showCommandbar}
      />

      {/* <TableSampleForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      /> */}
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(Purchase));
