import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find, cloneDeep } from "lodash";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import Hint from "../../libs/react-mpk/components/Hint/Hint";
import {
    Button,
    FontIcon,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHeader,
    TableRow,
    TextField,
} from "react-md";
import {
    DataForm,
    LoaderInfo,
    Pagination,
} from "../../libs/react-mpk/components";
import { format } from "../../libs/react-mpk/services/number.service";
import {
    defaultQueryParameter,
    sorts,
} from "../../libs/react-mpk/config/constant";
import moment from "moment";
import service from "./LaporanMutasi.service";

const defaultData = () => ({
    keterangan: "",
});
const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const LaporanMutasi = ({ envStore, navigationStore, ...props }) => {
    const { inputTypes, definition } = FormWrapper;
    const outletId = props.match.params.outletId;
    const [postData, setPostData] = useState({
        posPembelian: {
            status: 1,
            total: 0,
            posOutlet: {
                id: null,
            },
            lawanTransaksi: {
                id: null,
            },
            alamat: "",
        },
        listPosPembelianProduk: [],
        npwpHead: {
            id: null,
        },
    });
    const status = [
        { id: 1, name: "Simpan" },
        { id: 2, name: "Simpan dan Terima" },
    ];
    const [disabled, setDisabled] = useState(false);
    const [item, setItem] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showTypeForm, setShowTypeForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [totalData, setTotalData] = useState(0);
    const [supplier, setSupplier] = useState([]);
    const [loading, setLoading] = useState(false);
    const date = new Date();

    const [dataMutasi, setDataMutasi] = useState({})
    const [ready, setReady] = useState(true)

    const [query, setQuery] = useState(
        defaultQuery({
            page: 1,
            size: 5,
            sort: sorts.descending,
            sortBy: "tanggalDibuat",
            outletId: props.match.params.outletId,
            npwpHeadId: props.match.params.npwpHeadId,
            tanggalAwal: moment(date).format("YYYY-MM-DD"),
            tanggalAkhir: moment(date).format("YYYY-MM-DD"),
        })
    );

    const getProduct = async (query) => {
        try {
            let res = await service.getAllPage(query)
            let { data } = res
            setDataMutasi(data)
            setReady(false)
            console.log(dataMutasi);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProduct(query);
    }, []);

    useEffect(() => {
        
    },[dataMutasi])

    

    const changeDate = (e) => {
        if(e.target.name === 'tanggalAwal'){
            if(e.target.value > query['tanggalAkhir']){
                e.target.value = query['tanggalAkhir']
            }
        }
        setQuery({ ...query, [e.target.name]: e.target.value });
        
    };


    return !ready && (
        <>
            <FormWrapper
                baseId="purchase-form"
                style={{ width: '100%' }}
                title={t.translate("modules.laporanMutasiPersediaan.title")}
                hintShowIcon={false}
                defaultData={item}
                definitions={[
                    {
                        render: (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <TextField
                                id="startDate"
                                label="StartDate"
                                type="date"
                                name="tanggalAwal"
                                value={query.tanggalAwal}
                                onChange={(e) => changeDate(e)}
                                max={moment(date).format("YYYY-MM-DD")}
                              />
                              <TextField
                                id="endDate"
                                label="endDate"
                                type="date"
                                name="tanggalAkhir"
                                value={query.tanggalAkhir}
                                onChange={(e) => changeDate(e)}
                                min={query.tanggalAwal}
                                // max={new Date()}
                              />
            
                              <Button
                                buttonType="icon"
                                style={{ marginTop: "1%" }}
                                onClick={() => getProduct(query)}
                              >
                                <FontIcon iconClassName="mdi mdi-magnify" />
                              </Button>
                            </div>
                        )
                    },
                    {
                        render: (
                            <Hint
                                className="mpk-margin-N margin-top margin-bottom"
                                message="Saldo Awal"
                            />
                        )
                    },
                    {
                        render: (
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell>Kode Produk</TableCell>
                                        <TableCell>{t.translate("words.namaProduk")}</TableCell>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Unit</TableCell>
                                        <TableCell>Harga Satuan</TableCell>
                                        <TableCell>Harga Total</TableCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {!loading ? (
                                        dataMutasi.saldoAwalList &&  dataMutasi.saldoAwalList.map((d, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell>{d.posOutletProduk.sbkProduk.kode}</TableCell>
                                                    <TableCell>{d.posOutletProduk.sbkProduk.nama}</TableCell>
                                                    <TableCell>{format(d.qty)}</TableCell>
                                                    <TableCell>{d.posOutletProduk.sbkProduk.unit.nama}</TableCell>
                                                    <TableCell>Rp. {format(d.hargaSatuan)}</TableCell>
                                                    <TableCell>Rp. {format(d.totalHarga)}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <div style={{ marginLeft: "120%" }}>
                                            <LoaderInfo />
                                        </div>
                                    )}
                                </TableBody>

                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan='2' style={{ textAlign: 'center' }} >Total Persediaan</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            {format(dataMutasi.totalPersediaanQtySaldoAwal)}
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            Rp. {format(dataMutasi.totalPersediaanHargaSatuanSaldoAwal)}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            Rp. {format(dataMutasi.totalPersediaanTotalHargaSaldoAwal)}
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        ),
                    },
                    {
                        render: (
                            <Hint
                                className="mpk-margin-N margin-top margin-bottom"
                                message="Mutasi Masuk"
                            />
                        )
                    },
                    {
                        render: (
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell>Kode Produk</TableCell>
                                        <TableCell>{t.translate("words.namaProduk")}</TableCell>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Harga Satuan</TableCell>
                                        <TableCell>Harga Total</TableCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {!loading ? (
                                        dataMutasi.mutasiMasukList && dataMutasi.mutasiMasukList.map((d, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell>{d.posOutletProduk.sbkProduk.kode}</TableCell>
                                                    <TableCell>{d.posOutletProduk.sbkProduk.nama}</TableCell>
                                                    <TableCell>{format(d.qty)}</TableCell>
                                                    <TableCell>Rp. {format(d.hargaSatuan)}</TableCell>
                                                    <TableCell>Rp. {format(d.totalHarga)}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <div style={{ marginLeft: "120%" }}>
                                            <LoaderInfo />
                                        </div>
                                    )}
                                </TableBody>

                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan='2' style={{ textAlign: 'center' }} >Total Persediaan</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            {format(dataMutasi.totalPersediaanQtyMutasiMasuk)}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            Rp. {format(dataMutasi.totalPersediaanHargaSatuanMutasiMasuk)}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            Rp. {format(dataMutasi.totalPersediaanTotalHargaMutasiMasuk)}
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )
                    },
                    {
                        render: (
                            <Hint
                                className="mpk-margin-N margin-top margin-bottom"
                                message="Mutasi Keluar"
                            />
                        )
                    },
                    {
                        render: (
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell>Kode Produk</TableCell>
                                        <TableCell>{t.translate("words.namaProduk")}</TableCell>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Harga Satuan</TableCell>
                                        <TableCell>Harga Total</TableCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {!loading ? (
                                        dataMutasi.mutasiKeluarList && dataMutasi.mutasiKeluarList.map((d, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell>{d.posOutletProduk.sbkProduk.kode}</TableCell>
                                                    <TableCell>{d.posOutletProduk.sbkProduk.nama}</TableCell>
                                                    <TableCell>{format(d.qty)}</TableCell>
                                                    <TableCell>Rp. {format(d.hargaSatuan)}</TableCell>
                                                    <TableCell>Rp. {format(d.totalHarga)}</TableCell>
                                                    {/* {console.log(d.totalHarga)} */}
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <div style={{ marginLeft: "120%" }}>
                                            <LoaderInfo />
                                        </div>
                                    )}
                                </TableBody>

                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan='2' style={{ textAlign: 'center' }} >Total Persediaan</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            {format(dataMutasi.totalPersediaanQtyMutasiKeluar)}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            Rp. {format(dataMutasi.totalPersediaanHargaSatuanMutasiKeluar)}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            Rp. {format(dataMutasi.totalPersediaanTotalHargaMutasiKeluar)}
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )
                    },
                    {
                        render: (
                            <Hint
                                className="mpk-margin-N margin-top margin-bottom"
                                message="Saldo Akhir"
                            />
                        )
                    },
                    {
                        render: (
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell>Kode Produk</TableCell>
                                        <TableCell>{t.translate("words.namaProduk")}</TableCell>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Harga Satuan</TableCell>
                                        <TableCell>Harga Total</TableCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {!loading ? (
                                        dataMutasi.saldoAkhirList && dataMutasi.saldoAkhirList.map((d, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell>{d.sbkProduk.kode}</TableCell>
                                                    <TableCell>{d.sbkProduk.nama}</TableCell>
                                                    <TableCell>{format(d.stokPersediaan)}</TableCell>
                                                    <TableCell>Rp. {format(d.hargaPokok)}</TableCell>
                                                    <TableCell>Rp. {format((d.stokPersediaan*d.hargaPokok))}</TableCell>

                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <div style={{ marginLeft: "120%" }}>
                                            <LoaderInfo />
                                        </div>
                                    )}
                                </TableBody>

                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan='2' style={{ textAlign: 'center' }} >Total Persediaan</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            {format(dataMutasi.totalPersediaanQtySaldoAkhir)}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            Rp. {format(dataMutasi.totalPersediaanHargaSatuanSaldoAkhir)}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>
                                            Rp. {format(dataMutasi.totalPersediaanTotalHargaSaldoAkhir)}
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )
                    }
                ]}
                onChange={(data, key, value) => {
                    if (key === "supplier") {
                        supplier.map((d) => {
                            if (d.label == value) {
                                data["id"] = d.value;
                                data["alamat"] = d.alamat;
                            }
                        });

                        if (key === "status") {
                            if (value == 1) {
                                data.status = 1;
                            } else if (value == 2) {
                                data.status = 2;
                            }
                        }
                    }
                    

                    return data;
                }}
            />

        </>

    );
};

export default inject("envStore", "navigationStore")(observer(LaporanMutasi));
