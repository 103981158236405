import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find, cloneDeep } from "lodash";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import outletService from "./Outlet.service";
import { AreaService } from "../Area/AreaService";
import iziToast from "izitoast";
import { LoaderInfo } from "../../libs/react-mpk/components";

const cities = [
  { id: 1, name: "Jakarta" },
  { id: 2, name: "Bandung" },
  { id: 3, name: "Semarang" },
  { id: 4, name: "Yogyakarta" },
  { id: 5, name: "Surabaya" },
];

const defaultData = () => ({
  outletName: "",
  phoneNumber: "",
  email: "",
  address: "",
  postalCode: "",
  area: {
    id: null,
  },
});

const FormSample = ({ envStore, ...props }) => {
  const { inputTypes, definition } = FormWrapper;
  const [postData, setPostData] = useState({
    nama: "",
    noTelepon: "",
    email: "",
    alamat: "",
    area: {
      id: null,
    },
    outlet: null,
    npwpHead: {
      id: props.match.params.npwpHeadId,
    },
  });
  const [disabled, setDisabled] = useState(false);
  const [item, setItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const outletId = props.match.params.outletId

  const initData = async () => {
    setLoading(true);
    setDisabled(true);
    let temp = cloneDeep(defaultData());

    console.log(props.match.params);


    try {
      let res = await outletService.getOutletById(
        outletId
      );

      let { data } = res;

      setLoading(false);
      setDisabled(false)
      setPostData(data);
      temp.outletCode = data.kode
      if(!data.kode){
        iziToast.warning({
          title:'Kode Outlet',
          message:'Isi Kode Outlet Anda!'
        })
      }
      temp.outletName = data.nama;
      temp.phoneNumber = data.noTelepon;
      temp.email = data.email;
      temp.address = data.alamat;
      temp.postalCode = data.area.kodepos.toString();
      temp.area.id = data.area.id;

      setItem(temp);
    } catch (err) {
      setLoading(false);
      setDisabled(false)
      iziToast.error({
        message: err.message,
      });
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const getPostalCode = async (postalCode) => {
    try {
      let res = await AreaService.getByKodePos(postalCode);
      let { data } = res;
      return data;
    } catch (e) {
      return e.message;
    }
  };

  const submitData = async (item, callback) => {
    setDisabled(true);

    // console.log(item)
    let tempData = postData;

    tempData.kode = item.outletCode;
    tempData.nama = item.outletName;
    tempData.noTelepon = item.phoneNumber;
    tempData.email = item.email;
    // data.pin = item.pin;
    tempData.area.id = item.area.id;
    tempData.alamat = item.address;

    if (tempData.id) {
      try {
        let res = await outletService.updateOutlet(tempData);
        // console.log(res);
        let { data, status } = res;
        if (status == 200) {
          setDisabled(false);
          sessionStorage.setItem("outletCode",item.outletCode)
          initData();
        }
        callback("success", false);
      } catch (err) {
        setDisabled(false);
        sessionStorage.setItem("outletCode",item.outletCode)
        callback(err, true, false);
      }
    }
  };

  return (
    <FormWrapper
      //   actions={[
      //     {
      //       label: 'Reload',
      //       iconClassName: 'mdi mdi-reload'
      //     }
      //   ]}
      baseId="edit-outlet-form"
      title={t.translate("modules.outlet.editForm")}
      hintMessage={t.translate("modules.outlet.editForm")}
      hintShowIcon={false}
      defaultData={item}
      definitions={
        !loading
          ? [
              {
                inputType: inputTypes.INPUT,
                label: t.translate("words.outletCode"),
                key: "outletCode",
                disabled: disabled,
                required: true,
              },
              {
                id: "outletName",
                inputType: inputTypes.INPUT,
                label: t.translate("words.outletName"),
                key: "outletName",
                disabled: disabled,
              },
              {
                id: "phone",
                inputType: inputTypes.INPUT,
                label: t.translate("words.phone"),
                key: "phoneNumber",
                type: "number",
                disabled: disabled,
              },
              {
                id: "email",
                inputType: inputTypes.INPUT,
                label: t.translate("words.email"),
                type: "email",
                key: "email",
                disabled: disabled,
              },
              {
                id: "address",
                inputType: inputTypes.TEXTAREA,
                label: t.translate("words.address"),
                key: "address",
                disabled: disabled,
              },
              {
                id: "postalCode",
                inputType: inputTypes.INPUT,
                label:  t.translate("words.postalCode"),
                type: "number",
                key: "postalCode",
                disabled: disabled,
              },
            ]
          : [
              {
                render: (
                  <div style={{ marginLeft: '45%' }}>
                    <LoaderInfo />
                  </div>
                ),
              },
            ]
      }
      onChange={(data, key, value) => {
        if (key === "postalCode") {
          if (value.length === 5) {
            let temp = getPostalCode(value);
            temp
              .then((res) => {
                if (typeof res === "object") {
                  data.area.id = res.id;
                } else {
                  alert(res);
                }
              })
              .catch((err) => {
                alert(err);
              });
          }
        }

        return data;
      }}
      submitLabel="Submit"
      onSubmit={!disabled ? submitData : null}
    />
  );
};

export default inject("envStore")(observer(FormSample));
