import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./AkunNpwp.service";
import premiumService from "../Premium/Premium.service";
import moment from "moment";
import { DataForm } from "../../libs/react-mpk/components";
import OutletForm from "../Outlet/OutletForm";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import AkunNpwpForm from "./AkunNpwpForm";
import OutletOwnerForm from "../PemilikOutlet/OutletOwnerForm";
import iziToast from "izitoast";

const AkunNpwp = ({
  className = "",
  showCommandbar = true,
  envStore,
  modalStore,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [premium, setPremium] = useState("");
  let [search, setSearch] = useState({});
  const baseId = "akun-npwp";
  const basePath = `/internal/application`;

  useEffect(async () => {
    try {
      let res = await premiumService.cekPremium();
      let { data } = res;
      setPremium(data.message);
      sessionStorage.setItem("premium", data.message);
      console.log(premium);
    } catch (e) {
      console.log(e.response);
      setPremium(e.response.data.message);
      sessionStorage.setItem("premium", e.response.data.message);
      // iziToast.error({messasge:e.message})
    }
  }, []);

  var actions = [
    new TableWrapper.action(
      `${t.translate("words.add")}`,
      "mdi mdi-plus",
      () => {
        // setDisabledForm(false)
        // setSelectedItemId(null)
        setShowForm(true);
      },
      premium == "USER PREMIUM"
    ),
  ];

  var itemActions = [
    new TableWrapper.action(
      `${t.translate("words.pilih")}`,
      "mdi mdi-import",
      (item) => {
        // sessionStorage.setItem('npwpHead', item.npwp);
        sessionStorage.removeItem("npwpHeadId");
        sessionStorage.setItem("npwpHeadId", item.id);
        // console.log(`${basePath}/${item.id}`);
        window.location.href = `${basePath}/${item.id}`;
      }
    ),
    new TableWrapper.action(
      `${t.translate("words.edit")}`,
      "mdi mdi-pencil",
      (item) => {
        setDisabledForm(false);
        setSelectedItemId(item.id);
        setShowForm(true);
      },
      true
    ),
  ];

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={"Pemilik Outlet"}
        className={className}
        hintMessage={t.translate("modules.akunNpwp.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.akunNpwp.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        multiColumnFilter={true}
        useQueryHistory={false}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
              };

              let page = await service.getPage(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];
              resolve(page);
            } catch (err) {
              // resolve(err);
            }
          });
        }}
        // onFetchData = {(query) => {
        //   return (
        //       new Promise(async (resolve, reject) => {
        //         try{
        //           query = {
        //             ...search,
        //             ...query
        //           }
        //           let getPage = await service.get(query)
        //           getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
        //           resolve(getPage)
        //         } catch(e){
        //           resolve()
        //           // ErrorService(e)
        //         }
        //       })
        //   )
        // }}
        columns={[
          {
            label: "NPWP",
            searchable: true,
            sortable: false,
            key: "npwp",
            definition: {
              criterias: ["contains", "equals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            render: (item) => item.npwp,
          },
          {
            label: "Nama",
            searchable: true,
            sortable: false,
            key: "namaNpwp",
            definition: {
              criterias: ["contains", "equals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            render: (item) => item.namaNpwp,
          },
          {
            label: "Email",
            searchable: true,
            sortable: false,
            key: "email",
            definition: {
              criterias: ["contains", "equals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            render: (item) => item.email,
          },
          {
            label: "Tipe NPWP",
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.npwpOp
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.npwpOp ? "Orang Pribadi" : "Badan"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Tanggal Dibuat",
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },
        ]}
        actions={actions}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
        // sideHeader={
        //   <DataForm
        //     baseId={`${baseId}-search`}
        //     defaultData={search}
        //     submitIconClassName = 'mdi mdi-magnify'
        //     submitLabel={`${t.translate('words.cari')}`}
        //     onSubmit={(values, callback)=> {
        //       // console.log(values, 'values')
        //       setSearch(values);
        //       setTimeout(()=> {
        //         document.getElementsByClassName('mdi-reload')[0].click()
        //         callback("", false, false)
        //       }, 1000)
        //     }}
        //     definitions={[
        //       {
        //         inputType: inputTypes.INPUT,
        //         label: t.translate(`words.npwp`),
        //         key: 'npwp.contains',
        //         type: 'text'
        //       },
        //       {
        //         inputType: inputTypes.INPUT,
        //         label: t.translate(`words.nama`),
        //         key: 'namaNpwp.contains',
        //         type: 'text'
        //       },
        //       {
        //         inputType: inputTypes.INPUT,
        //         label: t.translate(`words.email`),
        //         key: 'email.contains',
        //         type: 'text'
        //       },
        //     ]}
        //     />
        // }
      />

      <AkunNpwpForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItemId(null);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        itemId={selectedItemId}
      />
    </>
  );
};

export default AkunNpwp;
