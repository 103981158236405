import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import { format } from "../../libs/react-mpk/services/number.service";
import BahanBakuForm from "./BahanBakuForm";
import bahanBakuService from "./BahanBaku.service";

// const defaultData = () => [({
//     sbkProduk:{
//         namaBahanBaku:"Processor",
//         kategori:{
//             nama:"Hardware"
//         },
//         defaultHarga:"5000000"
//     },
//     manajemenRak:{
//         kodeRak:"RHW",
//         namaRak:"RAK HARDWARE"
//     },
//     stokPersediaan:"100",
//     hargaPokok:"4500000"

// })]

const BahanBaku = ({
  className = "",
  showCommandbar = true,
  navigationStore,
  modalStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let outletId = props.match.params.outletId;
  const deleteBahanBaku = async (item) => {
    try {
      let res = await bahanBakuService.deleteBahanBaku(item.id);

      let { data, status } = res;

      if (status == 200) {
        iziToast.success({
          message: data.message,
        });

        // TableWrapper.updateDataItem("inventory-table", { id: data.id }, data);
        TableWrapper.reload("bahan-baku-table");
      } else {
        iziToast.info({
          message: data.message,
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };
  const deleteItem = async (item) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        deleteBahanBaku(item);
        callback();
      },
    });
  };
  return (
    <>
      <TableWrapper
        baseId="bahan-baku-table"
        title={t.translate("modules.bahanBaku.title")}
        className={className}
        hintMessage={t.translate("modules.bahanBaku.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.bahanBaku.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        columns={[
          {
            label: t.translate("words.namaBahanBaku"),
            searchable: true,
            sortable: false,
            key: "produkNama",
            definition: {
              criterias: ["contains", "equals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sbkProduk && item.sbkProduk.nama,
          },
          {
            label: t.translate("words.kategori"),
            searchable: true,
            sortable: false,
            key: "kategori",
            definition: {
              criterias: ["contains", "equals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sbkProduk && item.sbkProduk.kategori.nama,
          },
          {
            label: t.translate("words.kodeRak"),
            searchable: true,
            sortable: false,
            key: "kodeRak",
            definition: {
              criterias: ["contains", "equals", "notEquals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              item.manajemenRak ? item.manajemenRak.kodeRak : "Tanpa Rak",
          },
          {
            label: t.translate("words.namaRak"),
            searchable: true,
            sortable: false,
            key: "namaRak",
            definition: {
              criterias: ["contains", "equals", "notEquals"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              item.manajemenRak ? item.manajemenRak.namaRak : "Tanpa Rak",
          },
          {
            label: t.translate("words.hargaJualSatuan"),
            searchable: false,
            sortable: false,
            key: "defaultHarga",
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) =>
              item.sbkProduk && `Rp. ${format(item.sbkProduk.defaultHarga)}`,
          },
          {
            label: t.translate("words.monitorPersediaanBarang"),
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.isTrackingPersediaan
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.isTrackingPersediaan
                  ? t.translate("words.yes")
                  : t.translate("words.no")}
              </TableWrapper.Status>
            ),
          },
          {
            label: t.translate("words.availableStock"),
            searchable: false,
            sortable: false,
            key: "stokPersediaan",
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => format(item.stokPersediaan),
          },
          {
            label: t.translate("words.CogsTracking"),
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.isTrackingHargaPokok
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.isTrackingHargaPokok
                  ? t.translate("words.yes")
                  : t.translate("words.no")}
              </TableWrapper.Status>
            ),
          },
          {
            label: t.translate("words.CogsMeans"),
            searchable: false,
            key: "hargaPokok",
            sortable: false,
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => `Rp. ${format(item.hargaPokok)}`,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              //   if (premium === "USER PREMIUM") {
              setSelectedItem(null);
              setShowForm(true);
              //   } else if (
              //     premium == "User Ini Tidak Premium" &&
              //     produkqty < 100
              //   ) {
              //     setSelectedItem(null);
              //     setShowForm(true);
              //   } else if (
              //     premium == "User Ini Tidak Premium" &&
              //     produkqty >= 100
              //   ) {
              //     // showProdukDialog()
              //     setShowDialogProduk(true);
              //   }
              //   // console.log((premium !== 'USER PREMIUM' && produkqty > 5))
            },
            // !(premium !== 'USER PREMIUM' && produkqty > 5)
            // premium === 'USER PREMIUM' ? true : produkqty >= 7 ? false : true
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate("words.delete")}`,
            "mdi mdi-trash-can",
            (item) => {
              deleteItem(item);
            },
            true
          ),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', () => console.log('request delete'), true)
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "posOutletId.equals": props.match.params.outletId,
                npwpHeadId: props.match.params.npwpHeadId,
              };
              let page = await bahanBakuService.getPageBahanBaku(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];

              resolve(page);
            } catch (err) {
              // resolve(err);
            }
          });
        }}
      />
      <BahanBakuForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />
    </>
  );
};

export default inject("navigationStore", "modalStore")(observer(BahanBaku));
