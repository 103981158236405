import { crud, http } from "../../libs/react-mpk/services";

let unitService = new crud("/produk/unit");
let serviceUrl = "/produk/unit";
// let npwpHeadId = sessionStorage.getItem("npwpHeadId");

unitService.createUnit = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

unitService.updateUnit = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

unitService.getUnitById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

unitService.deleteUnit = async (id) => {
    return http.request({
      method: http.methods.DELETE,
      url: `${serviceUrl}/${id}`,
    });
  };

unitService.getPageUnit = async (query) => {
  query = {
    ...query,
    // npwpHeadId: npwpHeadId
  };

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

unitService.generateCode = async (npwpHeadId) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/generate_kode/${npwpHeadId}`
  });
};


export default unitService