import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import manajemenRakService from "./ManajemenRak.service";
import ManajemenRakForm from './ManajemenRakForm';
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";

const ManajemenRak = ({ className = "", showCommandbar = true, modalStore, ...props}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const outletId = props.match.params.outletId

  const deleteRak = async (item) => {
    try{
      let res = await manajemenRakService.deleteManajemenRak(item.id);

      let { data, status } = res;

      if (status == 200) {
          iziToast.success({
              message: data.message,
          });
          
          TableWrapper.updateDataItem("rak-table",{ id: data.id },data);
          TableWrapper.reload("rak-table");

      } else {
          iziToast.info({
              message: data.message,
          });
      }
    } catch (err) {
        iziToast.error({
            message: err.message
        })
    }
};

  const deleteItem = async (item) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        deleteRak(item);
        callback();
      },
    });
  };

  return (
    <>
      <TableWrapper
        baseId="rak-table"
        title={t.translate("modules.manajemenRak.rak.title")}
        className={className}
        hintMessage={t.translate("modules.manajemenRak.rak.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.manajemenRak.rak.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // multiColumnFilter={true}
        // extraFilter={<div>Test</div>}
        columns={[
            {
                label: t.translate('words.kodeRak'),
                searchable: true,
                sortable: false,
                key:"kodeRak",
                definition: {
                  criterias: ["contains", "equals", "in"],
                  inputType: TableWrapper.inputTypes.INPUT,
                },
                render: (item) => item.kodeRak
            },
            {
                label: t.translate('words.namaRak'),
                searchable: true,
                sortable: false,
                key: "namaRak",
                definition: {
                  criterias: ["contains", "equals", "in"],
                  inputType: TableWrapper.inputTypes.INPUT,
                },
                render: (item) => item.namaRak,
            },
            {
                label: t.translate('words.lantai'),
                searchable: false,
                sortable: false,
                key:"lantai",
                render:(item) => item.lantai,
            },
            {
                label: t.translate('words.tingkatRak'),
                searchable:false,
                sortable:false,
                key:"tingkatRak",
                render:(item) => item.tingkatRak,
            },
            {
                label: t.translate('words.keterangan'),
                searchable: false,
                sortable:false,
                key:"keterangan",
                render:(item) => item.keterangan,
            }
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
                setShowForm(true);
                setSelectedItem(null);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => deleteItem(item),
            true
          ),
        ]}
        onFetchData={ (query) => {
          return new Promise(async (resolve, reject) => {
            
            try {
              query = {
                ...query,
                "posOutletId.equals": props.match.params.outletId,
              };
              let page = await manajemenRakService.getPageManajemenRak(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];

              resolve(page);
            } catch (err) {
              // resolve(err);
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
        <ManajemenRakForm
            visible={showForm}
            onRequestClose={() => {
                setShowForm(false);
                setSelectedItem(null);
            }}
            item={selectedItem}
            {...props}
        />
    </>
  );
};

export default inject('modalStore')(observer(ManajemenRak));