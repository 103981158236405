import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import customerService from "./Customer.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";

const defaultData = () => ({
  nama: "",
  memilikiNpwp: "",
  npwp: "",
  nik: "",
  alamat: "",
  namaPic: "",
  emailPic: "",
  noTeleponPic: "",
  alamatSama: true,
  alamatPic: "",
  jenisUsaha: 0,
});

const yesNo = [
  { id: "ya", name: "yes" },
  { id: "tidak", name: "no" },
];

const businessList = [
  { id: 2, name: "op" },
  { id: 1, name: "badan" },
];

const CustomerForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [disabled, setDisabled] = useState(false);
  let [showPicAddress, setShowPicAddress] = useState(false);
  let [havingNpwp, setHavingNpwp] = useState(false);
  let [isOp, setIsOp] = useState(false);
  let [postData, setPostData] = useState({
    kategori: 1,
    jenisUsaha: 2,
    nama: "",
    npwp: "",
    nik: "",
    alamat: "",
    namaPic: "",
    emailPic: "",
    noTeleponPic: "",
    alamatSama: false,
    alamatPic: "",
    npwpHead: null,
  });

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        let res = await customerService.getOneCustomer(props.item);
        let { data, status } = res;

        setShowPicAddress(data.alamatSama);
        // if (data.npwp !== '000000000000000'){
        setHavingNpwp(data.npwp !== "000000000000000" ? true : false);
        setIsOp(data.jenisUsaha == 2 ? true : false);
        // }
        // else{
        //   setHavingNpwp(false)
        // }
        selectedItem.id = data.id;
        selectedItem.alamat = data.alamat;
        selectedItem.alamatPic = data.alamatPic;
        selectedItem.alamatSama = data.alamatSama;
        selectedItem.emailPic = data.emailPic;
        selectedItem.jenisUsaha = String(data.jenisUsaha);
        selectedItem.kategori = data.kategori;
        selectedItem.nama = data.nama;
        selectedItem.namaPic = data.namaPic;
        selectedItem.nik = data.nik;
        selectedItem.noTeleponPic = data.noTeleponPic;
        selectedItem.npwp = data.npwp;
        selectedItem.tanggalDibuat = data.tanggalDibuat;
        selectedItem.tanggalDiperbaharui = data.tanggalDiperbaharui;
        selectedItem.memilikiNpwp =
          data.npwp === "000000000000000" ? "tidak" : "ya";
        // selectedItem = cloneDeep(props.item);
        // selectedItem.number = String(selectedItem.number);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
    let temp = postData;

    setDisabled(true);
    temp.jenisUsaha = item.jenisUsaha;
    temp.nama = item.nama;
    temp.npwp = item.memilikiNpwp == "ya" ? item.npwp : "000000000000000";
    temp.nik = item.jenisUsaha == 2 ? item.nik : "0000000000000000";
    temp.alamat = item.alamat;
    temp.namaPic = item.nama;
    temp.emailPic = item.emailPic;
    temp.noTeleponPic = item.noTeleponPic;
    temp.alamatSama = item.alamatSama;
    temp.alamatPic = item.alamatSama ? item.alamat : item.alamatPic;
    temp.npwpHead = { id:  props.match.params.npwpHeadId };
    temp.kategori = 1;
    // console.log(temp)
    // console.log(item, "item")

    if (item.id) {
      try {
        let res = await customerService.updateCustomer(temp, item.id);

        setDisabled(false);
        let { data, status } = res;
        callback("success", false);

        TableWrapper.updateDataItem("customer-table", { id: data.id }, data);
        TableWrapper.reload("customer-table");
      } catch (err) {
        callback(err, true, false);
        setDisabled(false);
      }
    } else {
      try {
        let res = await customerService.createCustomer(temp);
        setDisabled(false);
        let { data, status } = res;
        callback("success", false);

        TableWrapper.updateDataItem("customer-table", { id: data.id }, data);
        TableWrapper.reload("customer-table");
      } catch (err) {
        callback(err, true, false);
        setDisabled(false);
      }
    }
  };

  const closeForm = () => {
    setPostData({
      kategori: 1,
      jenisUsaha: 2,
      nama: "",
      npwp: "",
      nik: "",
      alamat: "",
      namaPic: "",
      emailPic: "",
      noTeleponPic: "",
      alamatSama: false,
      alamatPic: "",
      npwpHead: null,
    });
    onRequestClose();
  };

  return (
    ready && (
      <DataForm
        baseId="customer-form"
        title={t.translate("modules.lawanTransaksi.customer.form")}
        visible={visible}
        // data={item}
        onRequestClose={() => closeForm()}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.name"),
            key: "nama",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("words.jenisUsahaLawanTransaksi"),
            key: "jenisUsaha",
            theme: "filled",
            options: businessList.map((d) => ({
              value: d.id,
              children: t.translate(`words.${d.name}`),
            })),
            labelKey: "children",
            valueKey: "value",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("words.haveNpwp"),
            key: "memilikiNpwp",
            theme: "filled",
            options: yesNo.map((d) => ({
              value: d.id,
              children: t.translate(`words.${d.name}`),
            })),
            labelKey: "children",
            valueKey: "value",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.identityNpwp"),
            key: "npwp",
            minLength: 15,
            maxLength: 15,
            disabled: disabled || !havingNpwp,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.identityNik"),
            key: "nik",
            minLength: 16,
            maxLength: 16,
            disabled: disabled || !isOp,
          },
          {
            inputType: DataForm.inputTypes.TEXTAREA,
            label: t.translate("words.address"),
            key: "alamat",
            disabled: disabled,
          },
          // {
          //   inputType: DataForm.inputTypes.INPUT,
          //   label: t.translate("words.namaPic"),
          //   key: "namaPic",
          //   disabled: disabled,
          // },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.email"),
            key: "emailPic",
            type: "email",
            validation: "email",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.phone"),
            key: "noTeleponPic",
            type: "number",
            disabled: disabled,
          },
          // {
          //   inputType: DataForm.inputTypes.CHECKBOX,
          //   label: t.translate("words.sameAddress"),
          //   key: "alamatSama",
          //   type: "number",
          //   disabled: disabled,
          // },
          // {
          //   inputType: DataForm.inputTypes.TEXTAREA,
          //   label: t.translate("words.alamatPic"),
          //   key: "alamatPic",
          //   disabled: disabled,
          //   show: !showPicAddress,
          // },
        ]}
        onChange={(data, key, value) => {
          if (key === "alamatSama") {
            setShowPicAddress(value);
          }

          if (key === "memilikiNpwp") {
            if (value === "tidak") {
              setHavingNpwp(false);
            } else {
              setHavingNpwp(true);
            }
          }

          if (key === "jenisUsaha") {
            // console.log(value)
            if (value == 2) {
              setIsOp(true);
            } else {
              setIsOp(false);
            }
          }

          if (key === "npwp") {
            data.npwp = String(value).replace(/\D/g, "");
          }
          if ( key === "ktp"){
            data.ktp = String(value).replace(/\D/g, "");
          }

          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default CustomerForm;
