import { crud, http } from '../../libs/react-mpk/services';

let stockAdjustmentService = new crud('/penyesuaian_produk');
let serviceUrl = "/penyesuaian_produk"

stockAdjustmentService.createStockAdjustment = (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data
  });
}
stockAdjustmentService.stockAdjustmentGetPage = (query) => {
  query = {
    ...query
  }
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  });
}
stockAdjustmentService.stockAdjustmentGetDetail = (id) => {
  
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
}

export default stockAdjustmentService;