import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find, cloneDeep } from "lodash";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import Hint from "../../libs/react-mpk/components/Hint/Hint";
import {
  Button,
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
} from "react-md";
import {
  DataForm,
  LoaderInfo,
  LoadingButton,
  Pagination,
} from "../../libs/react-mpk/components";
import { format } from "../../libs/react-mpk/services/number.service";
import {
  defaultQueryParameter,
  sorts,
} from "../../libs/react-mpk/config/constant";
import { http, toast } from "../../libs/react-mpk/services";
import AddPurchaseItem from "./AddPurchaseItem";
import purchaseService from "./Purchase.service";
import supplierService from "../Supplier/Supplier.service";
import iziToast from "izitoast";

const defaultData = () => ({
  keterangan: "",
});
const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const PurchaseForm = ({ envStore, navigationStore, ...props }) => {
  const { inputTypes, definition } = FormWrapper;
  const outletId = props.match.params.outletId;
  const npwpHeadId = props.match.params.npwpHeadId;
  // console.log(props);
  const [postData, setPostData] = useState({
    posPembelian: {
      status: 1,
      total: 0,
      posOutlet: {
        id: null,
      },
      lawanTransaksi: {
        id: null,
      },
      alamat: "",
    },
    listPosPembelianProduk: [],
    npwpHead: {
      id: null,
    },
  });
  const isBahanBakuSelect = [
    { name: "Bahan Baku", value: "true" },
    { name: "Produk", value: "false" },
  ];
  const status = [
    { id: 1, name: "Simpan" },
    { id: 2, name: "Simpan dan Terima" },
  ];
  const [disabled, setDisabled] = useState(false);
  const [item, setItem] = useState({
    supplier:null,
    isBahanBaku:null,
    alamat:null,
    status:null,
  });
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [supplier, setSupplier] = useState([]);
  const [product, setProduct] = useState([]);
  const [isBahanBaku, setIsBahanBaku] = useState(false);
  const [query, setQuery] = useState(
    defaultQuery({
      page: 1,
      size: 100,
      sort: sorts.descending,
      sortBy: "tanggalDibuat",
    })
  );
  const [purchaseProduct, setPurchaseProduct] = useState([]);
  const [loadingProduk, setLoadingProduk] = useState(false);

  const getSupplier = async (query) => {
    setDisabled(true);
    query.page = 0;
    query = {
      ...query,
      "kategori.equals": 2,
      npwpHeadId: props.match.params.npwpHeadId,
    };
    try {
      let res = await supplierService.getPage(query);
      let { data, headers } = res;
      setTotalData(headers["x-total-count"]);
      let item = [];
      data.map((d) => {
        item.push({
          value: d.id,
          label: d.nama,
          alamat: d.alamat,
        });
      });
      setSupplier(item);
      setDisabled(false);
    } catch (err) {
      // setDisabled(false);
      iziToast.error({
        message: err.message,
      });
    }
  };

  const fetchProduct = async (isBahanBaku) => {
    query.page = 1;
    query.size = 10000;
    let newQuery = http.mapQueryCriteria(query);
    newQuery.npwpHeadId = props.match.params.npwpHeadId;
    newQuery["posOutletId.equals"] = outletId;
    newQuery["isBahanBaku.equals"] = isBahanBaku;

    try {
      setLoadingProduk(true)
      let res = await purchaseService.getProductList(newQuery);
      let { data, headers } = res;

      setTotalData(headers["x-total-count"]);
      let temp = [];
      data.map((d) => {
        if (d.isTrackingPersediaan) {
          temp.push({
            value: d.id,
            label: d.sbkProduk.nama,
            stock: d.stokPersediaan,
            isTrackingPersediaan: d.isTrackingPersediaan,
          });
        }
      });
      setProduct(temp);
      setLoadingProduk(false)
    } catch (err) {
      toast.errorRequest(err)
      setLoadingProduk(false)
    }
  };

  const getProduct = async (query) => {
    let temp = JSON.parse(sessionStorage.getItem("purchaseTemporary"));
    if (temp) {
      setPurchaseProduct(temp);
      setTotalData(temp.length);
      temp.map((d) => {
        setTotalPrice(totalPrice + d.total);
      });
      sessionStorage.removeItem("purchaseTemporary");
    }
  };

  useEffect(() => {
    getProduct(query);
    getSupplier(query);
  }, []);

  const submitData = async (item, callback) => {
    setDisabled(true);
    let tempData = postData;
    tempData.posPembelian.posOutlet.id = props.match.params.outletId;
    tempData.npwpHead.id = props.match.params.npwpHeadId;
    tempData.posPembelian.total = totalPrice;
    tempData.posPembelian.lawanTransaksi.id = item.id;
    tempData.posPembelian.alamat = item.alamat;
    tempData.posPembelian.status = item.status;

    tempData.listPosPembelianProduk = [];
    purchaseProduct.map((d) => {
      tempData.listPosPembelianProduk.push({
        qty: d.qty,
        hargaSatuan: d.price,
        total: d.total,
        posOutletProduk: {
          id: d.productId,
        },
      });
    });

    if (tempData.listPosPembelianProduk.length === 0) {
      callback(t.translate("words.productNull"));
      setDisabled(false);
    } else {
      try {
        let res = await purchaseService.createPurchase(tempData);
        let { status } = res;
        if (status === 200) {
          setDisabled(false);
          navigationStore.redirectTo(
            `/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/pembelian`
          );
        }
        callback("success", false);
      } catch (err) {
        setDisabled(false);
        callback(err, true, false);
        console.log(err);
      }
    }
  };

  const addItem = () => {
    if (item.isBahanBaku == null) {
      iziToast.info({
        message: `harus Pilih Produk atau Bahan Baku Terlebih Dahulu`,
      });
    } else {
      setShowForm(true);
      setSelectedItem(null);
    }
  };

  const deletePurchaseItem = (e, i) => {
    setTotalPrice(totalPrice - purchaseProduct[i].total);
    let temp = [...purchaseProduct];

    temp.splice(i, 1);
    setPurchaseProduct(temp);
  };

  // const setNewQuery = (newQuery) => {
  //   setQuery({ ...query, ...newQuery });
  //   getProduct(query);
  // };

  return (
    <>
      <FormWrapper
        //   actions={[
        //     {
        //       label: 'Reload',
        //       iconClassName: 'mdi mdi-reload'
        //     }
        //   ]}
        backTo={`/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/pembelian`}
        baseId="purchase-form"
        title={t.translate("modules.pembelian.formTitle")}
        hintShowIcon={false}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("words.supplier"),
            key: "supplier",
            validation: "required",
            labelKey: "label",
            valueKey: "label",
            data: supplier,
            disabled: disabled,
            require: true,
          },
          {
            id: "isBahanBaku",
            inputType: DataForm.inputTypes.SELECT,
            label: "Produk atau Bahan Baku?",
            key: "isBahanBaku",
            options: isBahanBakuSelect.map((d) => ({
              value: d.value,
              children: d.name,
            })),
            labelKey: "children",
            disabled: disabled,
            validation: "required",
            require: true,
          },
          {
            id: "alamat",
            inputType: inputTypes.INPUT,
            label: "Alamat",
            key: "alamat",
            disabled: true,
          },
          {
            id: "status",
            inputType: DataForm.inputTypes.SELECT,
            label: "Metode Simpan",
            key: "status",
            options: status.map((d) => ({
              value: d.id,
              children: d.name,
            })),
            labelKey: "children",
            disabled: disabled,
            validation: "required",
            require: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <LoadingButton
                  loading={loadingProduk}
                    theme="primary"
                    themeType="contained"
                    style={{ margin: 15 }}
                    iconClassName={"mdi mdi-plus"}
                    onClick={() => addItem()}
                  >
                    {t.translate("words.add")}
                  </LoadingButton>
                </div>

                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCell>{t.translate("words.non")}</TableCell>
                      <TableCell>{t.translate("words.namaProduk")}</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Harga Satuan</TableCell>
                      <TableCell>{t.translate("words.total")}</TableCell>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {purchaseProduct.map((d, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{d.produk}</TableCell>
                          <TableCell>{d.qty}</TableCell>
                          <TableCell>Rp. {format(d.price)}</TableCell>
                          <TableCell>Rp. {format(d.total)}</TableCell>

                          <TableCell>
                            <Button
                              buttonType="icon"
                              style={{ marginTop: "1.5vh" }}
                              onClick={(e) => deletePurchaseItem(e, i)}
                            >
                              <FontIcon iconClassName="mdi mdi-delete" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TableCell>Total</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Rp. {format(totalPrice)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            ),
          },
        ]}
        onChange={(data, key, value) => {
          if (key === "supplier") {
            supplier.map((d) => {
              if (d.label == value) {
                data["id"] = d.value;
                data["alamat"] = d.alamat;
              }
            });

            if (key === "status") {
              if (value == 1) {
                data.status = 1;
              } else if (value == 2) {
                data.status = 2;
              }
            }
          }
          if (key === "isBahanBaku") {
            if (value == "true") {
              fetchProduct(true);
              setIsBahanBaku(true);
            } else if (value == "false") {
              fetchProduct(false);
              setIsBahanBaku(false);
            }
          }
          setItem(data);
          return data;
        }}
        submitLabel="Simpan"
        onSubmit={!disabled ? submitData : null}
      />

      <AddPurchaseItem
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={purchaseProduct}
        isBahanBaku={isBahanBaku}
        onSubmitRequest={getProduct}
        product={product}
        {...props}
      />
    </>
  );
};

export default inject("envStore", "navigationStore")(observer(PurchaseForm));
