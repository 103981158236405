import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import outletService from "../Outlet/Outlet.service";
import employeeService from "./Employee.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import iziToast from "izitoast";
import { toast } from "../../libs/react-mpk/services";

const defaultData = () => ({
  nama: "",
  noTelepon: "",
  email: "",
  pin: null,
  posOutlet: null,
});

const EmployeeForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [id, setId] = useState(null);
  let [ready, setReady] = useState(true);
  let [postData, setPostData] = useState({
    nama: "",
    noTelepon: "",
    email: "",
    posOutlet: {
      id: props.match.params.outletId,
    },
  });
  let [disabled, setDisabled] = useState(false);

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        try {
          let res = await employeeService.getEmployeeById(props.item);
          let { data } = res;
          setPostData(data);

          selectedItem.id = data.id;
          selectedItem.email = data.email;
          selectedItem.nama = data.nama;
          selectedItem.noTelepon = data.noTelepon;
          selectedItem.pin = data.pin;
        } catch (err) {}

        // selectedItem = cloneDeep(props.item);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
    setDisabled(true);

    let postItem = postData;
    postItem.email = item.email;
    postItem.nama = item.nama;
    postItem.noTelepon = item.noTelepon;

    if (item.id) {
      try {
        const res = await employeeService.updateEmployee(postItem);
        let { data, status } = res;
        if (status == 200) {
          setDisabled(false);
          TableWrapper.updateDataItem("employee-table", { id: data.id }, data);
          TableWrapper.reload("employee-table");
        }
        callback("success", false);
      } catch (err) {
        setDisabled(false);
        callback(err, true, false);
      }
    } else {
      try {
        if (item.pin.toString().length == 4) {
          const res = await employeeService.createEmployee(postItem);
          let { data, status } = res;
          if (status == 200) {
            setDisabled(false);
            let temp = {
              id: data.id,
              pin: item.pin,
            };
            if (temp.pin.toString().length == 4) {
              const result = await employeeService.updateEmployeePin(temp);
              if (result.status == 200) {
                TableWrapper.updateDataItem(
                  "employee-table",
                  { id: data.id },
                  data
                );
                TableWrapper.reload("employee-table");
              }
              callback("success", false);
            } else {
              callback("Pin harus 4 Digit", true, false);
              setDisabled(false);
            }
            // console.log(res, 'res')
          }
        } else {
          callback("Pin harus 4 Digit", true, false);
          setDisabled(false);
        }
      } catch (err) {
        // console.log(err, 'err')
        setDisabled(false);
        callback(err, true, false);
      }
    }
  };

  const closeForm = () => {
    setPostData({
      nama: "",
      noTelepon: "",
      email: "",
      posOutlet: {
        id: sessionStorage.getItem("outletId"),
      },
    });
    onRequestClose();
  };

  return (
    ready && (
      <DataForm
        baseId="employee-form"
        title={t.translate("modules.employee.form")}
        visible={visible}
        // data={item}
        onRequestClose={() => closeForm()}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.name"),
            key: "nama",
            validation: "required",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.phone"),
            key: "noTelepon",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.email"),
            type: "email",
            key: "email",
            validation: "email",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.employeePin"),
            key: "pin",
            type: "password",
            disabled: disabled || item && item.id,
            minLength: 4,
            maxLength: 4,
            validation: item && item.id ? "" : "required",
          },
        ]}
        onChange={(data, key, value) => {
          if (key == "pin") {
            data.pin = String(value).replace(/\D/g, '')
            if (value.length > 4) {
              toast.error("Maksimal PIN 4 karakter");
            }
          }
          if (key == "noTelepon"){
            data.noTelepon = value.replace(/\D/g, "")
          }
          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default EmployeeForm;
