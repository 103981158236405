import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { cloneDeep } from "lodash";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import stockAdjustmentService from "./StockAdjustmentService";
import Hint from "../../libs/react-mpk/components/Hint/Hint";
import {
  Button,
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "react-md";
import { LoaderInfo, Pagination } from "../../libs/react-mpk/components";
import {
  defaultQueryParameter,
  sorts,
} from "../../libs/react-mpk/config/constant";
import AddNewProduct from "./AddNewProduct";
import { http } from "../../libs/react-mpk/services";
import purchaseService from "../Purchase/Purchase.service";

const defaultData = () => ({
  keterangan: "",
});
const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const StockAdjustmentForm = ({ envStore, navigationStore, ...props }) => {
  const isBahanBakuSelect = [
    {name: "Bahan Baku", value:'1'},
    {name:"Produk",value:'2'}
  ]
  const { inputTypes, definition } = FormWrapper;
  const [postData, setPostData] = useState({
    posPenyesuaianStok: {
      keterangan: "Tidak ada",
      posOutlet: {
        id: null,
      },
    },
    listPosPenyesuaianStokProduk: [],
    npwpHead: {
      id: null,
    },
  });
  const [disabled, setDisabled] = useState(false);
  const [item, setItem] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [query, setQuery] = useState(
    defaultQuery({
      page: 1,
      size: 5,
      sort: sorts.descending,
      sortBy: "tanggalDibuat",
    })
  );
  const [adjustmentProduct, setAdjustmentProduct] = useState([]);
  const [isBahanBaku, setIsBahanBaku] = useState(false);
  const [loading, setLoading] = useState(false);
  const [kategori,setKategori] = useState('1');

  const [product, setProduct] = useState([])

  const outletId = props.match.params.outletId;
  const npwpHeadId = props.match.params.npwpHeadId;
  const backTo = `/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/stock`;

  const initData = async () => {
    let temp = cloneDeep(defaultData());

    try {
      // let res = await stockAdjustmentService.getById();

      // let { data } = res;

      // setPostData(data);
      //   temp.outletName = data.nama;
      //   temp.phoneNumber = data.noTelepon;
      //   temp.email = data.email;
      //   temp.address = data.alamat;
      //   temp.postalCode = data.area.kodepos;
      //   temp.area.id = data.area.id;

      setItem(temp);
    } catch (err) {}
  };

  const getProduct = async (query) => {
    // try {
    //   let res = await employeeService.getAllPage(http.mapQueryCriteria(query));
    //   setLoading(true);
    //   console.log(res);
    //   let { data, headers } = res;
    //   setTotalData(headers["x-total-count"]);
    //   setProduct(data);
    // } catch (err) {}
    let temp = JSON.parse(sessionStorage.getItem("stockAdjustmentTemporary"));
    if (temp) {
      setAdjustmentProduct(temp);
      setTotalData(temp.length);
      // console.log(temp)
      sessionStorage.removeItem("stockAdjustmentTemporary");
    }
  };

  useEffect(() => {
    initData();
    getProduct(query);
  }, []);

  const submitData = async (item, callback) => {
    setDisabled(true);

    // console.log(item)
    let tempData = postData;

    tempData.posPenyesuaianStok.keterangan = item.keterangan;
    tempData.posPenyesuaianStok.posOutlet.id = props.match.params.outletId;
    tempData.npwpHead.id = props.match.params.npwpHeadId;
    tempData.kategori = item.isBahanBaku

    adjustmentProduct.map((d) => {
      tempData.listPosPenyesuaianStokProduk.push({
        id: null,
        jumlahStokSebelum: d.availableStock,
        jumlahStokSesudah: Number(d.physicalStock),
        posOutletProduk: {
          id: d.productId,
        },
      });
    });

    console.log(tempData.listPosPenyesuaianStokProduk);

    if (tempData.listPosPenyesuaianStokProduk.length === 0) {
      callback(t.translate("words.productNull"));
      setDisabled(false);
    } else {
      try {
        let res = await stockAdjustmentService.createStockAdjustment(tempData);
        console.log(res);
        let { status } = res;
        if (status === 200) {
          setDisabled(false);

          navigationStore.redirectTo(backTo);
        }
        callback("success", false);
      } catch (err) {
        setDisabled(false);
        callback(err, true, false);
      }
    }
  };

  const addItem = () => {
    setShowForm(true);
    setSelectedItem(null);
  };

  const deleteAdjustmentStock = (e, i) => {
    let temp = [...adjustmentProduct];

    temp.splice(i, 1);
    setAdjustmentProduct(temp);
  };

  const setNewQuery = (newQuery) => {
    setQuery({ ...query, ...newQuery });
  };

  const fetchProduct = async (isBahanBaku) => {
    query.page = 1;
    query.size = 10000;
    let newQuery = http.mapQueryCriteria(query);
    newQuery.npwpHeadId = props.match.params.npwpHeadId;
    newQuery["posOutletId.equals"] = outletId
    newQuery["isBahanBaku.equals"] = isBahanBaku;
    
    try {
      let res = await purchaseService.getProductList(newQuery);
      let { data, headers } = res;

      setTotalData(headers["x-total-count"]);
      let temp = [];
      data.map((d) => {
        if (d.isTrackingPersediaan) {
          temp.push({
            value: d.id,
            label: d.sbkProduk.nama,
            stock: d.stokPersediaan,
            isTrackingPersediaan: d.isTrackingPersediaan,
          });
        }
      });

      // data.stokPersediaan
      // console.log(item);

      setProduct(temp);
      // setDisabled(false);
      // setQuery({});
    } catch (err) {}
  };

  return (
    <>
      <FormWrapper
        //   actions={[
        //     {
        //       label: 'Reload',
        //       iconClassName: 'mdi mdi-reload'
        //     }
        //   ]}
        backTo={backTo}
        baseId="stock-adjustment-form"
        title={t.translate("modules.penyesuaianPersediaan.form")}
        hintShowIcon={false}
        defaultData={item}
        definitions={[
          {
            id:"isBahanBaku",
            inputType: inputTypes.SELECT,
            label: "Produk atau Bahan Baku?",
            key:"isBahanBaku",
            options: isBahanBakuSelect.map((d) => ({
              value: d.value,
              children: d.name
            })),
            labelKey: "children",
            disabled: disabled,
            require:true,
          },
          {
            inputType: inputTypes.TEXTAREA,
            label: t.translate("words.keterangan"),
            key: "keterangan",
            disabled: disabled,
            required: true
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message={t.translate("words.adjustmentProduct")}
                />
                <div style={{ display: "flex" }}>
                  <Button
                    theme="primary"
                    themeType="contained"
                    style={{ margin: 15 }}
                    onClick={() => addItem()}
                  >
                    <FontIcon iconClassName="mdi mdi-plus" />
                    {t.translate("words.add")}
                  </Button>

                  {/* <Button
                    buttonType="icon"
                    style={{ marginTop: "1.5vh" }}
                    onClick={(e) => getProduct(query)}
                  >
                    <FontIcon iconClassName="mdi mdi-reload" />
                  </Button> */}
                </div>

                <div style={{ maxHeight: "23rem", overflow: "auto" }}>
                  <Table fullWidth>
                    <TableHeader sticky>
                      <TableRow>
                        <TableCell>{t.translate("words.non")}</TableCell>
                        <TableCell>{t.translate("words.namaProduk")}</TableCell>
                        <TableCell>
                          {t.translate("words.availableStock")}
                        </TableCell>
                        <TableCell>
                          {t.translate("words.physicalStock")}
                        </TableCell>
                        <TableCell>{t.translate("words.adjustment")}</TableCell>
                        <TableCell>
                          {t.translate("mpk.column.actions")}
                        </TableCell>
                      </TableRow>
                    </TableHeader>

                    <TableBody>
                      {!loading ? (
                        adjustmentProduct
                          .slice(
                            (query.page - 1) * query.size,
                            (query.page - 1) * query.size + query.size
                          )
                          .map((d, i) => {
                            return (
                              <TableRow key={i}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{d.produk}</TableCell>
                                <TableCell>{d.availableStock}</TableCell>
                                <TableCell>{d.physicalStock}</TableCell>
                                <TableCell>{d.adjustment}</TableCell>
                                <TableCell>
                                  <Button
                                    buttonType="icon"
                                    style={{ marginTop: "1.5vh" }}
                                    onClick={(e) => deleteAdjustmentStock(e, i)}
                                  >
                                    <FontIcon iconClassName="mdi mdi-delete" />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <div style={{ marginLeft: "120%" }}>
                          <LoaderInfo />
                        </div>
                      )}
                    </TableBody>

                    {/* <TableFooter> */}
                    {/* </TableFooter> */}
                  </Table>
                </div>
                <>
                  <Pagination
                    baseId={"adjusment-form-pagination"}
                    className="flex"
                    page={query.page}
                    size={query.size}
                    totalData={totalData}
                    onChange={(newQuery) => setNewQuery(newQuery)}
                  />
                </>
              </div>
            ),
          },
        ]}
        onChange={(data, key, value) => {
          if (key === "isBahanBaku") {
            if(value == "1"){
              fetchProduct(true)
              setIsBahanBaku("1")
            } else if(value =="2"){
              fetchProduct(false)
              setIsBahanBaku("2")
            }
          }
          return data;
        }}
        submitLabel="Submit"
        onSubmit={!disabled ? submitData : null}
      />

      <AddNewProduct
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={adjustmentProduct}
        isBahanBaku={isBahanBaku}
        onSubmitRequest={getProduct}
        product = {product}
        {...props}
      />
    </>
  );
};

export default inject(
  "envStore",
  "navigationStore"
)(observer(StockAdjustmentForm));
