import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { defaultQueryParameter } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";

const defaultData = () => ({
  produk: "",
  jumlah: "",
  price: 0,
  total: "",
  posOutletProdukId: 0,
});

const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const AddPenjualanItem = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  ...props
}) => {
  const { inputTypes } = FormWrapper;
  let [item, setItem] = useState(defaultData());
  let [id, setId] = useState(null);
  let [ready, setReady] = useState(true);
  let [disabled, setDisabled] = useState(false);
  const [totalData, setTotalData] = useState(0);
  let posOutl;
  const [query, setQuery] = useState(
    defaultQuery({
      page: 0,
      size: 9999,
      sort: "tanggalDibuat,DESC",
    })
  );

  let [postData, setPostData] = useState([]);
  const [products, setProduct] = useState([]);

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        setPostData(props.item);
      }
      setItem(selectedItem);

      if (props.product) {
        setProduct(props.product);
      }
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
    setDisabled(true);
    let temp = [...postData];
    temp.push(item);
    sessionStorage.setItem("purchaseTemporary", JSON.stringify(temp));
    callback("success", false);
    setDisabled(false);
    onSubmitRequest();
    // window.location.reload(false);
  };

  const closeForm = () => {
    onRequestClose();
  };

  return (
    ready && (
      <DataForm
        baseId="inventory-form"
        title={"Pilih Produk"}
        visible={visible}
        data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("words.produk"),
            key: "produk",
            // validation: "required",
            labelKey: "label",
            valueKey: "label",
            data: products,
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.jumlahBarang"),
            type: "number",
            key: "jumlah",
            disabled: disabled,
          },
          {
            id: "price",
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: "Harga Satuan",
            type: "number",
            key: "price",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.total"),
            type: "number",
            key: "total",
            disabled: true,
          },
        ]}
        onChange={(data, key, value) => {
          if (data["jumlah"] || data["price"]) {
            data["total"] = data["jumlah"] * data["price"];
          }

          if (key === "produk") {
            products.map((d) => {
              if (d.label == value) {
                data["price"] = d.price;
                data["total"] = data["price"];
                data.posOutletProdukId = d.value;
              }
            });
          }

          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default AddPenjualanItem;
