import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import employeeService from "./Employee.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { toast } from "../../libs/react-mpk/services";

const defaultData = () => ({
  oldPin: "",
  newPin: "",
  newPinConfirm: "",
});

const EmployeePinForm = ({
  visible = false,
  onRequestClose = () => {},
  firstTime = false,
  ...props
}) => {
  let [item, setItem] = useState(defaultData());
  let [ready, setReady] = useState(true);
  let [postData, setPostData] = useState({
    id: null,
    pin: "",
  });
  let [dataUser, setDataUser] = useState(null);
  let [disabled, setDisabled] = useState(false);

  const initData = async () => {
    if (visible) {
      if (props.item) {
        try {
          let res = await employeeService.getEmployeeById(props.item);
          let { data } = res;
          setDataUser(data);
          setPostData({ ...postData, id: data.id });
        } catch (err) {
          toast.errorRequest(err);
        }
      }
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
    let postItem = postData;

    setDisabled(true);

    postItem.pin = item.newPin;

    if (firstTime) {
      let firstEmployee = JSON.parse(sessionStorage.getItem("firstEmployee"));
      postItem.id = firstEmployee.id;
    }
    if (item.oldPin != dataUser.pin) {
      callback("PIN lama salah", true, false);
    } else if (item.newPin != item.newPinConfirm) {
      callback("PIN baru tidak sama", true, false);
    } else if (item.newPin.length != 4) {
      callback("PIN baru harus 4 digit", true, false);
    } else if (item.newPin == dataUser.pin) {
      callback("PIN baru tidak boleh sama dengan PIN lama", true, false);
    } else {
      try {
        const res = await employeeService.updateEmployeePin(postItem);
        let { data, status } = res;
        if (status == 200) {
          setDisabled(false);
          TableWrapper.updateDataItem("employee-table", { id: data.id }, data);
          TableWrapper.reload("employee-table");
          if (firstTime) {
            window.location.reload();
          }
        }
        callback("success", false);
      } catch (err) {
        setDisabled(false);
        callback(err, true, false);
      }
    }
  };

  return (
    ready && (
      <DataForm
        baseId="employee-pin-form"
        title={t.translate("modules.employee.setPinTitle")}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        style={{ maxWidth: "35vh" }}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "PIN lama",
            key: "oldPin",
            type: "password",validation: "required",

            maxLength: 4,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "PIN baru",
            key: "newPin",
            type: "password",validation: "required",

            maxLength: 4,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Konfirmasi PIN baru",
            key: "newPinConfirm",
            type: "password",validation: "required",

            maxLength: 4,
          },
        ]}
        onChange={(data, key, value) => {
          if (key === "oldPin") {
            data.oldPin = String(value).replace(/\D/g, "");
            if (value.length > 4) {
              alert("Maksimal PIN 4 karakter");
            }
          }
          if (key === "newPin") {
            data.newPin = String(value).replace(/\D/g, "");
            if (value.length > 4) {
              alert("Maksimal PIN 4 karakter");
            }
          }
          if (key === "newPinConfirm") {
            data.newPinConfirm = String(value).replace(/\D/g, "");
            if (value.length > 4) {
              alert("Maksimal PIN 4 karakter");
            }
          }

          return data;
        }}
        onSubmit={submitData}
      />
    )
  );
};

export default EmployeePinForm;
