import React, { useEffect, useState } from 'react'
import { Router, Switch, Route } from "react-router-dom";
import { PageWrapper } from "../libs/react-mpk/wrapper";
import { sso } from '../libs/react-mpk/hocs';
import AkunNpwp from '../modules/AkunNpwp/AkunNpwp';
import Premium from '../modules/Premium/Premium';
import { inject, observer } from 'mobx-react';


const MenuAkunNpwp = ({ history, match, envStore, ...props }) => {

    var sidebarMenuActive = [
        {
          label: "Menu",
        },
        {
          label: "Menu Utama",
          children: [
            {
              label: "Pemilik Outlet",
              index: "akun, npwp, akunNpwp",
              resourceUri: "",
              iconClassName: "mdi mdi-table",
              path: "/internal/pemilik-outlet",
              childPath: [],
            },
            // {
            //   label: "Premium",
            //   index: "premium",
            //   resourceUri: "",
            //   iconClassName: "mdi mdi-table",
            //   path: "/internal/pemilik-outlet/premium",
            //   childPath: [],
            // }
          ],
        },
      ];
      
  return (
    <>
    
    <PageWrapper
        inverseTheme={true}
        style={{ background: "#D14C21" }}
        sidebarCollapsible={true}
        sidebarDefaultCollapse={true}
        sidebarMenu={sidebarMenuActive}  
        sidebarHeader={
          <>
            <div style={{paddingLeft:20, paddingTop:20}}>
              Version {envStore.env.appInfo.version}
            </div>
          </>
        }
    >
    
    <Router history={history}>
          <Switch>
            <Route
              path="/internal/pemilik-outlet/premium"
              render={(props) => <Premium {...props} />}
            />
            <Route
              path="/internal/pemilik-outlet"
              render={(props) => <AkunNpwp {...props} />}
            />
          </Switch>
        </Router>
    </PageWrapper>
    
    </>
  )
}
export default inject('envStore')(observer(MenuAkunNpwp));
// export default MenuAkunNpwp;