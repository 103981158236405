import { crud } from '../../libs/react-mpk/services';
import http from '../../libs/react-mpk/services/http.service'

let outletService = new crud('/outlet');
let serviceUrl = '/outlet'
let npwpHeadId = localStorage.getItem('npwpHeadId')

outletService.createOutlet = async (data) => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}`,
        data
    })
}

outletService.updateOutlet = async (data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}`,
        data
    })
}

outletService.getByNpwpHeadId = async (npwpId) => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/get_one_by_npwp_head_id/${npwpId}`
    })
}

outletService.getOutletById = async (outletId) => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/${outletId}`
    })
}

export default outletService;