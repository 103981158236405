import React, { useEffect, useState } from "react";
import {
  DataForm,
  Hint,
  LoaderInfo,
  Pagination,
} from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import {
  defaultQueryParameter,
  sorts,
} from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import {
  Button,
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
} from "react-md";
import stockAdjustmentService from "./StockAdjustmentService";

const defaultData = () => ({
  keterangan: "",
});
const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const StockAdjustmentDetail = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  const [query, setQuery] = useState(
    defaultQuery({
      page: 1,
      size: 5,
      sort: sorts.descending,
      sortBy: "tanggalDibuat",
    })
  );
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adjustmentProduct, setAdjustmentProduct] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const setNewQuery = (newQuery) => {
    setQuery({ ...query, ...newQuery });
  };

  const initItem = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        try {
          let res = await stockAdjustmentService.stockAdjustmentGetDetail(
            props.item
          );
          let { data } = res;

          // console.log(data)
          setAdjustmentProduct(data.listPosPenyesuaianStokProduk);
          setTotalData(data.listPosPenyesuaianStokProduk.length);
          // selectedItem.keterangan =
        } catch (err) {}
      }
      if (props.desc) {
        selectedItem.keterangan = props.desc;
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initItem();
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="stock-adjustment-detail"
        title={t.translate("modules.penyesuaianPersediaan.detail")}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: inputTypes.TEXTAREA,
            label: t.translate("words.keterangan"),
            key: "keterangan",
            disabled: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message={t.translate("words.adjustmentProduct")}
                />

                <div style={{maxHeight: '23rem', overflow: 'auto'}}>
                  <Table fullWidth>
                    <TableHeader>
                      <TableRow>
                        <TableCell>{t.translate("words.non")}</TableCell>
                        <TableCell>{t.translate("words.namaProduk")}</TableCell>
                        <TableCell>
                          {t.translate("words.availableStock")}
                        </TableCell>
                        <TableCell>
                          {t.translate("words.physicalStock")}
                        </TableCell>
                        <TableCell>{t.translate("words.adjustment")}</TableCell>
                      </TableRow>
                    </TableHeader>

                    <TableBody>
                      {!loading ? (
                        adjustmentProduct
                          .slice(
                            (query.page - 1) * query.size,
                            (query.page - 1) * query.size + query.size
                          )
                          .map((d, i) => {
                            return (
                              <TableRow key={i}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>
                                  {d.posOutletProduk &&
                                    d.posOutletProduk.sbkProduk.nama}
                                </TableCell>
                                <TableCell>{d.jumlahStokSebelum}</TableCell>
                                <TableCell>{d.jumlahStokSesudah}</TableCell>
                                <TableCell>
                                  {d.jumlahStokSesudah - d.jumlahStokSebelum}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <div style={{ marginLeft: "120%" }}>
                          <LoaderInfo />
                        </div>
                      )}
                    </TableBody>
                  </Table>
                </div>

                <Pagination
                  baseId={"adjusment-form-pagination"}
                  className="flex"
                  page={query.page}
                  size={query.size}
                  totalData={totalData}
                  onChange={(newQuery) => setNewQuery(newQuery)}
                />
              </div>
            ),
          },
        ]}
        onBeforeChange={(key, value) => {
          console.log(key, value);
          return value;
        }}
        onSubmit={null}
      />
    )
  );
};

export default StockAdjustmentDetail;
