import React, { useEffect } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { useState } from "react";
import t from "counterpart";
import premiumService from "./Premium.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import moment from "moment";

const PremiumForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let bulan =[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
  const defaultData = () => ({
    bulan: 12,
    pengguna: {
      id: 134,
    },
  });

  let [item, setItem] = useState(null);
  let [id, setId] = useState(null);
  let [ready, setReady] = useState(true);
  let [postData, setPostData] = useState({
    bulan : "",
    pengguna: {
      id: '',
    },
  });
  let [disabled, setDisabled] = useState(false);
  let [pengguna, setPengguna] = useState({})

  const initData = async () => {
    if (visible) {
      getPengguna();
      let selectedItem = defaultData();
      if (props.item) {
        try {
          let res = await premiumService.getPremiumById(props.item);
          let { data } = res;
        
          selectedItem.id = data.id
          selectedItem.app = data.app;
          selectedItem.tanggalPremiumAwal = data.tanggalPremiumAwal;
          selectedItem.tanggalPremiumAkhir = data.tanggalPremiumAkhir
          selectedItem.tanggalBerakhir = data.tanggalBerakhir;
          selectedItem.bulan = data.bulan
          selectedItem.pengguna.id = data.pengguna.id
          selectedItem.status = data.status
        } catch (err) {
            console.log(err);
        }
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  const getPengguna = async() => {
      try{
          //service get pengguna
          let res = await premiumService.getPengguna(id);
          let { data } = res
          setPengguna(res.data)
      }
      catch(err){
        console.log(err);
      }
  }


  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
    setDisabled(true);

    let postItem = postData;

    // 
    // postItem.tanggalBerakhir = moment(item.tanggalBerakhir).format("YYYY-MM-DD HH:mm:ss");
    postItem.bulan = item.bulan
    postItem.pengguna.id = pengguna.id

    try {
      const res = await premiumService.createPremium(postItem);
      let { data, status } = res;
      if (status == 200) {
        setDisabled(false);
        TableWrapper.updateDataItem("premium-table", { id: data.id }, data);
        TableWrapper.reload("premium-table");
      }
      callback("success", false);
    } catch (err) {
      setDisabled(false);
      callback(err, true, false);
    }
  };

  const closeForm = () => {
    setPostData({
        bulan: '',
        pengguna: {
          id: '',
        },
    });
    onRequestClose();
  };

  return (
    ready && (
      <DataForm
        baseId="premium-form"
        title={t.translate("modules.premium.form")}
        visible={visible}
        // data={item}
        onRequestClose={() => closeForm()}
        asDialog={true}
        defaultData={item}
        definitions={[
          // {
          //   inputType: DataForm.inputTypes.CHECKBOX,
          //   label: t.translate(`words.semuaAplikasi`),
          //   key:"semuaAplikasi",
          //   disabled:disabled,
          // },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate(`words.bulan`),
            key: "bulan",
            validation: "required",
            options: bulan,
            disabled: disabled,
          },
        ]}
        onChange={(data, key, value) => {
          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default PremiumForm;
