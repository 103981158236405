import { crud, http } from "../../libs/react-mpk/services";
let premiumService = null;
let serviceUrl = "/pengguna_premium";
let app = 7;
premiumService = new crud("/pengguna_premium");

premiumService.cekPremium = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/cek_premium`,
  });
};

premiumService.getPengguna = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/pengguna/get_one`,
  });
}

premiumService.getPremiumById = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

premiumService.getPagePremium = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

premiumService.createPremium = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data
  })
}

premiumService.accPremium = async(id,data) => {
  console.log("acc");
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/acc_premium/${id}`,
    data
  })
}

premiumService.cancelPremium = async(id,data) => {
  console.log("cancel");
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/cancel_premium/${id}`,
    data
  })
}

premiumService.rejectPremium = async (id,data) => {
  console.log("reject");
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/reject_premium/${id}`,
    data
  })
}

export default premiumService;
