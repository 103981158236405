import { crud, http } from '../../libs/react-mpk/services';

let supplierService = new crud('/pos_pelanggan');
let serviceUrl = "/pos_pelanggan"
// let npwpHeadId = localStorage.getItem('npwpHeadId')


supplierService.getPage = (query) => {
  // console.log(query, "QUERY")
  query = {
    ...query,
    // npwpHeadId: npwpHeadId
  }

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
  
}

supplierService.createSupplier = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

supplierService.updateSupplier = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

supplierService.getOneSupplier = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

supplierService.deleteSupplier = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};


export default supplierService;