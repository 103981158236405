import { crud, http } from "../../libs/react-mpk/services";

let reportService = new crud("/report");
let serviceUrl = "/report";

reportService.getGrossReport = (query) => {
  query = {
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/laba_kotor`,
    query,
  });
};

export default reportService;
