import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import categoryService from "../Category/Category.service";
import manajemenRakService from "./ManajemenRak.service";
import { Text } from "react-md";

const ManajemenRakForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const defaultData = () => ({
    kodeRak: "",
    namaRak: "",
    lantai: "",
    tingkatRak: "",
    keterangan: "",
    posOutlet: {
      id: props.match.params.outletId,
    },
  });
  let [item, setItem] = useState(null);
  let [id, setId] = useState(null);
  let [ready, setReady] = useState(true);
  let [postData, setPostData] = useState({
    kodeRak: "",
    namaRak: "",
    lantai: "",
    tingkatRak: "",
    keterangan: "",
    posOutlet: {
      id: props.match.params.outletId,
    },
  });
  let [disabled, setDisabled] = useState(false);

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        try {
          // let res = await categoryService.getCategoryById(props.item);
          // let { data } = res;
          // // setPostData(data);

          // selectedItem.id = data.id;
          // selectedItem.kode = data.kode;
          // selectedItem.nama = data.nama;

          let res = await manajemenRakService.getManajemenRakById(props.item);
          let { data } = res;

          selectedItem.id = data.id;
          selectedItem.kodeRak = data.kodeRak;
          selectedItem.namaRak = data.namaRak;
          selectedItem.lantai = data.lantai;
          selectedItem.tingkatRak = data.tingkatRak;
          selectedItem.keterangan = data.keterangan;
          selectedItem.posOutlet.id = data.posOutlet.id;
        } catch (err) {}

        // selectedItem = cloneDeep(props.item);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
      // console.log(item, "item");
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
    setDisabled(true);

    let postItem = postData;
    // console.log(sessionStorage.getItem("outletId"), "posOutletId");
    postItem.posOutlet = {
      id: props.match.params.outletId,
    };
    postItem.kodeRak = item.kodeRak;
    postItem.namaRak = item.namaRak;
    postItem.lantai = item.lantai;
    postItem.tingkatRak = item.tingkatRak;
    postItem.keterangan = item.keterangan;
    console.log(postItem, "postItem");

    if (item.id) {
      try {
        // const res = await categoryService.updateCategory(postItem, item.id);
        // let { data, status } = res;
        // if (status == 200) {
        //   setDisabled(false);
        //   TableWrapper.updateDataItem("category-table", { id: data.id }, data);
        //   TableWrapper.reload("category-table");
        // }
        // console.log(res, 'res')
        const res = await manajemenRakService.updateManajemenRak(
          postItem,
          item.id
        );
        let { data, status } = res;
        if (status == 200) {
          setDisabled(false);
          TableWrapper.updateDataItem("rak-table", { id: data.id }, data);
          TableWrapper.reload("rak-table");
        }
        callback("success", false);
      } catch (err) {
        // console.log(err, 'err')
        setDisabled(false);
        callback(err, true, false);
      }
    } else {
      try {
        // const res = await categoryService.createCategory(postItem);
        // let { data, status } = res;
        // if (status == 200) {
        //   setDisabled(false);
        //   TableWrapper.updateDataItem("category-table", { id: data.id }, data);
        //   TableWrapper.reload("category-table");
        // }
        // console.log(res, 'res')

        const res = await manajemenRakService.createManajemenRak(postItem);
        let { data, status } = res;
        if (status == 200) {
          setDisabled(false);
          TableWrapper.updateDataItem("rak-table", { id: data.id }, data);
          TableWrapper.reload("rak-table");
        }

        callback("success", false);
      } catch (err) {
        // console.log(err, 'err')
        setDisabled(false);
        callback(err, true, false);
      }
    }
  };

  const closeForm = () => {
    setPostData({
      kodeRak: "",
      namaRak: "",
      lantai: "",
      tingkatRak: "",
      keterangan: "",
      posOutlet: {
        id: sessionStorage.getItem("outletId"),
      },
    });
    onRequestClose();
  };

  return (
    ready && (
      <DataForm
        baseId="manajemenRak-form"
        title={t.translate("modules.manajemenRak.rak.form")}
        visible={visible}
        // data={item}
        onRequestClose={() => closeForm()}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.kodeRak`),
            key: "kodeRak",
            validation: "required",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.namaRak`),
            key: "namaRak",
            validation: "required",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.lantai`),
            key: "lantai",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.tingkatRak`),
            key: "tingkatRak",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.keterangan`),
            key: "keterangan",
            maxLength: 50,
            disabled: disabled,
            style:{marginBottom:0}
          },
          {
            render: (
              <>
                <Text style={{marginTop:0,fontSize:12,color:'grey'}}>(Max 50 Character)</Text>
              </>
            ),
          },
        ]}
        onChange={(data, key, value) => {
          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default ManajemenRakForm;
