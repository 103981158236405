import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import categoryService from "./Category.service";
import CategoryForm from "./CategoryForm";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";

const Category = ({ className = "", showCommandbar = true, modalStore, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const deleteCategory = async (item) => {
    try {
      let res = await categoryService.deleteCategory(item.id);

      let { data, status } = res;

      if (status == 200) {
        iziToast.success({
          message: data.message,
        });

        TableWrapper.updateDataItem("category-table", { id: data.id }, data);
        TableWrapper.reload("category-table");
      } else {
        iziToast.info({
          message: data.message,
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message
      })
    }
  };

  const deleteItem = async (item) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        deleteCategory(item);
        callback();
      },
    });
  };

  return (
    <>
      <TableWrapper
        baseId="category-table"
        title={t.translate("modules.kategoriUnit.kategori.title")}
        className={className}
        hintMessage={t.translate("modules.kategoriUnit.kategori.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.kategoriUnit.kategori.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // multiColumnFilter={true}
        // extraFilter={<div>Test</div>}
        columns={[
          {
            label: t.translate('words.kode'),
            searchable: true,
            sortable: false,
            key: "kode",
            render: (item) => item.kode,
          },
          {
            label: t.translate('words.name'),
            searchable: true,
            sortable: false,
            key: "nama",
            render: (item) => item.nama,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
                setShowForm(true);
                setSelectedItem(null);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => deleteItem(item),
            true
          ),
        ]}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              let page = await categoryService.getPageCategory(query);
              if(page.headers["x-total-count"])
                page.headers["x-pagination-count"] = page.headers["x-total-count"];

              resolve(page);
            } catch (err) {
              // resolve(err);
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <CategoryForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />
    </>
  );
};

export default inject('modalStore')(observer(Category));