import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import productService from "./Product.service";

const defaultData = () => ({
  bahanBaku:'',
  bahanBakuId:0,
  jumlah:"",
})


var thousandSeparator = ".";
var decimalSeparator = ",";

const ProductBahanBakuForm = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  ...props
}) => {
    let [disabled,setDisabled] = useState(false)
    let [namaBahanBakuList, setNamaBahanBakuList] = useState([])
    let [namaProdukList,setNamaProdukList] = useState([])
    const [selectedItem, setSelectedItem] = useState(null);
    let [totalData, setTotalData] = useState(0)
    const [ready, setReady] = useState(true)
    let [bahanBakuList, setBahanBakuList] = useState([])
    let [currBahanBaku, setCurrBahanBaku] = useState({})

    const getBahanBaku = async () => {
      setDisabled(true);
      try{
        let res = await productService.getBahanBakuList();
        // console.log(res);
        setTotalData(res.headers["x-total-count"]);

        let bahanBaku = [];
        res.data.map((d) => {
          d.value = d.id
          d.label = d.sbkProduk.nama
          // bahanBaku.push({
          //   value:d.id,
          //   label: d.sbkProduk.nama,
          // });
        });
        setBahanBakuList(res.data);
        setDisabled(false)
      } catch (err){
        console.log(err,"error");
      }
    }

    useEffect(() => {
      initData();
      getBahanBaku();
    }, [visible])

    let [postData, setPostData] = useState([])

    const initData = async () => {
      if(visible) {
        let selectedItem = defaultData();
        if (props.item) {
          setPostData(props.item);
        }
        setSelectedItem(selectedItem);
      } else {
        setSelectedItem(defaultData());
      }
      setReady(visible);
      getBahanBaku();
    }

    const submitData = async (item,callback) => {
      setDisabled(true);
      // item.bahanBaku = currBahanBaku
      let temp = [...postData]
      temp.push(item)
      sessionStorage.setItem("productBahanBaku",JSON.stringify(temp));
      callback("success",false);
      setDisabled(false);
      onSubmitRequest();

      
    }

  return (
    ready && (
    <DataForm
      baseId="product-bahan-baku-form"
      title={t.translate("modules.produk.bahanbaku.form")}
      visible={visible}
      onRequestClose={onRequestClose}
      asDialog={true}
      defaultData={selectedItem}
      definitions={[
        {
            inputType:DataForm.inputTypes.AUTOCOMPLETE,
            label:t.translate("words.namaBahanBaku"),
            key:"bahanBaku",
            labelKey: "label",
            valueKey:"label",
            data:bahanBakuList,
            disabled:disabled,
            required:true,
        },
        {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.jumlah"),
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            key:"jumlah",
            disabled: disabled,
            required: true
        }
    ]}
    onSubmit={!disabled ? submitData : null}
    onChange={(data,key,value) => {
      if(key === "bahanBaku"){
        let bahan = bahanBakuList.find(d => d.label ==  value)
        if (bahan){
          data.bahanBakuId = bahan.id
          data.currBahanBaku = bahan
        }
      }
      

      if (key === "jumlah") {
        if (value <0){
          data.jumlah = 0;
        }
      }
    }}
    />
    )
  );
};

export default ProductBahanBakuForm;
