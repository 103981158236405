import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find, cloneDeep } from "lodash";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableRow,
} from "react-md";

import { defaultQueryParameter } from "../../libs/react-mpk/config/constant";
import { format } from "../../libs/react-mpk/services/number.service";
import penjualanService from "./Penjualan.service";
import { LoaderInfo } from "../../libs/react-mpk/components";
import ModalLunasForm from "./modalLunasForm";

const defaultData = () => ({
  keterangan: "",
});
const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const PenjualanDetail = ({ envStore, navigationStore, ...props }) => {
  const { inputTypes } = FormWrapper;
  const outletId = props.match.params.outletId;
  const npwpHeadId = props.match.params.npwpHeadId;
  const [item, setItem] = useState({});
  const [fkProdukList, setfkProdukList] = useState([]);
  const [totalProduk, setTotalProduk] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    initData();
  }, []);
  const addItem = () => {
    setShowForm(true);
  };
  const initData = async () => {
    setLoading(true);
    let selectedItem = defaultData();
    if (props.match.params.id) {
      let res = await penjualanService.penjualanGetDetail(
        props.match.params.id
      );
      let { data } = res;
      selectedItem.id = data.id;
      selectedItem.karyawan = data.posKaryawan.nama;
      selectedItem.noTransaksi = data.noTransaksi;
      selectedItem.customer = data.sbkFk.lawanTransaksi.nama;
      selectedItem.jenisTransaksi =
      data.jenisTransaksi == 1 ? "Bayar Nanti" : "Tunai";
      selectedItem.status =
        data.sbkFk.pembayaran.status < 0
          ? "Terlambat bayar"
          : data.sbkFk.pembayaran.status == 1
          ? "Belum Dibayar"
          : data.sbkFk.pembayaran.status == 2
          ? " Dibayar Sebagian"
          : data.sbkFk.pembayaran.status == 4
          ? "Batal"
          : "Lunas";
      selectedItem.nominalDiterima =
        data.nominalDiterima != 0 ? `Rp. ${format(data.nominalDiterima)}` : "0";
      selectedItem.nominalTagihan =
        data.nominalTagihan && `Rp. ${format(data.nominalTagihan)}`;
      setfkProdukList(data.fkProdukList);
      setTotalProduk(
        data.fkProdukList
          .map((d) => d.hargaTotal)
          .reduce(
            (previousScore, currentScore, index) =>
              previousScore + currentScore,
            0
          )
      );
    }
    setItem(selectedItem);
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <div style={{ marginLeft: "120%" }}>
          <LoaderInfo />
        </div>
      ) : (
        <FormWrapper
          backTo={`/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/penjualan`}
          baseId="penjualan-form"
          title="Detail Penjualan"
          hintShowIcon={false}
          defaultData={item}
          definitions={[
            {
              id: "noTransaksi",
              inputType: inputTypes.INPUT,
              label: "No Transaksi",
              key: "noTransaksi",
              disabled: true,
            },
            {
              id: "customer",
              inputType: inputTypes.INPUT,
              label: "Nama Customer",
              key: "customer",
              disabled: true,
            },
            {
              id: "karyawan",
              inputType: inputTypes.INPUT,
              label: "Nama Karyawan",
              key: "karyawan",
              disabled: true,
            },
            {
              id: "jenisTransaksi",
              inputType: inputTypes.INPUT,
              label: "Jenis Transaksi",
              key: "jenisTransaksi",
              disabled: true,
              style: { width: "49%" },
            },
            {
              id: "status",
              inputType: inputTypes.INPUT,
              label: "Status",
              key: "status",
              disabled: true,
              style: { width: "49%", marginLeft: "2%" },
            },
            {
              id: "nominalDiterima",
              inputType: inputTypes.INPUT,
              label: "Nominal Diterima",
              key: "nominalDiterima",
              disabled: true,
              style: { width: "49%" },
            },
            {
              id: "nominalTagihan",
              inputType: inputTypes.INPUT,
              label: "Nominal Tagihan",
              key: "nominalTagihan",
              disabled: true,
              style: { width: "49%", marginLeft: "2%" },
            },
            {
              render: (
                <div style={{ width: "100%" }}>
                  <TableContainer style={{ maxHeight: "20rem" }}>
                    <Table align="center" fullWidth>
                      <TableHeader>
                        <TableRow>
                          <TableCell>{t.translate("words.non")}</TableCell>
                          <TableCell>
                            {t.translate("words.namaProduk")}
                          </TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Harga Satuan</TableCell>
                          <TableCell>{t.translate("words.total")}</TableCell>
                        </TableRow>
                      </TableHeader>

                      <TableBody border-bottom>
                        {fkProdukList.map((d, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{d.produk.nama}</TableCell>
                              <TableCell>{d.jumlahBarang}</TableCell>
                              <TableCell>Rp. {format(d.hargaSatuan)}</TableCell>
                              <TableCell>Rp. {format(d.hargaTotal)}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>

                      <TableFooter>
                        <TableRow>
                          <TableCell>Total:</TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Rp. {format(totalProduk)}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              ),
            },
            {
              render:
                parseInt(item.nominalDiterima) == 0 ||
                parseInt(item.nominalDiterima) <
                  parseInt(item.nominalTagihan) ? (
                  <Button style={{marginTop:'3%',marginBottom:'3%',color:'white',backgroundColor:"#44bd32"}} onClick={(data) => addItem(data)} themeType="contained" className={{color:"red"}}>Lunaskan Pembayaran</Button>
                ) : null,
            },
          ]}
          onChange={(data, key, value) => {
            return data;
          }}
          submitLabel={"Lunaskan Pembayaran"}
        />
      )}
      <ModalLunasForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
        }}
        totalProduk={totalProduk}
        {...props}
      />
    </>
  );
};

export default inject("envStore", "navigationStore")(observer(PenjualanDetail));
