import React, {useState, useEffect} from 'react'
import { Flex, DataView, Commandbar, Label, LoaderInfo } from '../components'
import { eventNames, eventTypes } from '../config/constant'
import t from 'counterpart'
import { chart, dashboardDefinition, chartData, summaryData } from '../class'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { merge } from 'lodash'

const getChannelName = (baseId) => (`${baseId}-dashboard-wrapper-event`)

class summaries{
  constructor(onFetchData, onInitData, render){
    this.onFetchData = onFetchData
    this.onInitData = onInitData
    this.render = render
  }
}

const DashboardWrapper = ({
  actions                 = [],
  baseId                  = 'mpk-dashboard-wrapper',
  backTo                  = null,
  className               = '',
  definitions             = [],
  showCommandbar          = true,
  title                   = null,
  subtitle                = null,
  useFilter               = true,
  maxPeriodUnit           = 'month',
  maxPeriodValue          = 3,
  usePeriod               = false,
  defaultQuery            = {},
  filterDefinitions       = [],
  filterOnChange          = null,
  onChangeFilter           = null,
  onBeforeChangeFilter    = null,
  queryDefinitions        = [],
  commandbarRightCorner   = null,
  commandbarLeftCorner    = null,
  onChangeQuery           = null,
  loading                 = false,
  intervalQuery           = 0,
  commandbar              = {},
  ...props
}) => {
  const [showFilter, setShowFilter] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [cbFetchData, setCbFetchData] = useState({})
  const channelName = `${baseId}-event`
  const periodDefinitions = [
    {
      inputType: DataView.Filter.inputTypes.DATE,
      label: t.translate('mpk.column.startDate'),
      key: 'startDate',
      type: 'date'
    },
    {
      inputType: DataView.Filter.inputTypes.DATE,
      label: t.translate('mpk.column.endDate'),
      key: 'endDate',
      type: 'date'
    }
  ]

  const defaultPeriod = usePeriod ? {
    startDate: moment(props.envStore.env.timestamp).add(-1*maxPeriodValue, maxPeriodUnit).format('YYYY-MM-DD'),
    endDate: moment(props.envStore.env.timestamp).format('YYYY-MM-DD')
  } : {}

  const [query, setQuery] = useState(defaultQuery ? merge(defaultPeriod, defaultQuery) : defaultPeriod)

  const reload = (reloadOnly=false) => {
    for(let key of Object.keys(cbFetchData)){
      cbFetchData[key](query)
    }
  }

  useEffect(() => {
    let tick;
    let channelName = getChannelName(baseId)
    if(intervalQuery && Number(intervalQuery) >= 1000) {
      tick = setInterval(() => {
        reload(true)
      }, intervalQuery)
    }
    window.addEventListener(channelName, e => {
      let { eventType, eventData } = e.detail
      switch(eventType){
        case eventTypes.RELOAD:
          if(eventData) setQuery({...eventData, ...defaultPeriod})
          else reload(true)
          break;
        default:
          break;
      }
    })

    return () => {
      clearInterval(tick)
      window.removeEventListener(channelName, () => {}, false)
    }
  }, [])

  useEffect(() => {
    if(!firstLoad){
      reload(true)
      setFirstLoad(false)
    } else {
      if(onChangeQuery) onChangeQuery(query);
    }
  }, [query])

  return (
    <>
      <Flex
        id={baseId}
        className={`mpk-dashboard-wrapper mpk-full full-width full-height mpk-padding-S padding-right ${className}`}
        direction={Flex.properties.direction.COLUMN}
        scrollable
      >
        { showCommandbar && (
          <Commandbar 
            title={`${title}`}
            backTo={backTo}
            subtitle={`
              ${subtitle ? `${subtitle} `: ''}
              ${usePeriod ? `${t.translate('mpk.column.period')} : ${moment(query.startDate).format('ll')}-${moment(query.endDate).format('ll')}` : ''}
            `}
            className="mpk-position position-sticky"
            style={{
              zIndex: 10,
              top: 0
            }}
            actions={[...actions, ...(useFilter ? [
              {
                label: t.translate('mpk.column.reload'),
                iconClassName: 'mdi mdi-reload',
                onClick: () => reload(true)
              },
              {
                label: t.translate('mpk.column.filter'),
                iconClassName: 'mdi mdi-filter',
                onClick: () => setShowFilter(!showFilter)
              }
            ] : [])]}
            rightCorner={commandbarRightCorner}
            leftCorner={commandbarLeftCorner}
            {...commandbar}
          />
        )}
        { loading ? <LoaderInfo>{t.translate('mpk.sentence.loadingData')}</LoaderInfo> : (
          <div className={`mpk-full full-width ${showCommandbar ? '' : 'mpk-padding-N padding-top padding-bottom'}`}>
            {definitions.map((def, defI) => (
              <div 
                key={`${baseId}-definition-${defI}`}
                className="mpk-full full-width"
              >
                {def.label && <Label>{def.label}</Label>}
                <Flex 
                  className="summary mpk-full full-width mpk-margin-S margin-bottom"
                  align={Flex.properties.align.CENTER}
                  wrap
                >
                  {def.summaries.map((sum, sumI) => (
                    <DataView.Summaries
                      key={`${baseId}-definition-${defI}-summary-${sumI}`}
                      channelName={`${channelName}-${defI}-summary-${sumI}`}
                      baseId={baseId}
                      onFetchData={sum.onFetchData}
                      onInitData={sum.onInitData}
                      render={sum.render}
                      defaultQuery={query}
                      query={query}
                      getHandleFetchData={callback => setCbFetchData( cb => {
                        cb[`summary${defI}${sumI}`] = callback
                        return cb
                      })}
                    />
                  ))}
                </Flex>
                <Flex 
                  className="chart mpk-full full-width"
                  align={Flex.properties.align.CENTER}
                  wrap
                >
                  {def.charts.map((ch, chI) => (
                    <DataView.Chart
                      className="mpk-margin-S margin-right margin-bottom"
                      channelName={`${channelName}-${defI}-chart-${chI}`}
                      key={`${baseId}-definition-${defI}-chart-${chI}`}
                      baseId={baseId}
                      label={ch.label}
                      onFetchData={ch.onFetchData}
                      onInitData={ch.onInitData}
                      chartConfig={ch.chartConfig}
                      dataConfig={ch.dataConfig}
                      defaultQuery={query}
                      query={query}
                      hintMessage={ch.hintMessage}
                      staticOptions={ch.staticOptions}
                      getHandleFetchData={callback => setCbFetchData( cb => {
                        cb[`chart${defI}${chI}`] = callback
                        return cb
                      })}
                    />
                  ))}
                </Flex>
              </div>
            ))}
            {props.children && (
              <div className="mpk-margin-S margin-right">{props.children}</div>
            )}
          </div>
        )}
      </Flex>
      {useFilter && (
        <DataView.Filter
          baseId={`${baseId}-filter`}
          visible={showFilter}
          focusOnMount={false}
          onRequestClose={() => setShowFilter(false)}
          maxPeriodUnit={maxPeriodUnit}
          maxPeriodValue={maxPeriodValue}
          definitions={usePeriod ? [...periodDefinitions, ...filterDefinitions, ...queryDefinitions] : [...filterDefinitions, ...queryDefinitions]}
          defaultData={query}
          usePeriod={usePeriod}
          onChange={onChangeFilter}
          onBeforeChange={onBeforeChangeFilter}
          onSubmit={(newQuery, callback) => {
            setQuery(newQuery)
            setTimeout(() => {
              setShowFilter(false)
              callback()
            }, 500)
          }}
        />
      )}
    </>
  )
}

DashboardWrapper.definition = dashboardDefinition
DashboardWrapper.summaries = summaries
DashboardWrapper.summaryData = summaryData
DashboardWrapper.chart = chart
DashboardWrapper.chartData = chartData
DashboardWrapper.inputTypes = DataView.Filter.inputTypes
DashboardWrapper.reload = (baseId, query=null) => {
  let channelName = getChannelName(baseId)
  window.dispatchEvent(new CustomEvent(channelName, {detail:{
    eventType: eventTypes.RELOAD,
    eventData: query
  }}))
}

export default inject('envStore')(observer(DashboardWrapper))
