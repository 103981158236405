import { crud, http } from "../../libs/react-mpk/services";

let manajemenRakService = new crud("/produk/manajemen_rak");
let serviceUrl = "/produk/manajemen_rak";
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let outletId = sessionStorage.getItem("outletId");

manajemenRakService.getManajemenRakById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

manajemenRakService.getPageManajemenRak = async (query) => {
  query = {
    ...query,
    "posOutletId.equals": outletId,
    npwpHeadId: npwpHeadId,
  };

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

manajemenRakService.createManajemenRak = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

manajemenRakService.updateManajemenRak = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

manajemenRakService.deleteManajemenRak = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

export default manajemenRakService;
