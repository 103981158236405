import React, { useEffect, useState } from "react";
import { DataForm, LoaderInfo, Modal } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import service from "./AkunNpwp.service";
import AreaService from "../../services/Area.service";
import { cloneDeep } from "lodash";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogFooter,
  Text,
  FontIcon,
} from "react-md";
import { toast } from "../../libs/react-mpk/services";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

const AkunNpwpForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  const PENGURUSANEFIN_SUDAH_MEMILIKI_EFIN = 1;
  const PENGURUSANEFIN_EFIN_DIURUS_TIM_SP = 2;
  const PENGURUSANEFIN_EFIN_DIURUS_SENDIRI = 3;
  const STATUSPERNIKAHAN_MENIKAH_INT = 1;
  const STATUSPERNIKAHAN_BELUM_MENIKAH_INT = 2;
  const STATUSPERNIKAHAN_CERAI_INT = 3;
  const defaultData = {
    npwpOpInt: "0",
    noKtp: "",
    memilikiNpwp: true,
    npwp: "",
    namaNpwp: "",
    noTelepon: "",
    email: "",
    alamat: "",
    kodepos: null,
    noEfin: "",
    pegawai: false,
    pemilikUsaha: true,
    pekerjaBebas: false,
    kluId: null,
    klu: null,
    pkp: false,
    tanggalPkp: null,
    jumlahTanggungan: "0",
    npwpPasangan: "",
    pisahHarta: false,
    statusNikah: 1,
    statusNikahStr: "Belum Menikah",
    tahun: new Date().getFullYear(),
    tanggalMulai: new Date(),
    kenaPphFinal: false,
    omzetSudahDihitung: 0,
  };
  let [dataSubmit, setDataSubmit] = useState({
    id: null,
    npwpHead: {
      npwpOp: true,
      namaNpwp: "",
      npwp: "",
      alamat: "",
      area: {
        id: null,
        jenis: "Tidak Ada",
        kabupaten: "Tidak Ada",
        kecamatan: "Tidak Ada",
        kelurahan: "Tidak Ada",
        kodepos: null,
        propinsi: "Tidak Ada",
      },
      email: "",
      pengurusanEfin: 1,
      noEfin: "",
      klu: { id: null },
      noKtp: "",
      noTelepon: "",
      noTeleponB: "",
      pkp: true,
      tanggalPkp: null,
    },
    jumlahTanggungan: "0",
    npwpPasangan: "",
    pegawai: false,
    pekerjaBebas: false,
    pemilikUsaha: false,
    pisahHarta: false,
    statusNikah: 1,
    tahun: new Date().getFullYear(),
    dataAwal: {
      tanggalMulai: new Date(),
      kenaPphFinal: false,
      omzetSudahDihitung: 0,
      kategoriCatat: 1,
    },
  });
  let [item, setItem] = useState(defaultData);
  let [ready, setReady] = useState(true);
  let [isDisabled, setIsDisabled] = useState(false);
  let [showDialogError, setShowDialogError] = useState(false);
  let [messageError, setMessageError] = useState(false);
  let [pkp, setPkp] = useState(true);
  let [nikah, setNikah] = useState(true);
  let [isEdit, setIsEdit] = useState(false);
  let [editRole, setEditRole] = useState(false);
  let [isBadan, setIsBadan] = useState(false);

  // VALIDASI
  let [isOp, setIsOp] = useState(true);
  let [isPunyaNpwp, setIsPunyaNpwp] = useState(true);
  let [isMenikah, setIsMenikah] = useState(false);
  let [isPisahHarta, setIsPisahHarta] = useState(false);
  const [inputValidasiNpwp, setInputValidasiNpwp] = useState(
    "##.###.###.#-###.###"
  );
  let [disabledNik, setDisabledNik] = useState(false);
  const [inputValidasiNik, setInputValidasiNik] = useState("################");
  const [inputValidasiKodepos, setInputValidasiKodepos] = useState("#####");
  let [disabledTanggalPkp, setDisabledTanggalPkp] = useState(false);
  const [dataKlu, setDataKlu] = useState([]);
  const [listKlu, setListKlu] = useState([]);
  const [loadingKlu, setLoadingKlu] = useState(false);
  const [loadingById, setLoadingById] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAll, setLoadingAll] = useState(true);
  let [queryKlu, setQueryKlu] = useState("");

  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = item;
        selectedItem.npwpOpInt = "0";
        setIsOp(true);
        setIsPunyaNpwp(true);
        setDisabledNik(false);
        setDisabledTanggalPkp(false);
        setIsEdit(false);
        // setIsDisabled(props.isDisabled);

        if (props.itemId != null) {
          try {
            setLoadingById(true);
            setIsEdit(true);
            let res = await service.getOneById(props.itemId);

            let { data } = res;
            console.log(data.npwpHead.klu.nama, "resKlu.data");
            await getDataKlu(data.npwpHead.klu.nama);
            // console.log(resKlu, "resKlu.data");
            // setListKlu(resKlu.data[0]);
            if (data.npwpOp == true) {
              setEditRole(true);
              setNikah(true);
              console.log(editRole);
              if ((data.pkp = true)) {
                setPkp(false);
              }
            }
            if (data.npwpOp == false) {
              setEditRole(false);
              console.log(editRole);
              setNikah(true);
              setPkp(false);
            }
            setDataSubmit(data);
            // console.log(editRole)
            //VALIDASI INPUT NPWP OP
            selectedItem.npwpOp = data.npwpHead.npwpOp;
            selectedItem.npwpOpInt = selectedItem.npwpOp ? "0" : "1";
            setIsOp(selectedItem.npwpOp);
            selectedItem.noKtp = data.npwpHead.noKtp;
            selectedItem.npwp = data.npwpHead.npwp;
            selectedItem.memilikiNpwp = true;
            setIsPunyaNpwp(true);

            selectedItem.namaNpwp = data.npwpHead.namaNpwp;
            selectedItem.noTelepon = data.npwpHead.noTelepon;
            selectedItem.email = data.npwpHead.email;
            selectedItem.alamat = data.npwpHead.alamat;

            selectedItem.kodepos = data.npwpHead.area.kodepos.toString();
            selectedItem.klu = data.npwpHead.klu ? data.npwpHead.klu.id : "";
            // listKlu
            //   .filter((dd) =>
            //     dd.nama == data.npwpHead.klu ? data.npwpHead.klu.nama : ""
            //   )
            //   .map((d) => (selectedItem.kluId = d.id));
            selectedItem.areaId = data.npwpHead.area.id;
            selectedItem.areaJenis = data.npwpHead.area.jenis;
            selectedItem.areaKabupaten = data.npwpHead.area.kabupaten;
            selectedItem.areaKecamatan = data.npwpHead.area.kecamatan;
            selectedItem.areaKelurahan = data.npwpHead.area.kelurahan;
            selectedItem.areaKodepos = data.npwpHead.area.kodepos.toString();
            selectedItem.areaPropinsi = data.npwpHead.area.propinsi;

            selectedItem.noEfin = "0000000000";
            selectedItem.pegawai = data.pegawai;
            selectedItem.pemilikUsaha = data.pemilikUsaha;
            selectedItem.pekerjaBebas = data.pekerjaBebas;

            //VALIDASI INPUT PKP
            selectedItem.pkp = data.npwpHead.pkp;
            if (selectedItem.pkp) {
              setDisabledTanggalPkp(true);
            } else {
              setDisabledTanggalPkp(false);
            }
            selectedItem.tanggalPkp = data.npwpHead.tanggalPkp;
            selectedItem.jumlahTanggungan = data.jumlahTanggungan.toString();
            selectedItem.npwpPasangan = data.npwpPasangan;
            selectedItem.pisahHarta = data.pisahHarta;
            selectedItem.statusNikah = data.statusNikah;
            selectedItem.statusNikahStr =
              data.statusNikah == STATUSPERNIKAHAN_BELUM_MENIKAH_INT
                ? "Belum Menikah"
                : data.statusNikahStr == STATUSPERNIKAHAN_CERAI_INT
                ? "Cerai"
                : STATUSPERNIKAHAN_MENIKAH_INT;
            selectedItem.tahun = data.tahun;
            setItem(selectedItem);
            setLoadingById(false);
          } catch (e) {
            setMessageError(`${e}`);
            setShowDialogError(true);
            onRequestClose();
            setLoadingById(false);
          }
        }
        setItem(selectedItem);
        // getDataKlu();
      } else {
        setItem(defaultData);
      }
      setReady(visible);
    }
    setLoading(true);
    initData();
    setLoading(false);
  }, [visible]);
  useEffect(() => {
    setLoadingAll(loading || loadingById);
  }, [loading, loadingById]);

  useEffect(() => {
    setLoadingKlu(true);
    const timeOutId = setTimeout(() => getDataKlu(queryKlu), 500);
    return () => clearTimeout(timeOutId);
  }, [queryKlu]);

  const getKodepos = async (kodepos) => {
    try {
      let res = await AreaService.getByKodePos(kodepos);

      let { data } = res;

      return data;
    } catch (e) {
      return e.message;
    }
  };
  const getDataKlu = async (queryNama) => {
    try {
      setLoadingKlu(true);
      let klu = await service.getKlu(queryNama);
      var klsAsset = [];
      klu.data.map((d) => {
        klsAsset.push({
          label: `${d.kode} - ${d.nama}`,
          value: d.id,
        });
      });
      setDataKlu(klsAsset);
      setListKlu(klu.data);
      setLoadingKlu(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingKlu(false);
    }
  };

  const onSubmit = async (data, callback) => {
    let reqData = dataSubmit;
    reqData.npwpHead.npwpOp = data.npwpOpInt == "0" ? true : false;
    reqData.npwpHead.namaNpwp = data.namaNpwp;
    reqData.npwpHead.npwp = data.memilikiNpwp ? data.npwp : "000000000000000";
    reqData.npwpHead.alamat = data.alamat;
    reqData.npwpHead.area.id = data.areaId;
    reqData.npwpHead.area.jenis = data.areaJenis;
    reqData.npwpHead.area.kabupaten = data.areaKabupaten;
    reqData.npwpHead.area.kecamatan = data.areaKecamatan;
    reqData.npwpHead.area.kelurahan = data.areaKelurahan;
    reqData.npwpHead.area.kodepos = data.areaKodepos;
    reqData.npwpHead.area.propinsi = data.areaPropinsi;
    reqData.npwpHead.email = data.email;
    reqData.npwpHead.pengurusanEfin = data.noEfin
      ? PENGURUSANEFIN_SUDAH_MEMILIKI_EFIN
      : PENGURUSANEFIN_EFIN_DIURUS_SENDIRI;
    reqData.npwpHead.noEfin = "0000000000";
    // reqData.npwpHead.klu = {
    //   id: item.kluId,
    // };
    reqData.npwpHead.klu.id = item.klu;
    reqData.npwpHead.noKtp = data.noKtp;
    reqData.npwpHead.noTelepon = data.noTelepon;
    reqData.npwpHead.noTeleponB = "";
    reqData.npwpHead.pkp = data.pkp;

    reqData.dataAwal.tanggalMulai = moment(new Date(data.tanggalMulai)).format(
      "yyyy-MM-DD hh:mm:ss"
    );
    reqData.dataAwal.kenaPphFinal = data.kenaPphFinal
      ? data.kenaPphFinal
      : false;
    reqData.dataAwal.omzetSudahDihitung = data.omzetSudahDihitung;
    if (data.tanggalPkp) {
      reqData.npwpHead.tanggalPkp = moment(new Date(data.tanggalPkp)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    reqData.jumlahTanggungan = data.jumlahTanggungan;
    reqData.npwpPasangan = data.npwpPasangan;
    reqData.pegawai = data.pegawai;
    reqData.pekerjaBebas = data.pekerjaBebas;
    reqData.pemilikUsaha = data.pemilikUsaha;
    reqData.pisahHarta = data.pisahHarta;
    reqData.statusNikah =
      data.statusNikahStr == "Belum Menikah"
        ? STATUSPERNIKAHAN_BELUM_MENIKAH_INT
        : data.statusNikahStr == "Cerai"
        ? STATUSPERNIKAHAN_CERAI_INT
        : STATUSPERNIKAHAN_MENIKAH_INT;
    reqData.tahun = moment(new Date(data.tanggalMulai)).year();
    if (reqData.id) {
      try {
        await service.update(reqData);
        callback("success", false);
        setItem(defaultData);
      } catch (err) {
        callback(err, true, false);
        setItem(defaultData);
      }
    } else {
      try {
        const res = await service.create(reqData);
        let { data } = res;
        callback("success", false);
      } catch (err) {
        callback(err, true, false);
      }
    }
  };

  return (
    ready && (
      <div>
        <DataForm
          baseId="akun-npwp-form"
          title={"Pemilik Outlet Form"}
          visible={visible}
          onRequestClose={onRequestClose}
          asDialog={true}
          defaultData={item}
          definitions={[
            {
              render: (
                <div>
                  <p style={{ marginLeft: "10px" }}>
                    <b>{t.translate(`words.opOrBadan`)}</b>
                  </p>
                  <br />
                </div>
              ),
            },
            {
              inputType: inputTypes.RADIO,
              label: t.translate(`words.op`),
              key: "npwpOpInt",
              name: "npwpOpInt",
              value: "0",
              style: {
                width: "100%",
              },
              disabled: isDisabled || !isOp || props.itemId,
            },
            {
              inputType: inputTypes.RADIO,
              label: t.translate(`words.badan`),
              key: "npwpOpInt",
              name: "npwpOpInt",
              width: "100%",
              value: "1",
              style: {
                width: "50%",
              },
              disabled: isDisabled || !isOp || props.itemId,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.identityNik`),
              key: "noKtp",
              type: "text",
              mask: inputValidasiNik,
              maskChar: "_",
              maskValue: "string",
              width: "100%",
              // validation: 'required',
              disabled: isDisabled || disabledNik || props.itemId,
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.memilikiNpwp`),
              key: "memilikiNpwp",
              name: "memilikiNpwp",
              value: isBadan,
              style: {
                width: "100%",
              },
              disabled: isDisabled || isBadan || props.itemId,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.identityNpwp`),
              key: "npwp",
              type: "text",
              mask: inputValidasiNpwp,
              maskChar: "_",
              maskValue: "string",
              width: "100%",
              show: isPunyaNpwp || isBadan,
              disabled: isDisabled || isEdit || props.itemId,
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate("words.name"),
              key: "namaNpwp",
              type: "text",
              validation: "required",
              disabled: isDisabled || isEdit || props.itemId,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate("words.phone"),
              key: "noTelepon",
              type: "text",
              isNumericString: true,
              validation: "required",
              disabled: isDisabled,
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate("words.email"),
              key: "email",
              type: "text",
              validation: "required",
              disabled: isDisabled || isEdit,
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate("words.address"),
              key: "alamat",
              type: "text",
              validation: "required",
              disabled: isDisabled || isEdit,
            },
            // {
            //   inputType: inputTypes.REACT_SELECT,
            //   label: t.translate(`words.kodepos`),
            //   key: 'kodepos',
            //   validation: 'required',
            //   labelKey: 'label',
            //   valueKey: 'label',
            //   data: kodepos,
            //   options: kodepos,
            //   async: true,
            //   defaultOptions: kodepos,
            //   loadOptions: async (inputValues)=> {
            // 	if(inputValues.length === 5){
            // 		var options = await getKodepos(inputValues)
            // 		return options
            // 	} else {
            // 		return kodepos
            // 	}
            //   }
            // },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.kodepos`),
              key: "kodepos",
              type: "text",
              mask: inputValidasiKodepos,
              maskChar: "_",
              maskValue: "string",
              width: "100%",
              validation: "required",
              disabled: isDisabled || isEdit,
            },
            // {
            //   inputType: inputTypes.INPUT,
            //   label: t.translate("words.noEfin"),
            //   key: "noEfin",
            //   type: "text",
            //   disabled: isDisabled || isEdit,
            // },
            // {
            //   inputType: DataForm.inputTypes.AUTOCOMPLETE,
            //   label: t.translate(`words.kodeKlasifikasiLapanganUsaha`),
            //   key: "klu",
            //   validation: "required",
            //   labelKey: "label",
            //   valueKey: "label",
            //   data: dataKlu,
            //   disabled: isDisabled || isEdit,
            //   style: { width: "90%" },
            // },
            {
              inputType: DataForm.inputTypes.REACT_SELECT,
              isClearable: true,
              label: t.translate(`words.kodeKlasifikasiLapanganUsaha`),
              key: "klu",
              validation: props.itemId && "required",
              labelKey: "label",
              valueKey: "value",
              options: dataKlu,
              isDisabled: isDisabled || isEdit,
              isLoading: loadingKlu,
              onInputChange: (value) => {
                if (value) setQueryKlu(value);
              },
              style: { width: "90%" },
            },
            {
              render: (
                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 35,
                  }}
                >
                  <Tooltip
                    placement="left"
                    overlay={
                      <span>
                        Jika Kode KLU tidak ditemukan, silahkan ketik nama KLU
                        yang ingin dicari pada kolom KLU
                      </span>
                    }
                  >
                    <FontIcon
                      iconClassName="mdi mdi-information-outline"
                      className="react-tooltip"
                      style={{ fontSize: 27 }}
                    />
                  </Tooltip>
                </div>
              ),
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.pemilikUsaha`),
              key: "pemilikUsaha",
              name: "pemilikUsaha",
              style: {
                width: "100%",
              },
              disabled: true,
              show: false,
              // show: editRole
            },
            {
              inputType: inputTypes.DATEPICKER,
              label: t.translate(`words.tanggalMulaiPencatatan`),
              key: "tanggalMulai",
              width: "100%",
              type: "date",
              validation: "required",
              show: props.itemId == null,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Omzet Diluar Aplikasi",
              key: "omzetSudahDihitung",
              type: "text",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              width: "100%",
              required: true,
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.terdaftarSebagaiPkp`),
              key: "pkp",
              name: "pkp",
              style: {
                width: "100%",
              },
              disabled: isDisabled,
              show: false,
            },
            {
              inputType: inputTypes.DATEPICKER,
              label: t.translate(`words.tanggalDitetapkanPkp`),
              key: "tanggalPkp",
              width: "100%",
              type: "date",
              // validation: 'required',
              show: disabledTanggalPkp,
              disabled: isDisabled,
              show: false,
            },
            {
              inputType: DataForm.inputTypes.SELECT,
              label: t.translate(`words.statusNikah`),
              key: "statusNikahStr",
              type: "text",
              validation: "required",
              options: ["Belum Menikah", "Menikah", "Cerai"],
              disabled: isDisabled,
              show: false,
            },
            {
              inputType: DataForm.inputTypes.SELECT,
              label: t.translate(`words.jumlahTanggungan`),
              key: "jumlahTanggungan",
              type: "text",
              options: ["0", "1", "2", "3"],
              disabled: isDisabled,
              show: false,
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.apakahPisahHarta`),
              key: "pisahHarta",
              name: "pisahHarta",
              style: {
                width: "100%",
              },
              show: isMenikah,
              disabled: isDisabled || props.itemId,
              show: false,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.npwpPasangan`),
              key: "npwpPasangan",
              type: "text",
              mask: inputValidasiNpwp,
              maskChar: "_",
              maskValue: "string",
              width: "100%",
              show: isMenikah && isPisahHarta,
              disabled: isDisabled || props.itemId,
              show: false,
            },
          ]}
          onChange={async (formData, key, value) => {
            if (key == "npwp") {
              if (value == "000000000000000") {
                alert("NPWP Tidak Boleh 000000000000000");
                formData.npwp = "";
              }
            }
            if (key === "npwpOpInt") {
              if (value === "0") {
                setIsBadan(false);
                setDisabledNik(false);
              } else {
                setIsBadan(true);
                setDisabledNik(true);
              }
            }
            //VALIDASI MEMILIKI NPWP
            if (key == "memilikiNpwp") {
              setIsPunyaNpwp(value);
            }

            //VALIDASI KODEPOS
            if (key == "kodepos") {
              if (value.length === 5) {
                var res = await getKodepos(value);
                if (typeof res === "object") {
                  formData.area = res.kodepos;
                  formData.areaId = res.id;
                  formData.areaJenis = res.jenis;
                  formData.areaKabupaten = res.kabupaten;
                  formData.areaKecamatan = res.kecamatan;
                  formData.areaKelurahan = res.kelurahan;
                  formData.areaKodepos = res.kodepos;
                  formData.areaPropinsi = res.propinsi;
                } else {
                  alert(res);
                  formData.area = null;
                  formData.areaId = null;
                  formData.areaJenis = null;
                  formData.areaKabupaten = null;
                  formData.areaKecamatan = null;
                  formData.areaKelurahan = null;
                  formData.areaKodepos = null;
                  formData.areaPropinsi = null;
                }
              }
            }
            if (key == "pkp") {
              if (value == "0") setDisabledTanggalPkp(false);
              else setDisabledTanggalPkp(true);
            }
            //VALIDASI MENIKAH
            if (key == "statusNikahStr") {
              if (value == "Menikah") setIsMenikah(true);
              else setIsMenikah(false);
            }
            //VALIDASI PISAH HARTA
            if (key == "pisahHarta") {
              setIsPisahHarta(value);
            }
            if (key == "klu") {
              dataKlu.map((d) => {
                if (d.label == value) {
                  formData.kluId = d.value;
                }
              });
            }
            setItem(formData);
            return formData;
          }}
          onSubmit={!isDisabled ? onSubmit : null}
        />
        <Dialog visible={showDialogError} style={{ width: "auto" }}>
          <DialogContent>
            {messageError}
            <br />
            <br />
            Tidak memiliki data di tahun{" "}
            {sessionStorage.getItem("tahunSobatBuku")}
          </DialogContent>
          <DialogFooter>
            <Button
              onClick={() => {
                setShowDialogError(false);
              }}
              theme="primary"
              themeType="contained"
            >
              {t.translate(`words.close`)}
            </Button>
          </DialogFooter>
        </Dialog>
        <Modal
          disableFocusOnMount={false}
          visible={loadingAll}
          children={<LoaderInfo children={"Loading . . . "} />}
        />
      </div>
    )
  );
};

export default AkunNpwpForm;
