import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import iziToast from "izitoast";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import stockAdjustmentService from "./StockAdjustmentService";
import StockAdjustmentDetail from "./StockAdjustmentDetail";
import { format } from "../../libs/react-mpk/services/number.service";

const StockAdjustment = ({
  className = "",
  showCommandbar = true,
  navigationStore,
  envStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [desc, setDesc] = useState("")

  const outletId = props.match.params.outletId
  const npwpHeadId = props.match.params.npwpHeadId

  return (
    <>
      <TableWrapper
        baseId="mod-table-sample"
        title={t.translate("modules.penyesuaianPersediaan.title")}
        className={className}
        hintMessage={t.translate("modules.penyesuaianPersediaan.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.penyesuaianPersediaan.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="DESC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        columns={[
          {
            label: t.translate("words.tanggalDibuat"),
            searchable: false,
            sortable: false,
            key: "tanggalDibuat",
            render: (item) => moment(item.tanggalDibuat).format("DD-MM-YYYY"),
          },
          {
            label: t.translate("words.keterangan"),
            searchable: true,
            sortable: false,
            key: "keterangan",
            render: (item) => (item.keterangan ? item.keterangan : "-"),
          },
          {
            label: t.translate("words.adjusmentTotal"),
            searchable: false,
            sortable: false,
            key: "jumlahAdjustment",
            render: (item) => item.jumlahAdjustment < 0 ? `(${format(item.jumlahAdjustment * -1)})` : format(item.jumlahAdjustment),
          },
          {
            label: t.translate("words.expenseIncome"),
            searchable: false,
            sortable: false,
            key: "totalBebanPendapatan",
            render: (item) => item.totalBebanPendapatan < 0 ? `(${format(item.totalBebanPendapatan * -1)})` : format(item.totalBebanPendapatan),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              navigationStore.redirectTo(
                `/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/adjustment`
              );
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Detail",
            "mdi mdi-redo",
            (item) => {
              setShowForm(true);
              setSelectedItem(item.id);
              setDesc(item.keterangan)
            },
            true
          ),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => console.log(item), true)
        ]}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "posOutletId.equals": sessionStorage.getItem("outletId"),
              };
              let page = await stockAdjustmentService.stockAdjustmentGetPage(
                query
              );
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];
              resolve(page);
            } catch (err) {
              // resolve(err);
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <StockAdjustmentDetail
        visible={showForm}
        onRequestClose={() => {
          setSelectedItem(null);
          setShowForm(false);
        }}
        item={selectedItem}
        desc={desc}
        {...props}
      />
    </>
  );
};

export default inject("navigationStore", "envStore")(observer(StockAdjustment));
