import { crud, http } from "../../libs/react-mpk/services";

let productService = new crud("/outlet_produk");
let serviceUrl = "/outlet_produk";
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let posOutletId = sessionStorage.getItem("outletId");

productService.createProduct = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

productService.updateProduct = async (data, id) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

productService.getProductById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

productService.updateProduct = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

productService.deleteProduct = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

productService.getProductPage = async (query) => {
  // console.log(query, "query2")
  query = {
    ...query,
  };
  // console.log(query, "query3")

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

productService.uploadImage = async (file) => {
  let data = {
    file: file,
    npwpHeadId: npwpHeadId,
  };

  return http.request({
    method: http.methods.POST,
    url: `/produk/gambar/upload`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};

//rak produk
productService.getRakList = async(posOutletId) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kodeRak.contains": "",
    "namaRak.contains:": "",
    "posOutletId.equals": posOutletId,
     npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/produk/manajemen_rak`,
    query,
  });
};

// kategori produk

productService.getCategoryList = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": "",
    "posOutletId.equals": posOutletId,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/produk/kategori`,
    query,
  });
};

// unit product
productService.getUnitList = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": "",
    "posOutletId.equals": posOutletId,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/produk/unit`,
    query,
  });
};

// bahan baku product
productService.getBahanBakuList = async() => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": "",
    "posOutletId.equals": posOutletId,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/outlet_produk/bahan_baku`,
    query,
  });
}

// productService.createUnit = async () => {
//   return http.request({
//     method: http.methods.POST,
//     url: `/produk/unit`,
//   });
// };

export default productService;
