import React, { useState, useEffect } from "react";
import PageWrapper from "../libs/react-mpk/wrapper/PageWrapper";
import { Route, Router, Switch } from "react-router-dom";
import { sso } from "../libs/react-mpk/hocs";
import OutletDetail from "../modules/Outlet/OutletDetail";
import Employee from "../modules/Employee/Employee";
import Product from "../modules/Product/Product";
import StockAdjustment from "../modules/StockAdjustment/StockAdjustment";
import StockAdjustmentForm from "../modules/StockAdjustment/StockAdjustmentForm";
import Supplier from "../modules/Supplier/Supplier";
import Purchase from "../modules/Purchase/Purchase";
import PurchaseForm from "../modules/Purchase/PurchaseForm";
import Customer from "../modules/Customer/Customer";
import Report from "../modules/Report/Report";
import Category from "../modules/Category/Category";
import Unit from "../modules/Unit/Unit";
import StockAdjustmentDetail from "../modules/StockAdjustment/StockAdjustmentDetail";
import { FontIcon, List, ListItem } from "react-md";
import outletService from "../modules/Outlet/Outlet.service";
import PurchaseDetail from "../modules/Purchase/PurchaseDetail";
import LaporanMutasi from "../modules/LaporanMutasi/LaporanMutasi";
import ManajemenRak from "../modules/ManajemenRak/ManajemenRak";
import BahanBaku from "../modules/BahanBaku/BahanBaku";
import Penjualan from "../modules/Penjualan/Penjualan";
import PenjualanForm from "../modules/Penjualan/PenjualanForm";
import PenjualanDetail from "../modules/Penjualan/PenjualanDetail";

const MainMenu = ({ history, match, navigationStore, ...props }) => {
  var outletId = match.params.outletId;
  var npwpHeadId = match.params.npwpHeadId;
  // var outletName = sessionStorage.getItem("outletName");

  const [outlet, setOutlet] = useState({});
  const [outletName, setOutletName] = useState("");

  const initData = async () => {
    try {
      let res = await outletService.getOutletById(outletId);

      sessionStorage.setItem("outletId", outletId);

      let { data } = res;

      setOutlet(data);
      setOutletName(data.nama);
    } catch (err) {}
  };

  useEffect(() => {
    // sessionStorage.setItem('outletId', match.params.outletId);
    // sessionStorage.setItem('outletName', match.params.outletName);
    initData();
    let outletCode = sessionStorage.getItem("outletCode");
    if (outletCode == "null") {
      // console.log(outletCode);
      navigationStore.redirectTo(
        `/internal/application/${npwpHeadId}/outlet/${outletId}/outlet`
      );
    }
  }, []);

  var sidebarMenuActive = [
    {
      label: "",
    },
    {
      label: "Menu Utama",
      children: [
        {
          label: "Karyawan",
          index: "karyawan",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/employee`,
          childPath: [],
        },
        {
          label: `Produk`,
          index: `Produk`,
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/product`,
          childPath: [],
        },
        {
          label: `Penyesuaian Persediaan`,
          index: `Penyesuaian, Persediaan`,
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/stock`,
          childPath: [],
        },
        {
          label: "Pembelian",
          index: "pembelian",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/pembelian`,
          childPath: [],
        },
        {
          label: "Penjualan",
          index: "Penjualan",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/penjualan`,
          childPath: [],
        },
        {
          label: `Kategori`,
          index: `kategori`,
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/category`,
          childPath: [],
        },
        {
          label: `Unit`,
          index: `unit`,
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/unit`,
          childPath: [],
        },
        {
          label: `Manajemen Rak`,
          index: `manajemenRak`,
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/manajemen-rak`,
          childPath: [],
        },
        {
          label: `Bahan Baku`,
          index: `bahanBaku`,
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/bahan-baku`,
          childPath: [],
        },
        {
          label: "Customer",
          index: "customer",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/customer`,
          childPath: [],
        },
        {
          label: `Supplier`,
          index: `supplier`,
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/supplier`,
          childPath: [],
        },
        {
          label: "Outlet",
          index: "outlet",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/outlet`,
          childPath: [],
        },
        {
          label: `Laporan`,
          index: `laporan`,
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/report`,
          childPath: [],
        },
        {
          label: "Laporan Mutasi Persediaan",
          index: "laporanMutasi",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}/outlet/${outletId}/mutasi`,
        },
      ],
    },
  ];

  return (
    <>
      <PageWrapper
        inverseTheme={true}
        // style={{ background: "#D14C21" }}
        sidebarCollapsible={true}
        sidebarDefaultCollapse={true}
        sidebarHeader={
          <>
            <List
              style={{
                width: "100%",
                background: "white",
                padding: "0px",
                height: "65px",
              }}
            >
              <ListItem
                style={{ marginTop: "-3px" }}
                secondaryText={`Outlet: ${outletName}`}
                rightAddon={
                  <FontIcon
                    onClick={() => {
                      window.location.href = `/internal/application/${npwpHeadId}`;
                      //history.push(`/product/company/${companyId}/daftar-spt`)
                    }}
                    iconClassName="mdi mdi-close"
                  ></FontIcon>
                }
              >
                {/* {`${spt.year} - ${spt.month}`} */}
              </ListItem>
            </List>
            {/* <DataForm
              baseId={`tahun`}
              defaultData={}
              onSubmit={null}
              definitions={[
                {
                  inputType: inputTypes.SELECT,
                  label: t.translate(`words.tahun`),
                  key: 'tahun',
                  type: 'text',
                  validation: 'required',
                  options: optionTahun,
                  // disabled: disabledKapKJS
                }
              ]}
              onChange={async (formData, key, value) => {
                sessionStorage.setItem('tahunSobatBuku', value);
                window.location.reload();
                // console.log(tahunSobatBuku)
              }}
            /> */}
          </>
        }
        sidebarMenu={sidebarMenuActive}
      >
        <Router history={history}>
          <Switch>
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/outlet"
              render={(props) => <OutletDetail {...props} />}
            />

            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/employee"
              render={(props) => <Employee showAll={false} {...props} />}
            />

            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/manajemen-rak"
              render={(props) => <ManajemenRak {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/bahan-baku"
              render={(props) => <BahanBaku {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/product"
              render={(props) => <Product {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/report"
              render={(props) => <Report {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/stock"
              render={(props) => <StockAdjustment {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/adjustment"
              render={(props) => <StockAdjustmentForm {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/adjustment/:id"
              render={(props) => <StockAdjustmentDetail {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/supplier"
              render={(props) => <Supplier {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/customer"
              render={(props) => <Customer {...props} />}
            />

            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/pembelian/:id/detail"
              render={(props) => <PurchaseDetail {...props} />}
            />

            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/pembelian/:id"
              render={(props) => <PurchaseForm {...props} />}
            />

            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/pembelian"
              render={(props) => <Purchase {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/penjualan/:id/detail"
              render={(props) => <PenjualanDetail {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/penjualan/:id"
              render={(props) => <PenjualanForm {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/penjualan"
              render={(props) => <Penjualan {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/category"
              render={(props) => <Category {...props} />}
            />
            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/unit"
              render={(props) => <Unit {...props} />}
            />

            <Route
              path="/internal/application/:npwpHeadId/outlet/:outletId/mutasi"
              render={(props) => <LaporanMutasi {...props} />}
            />
          </Switch>
        </Router>
      </PageWrapper>
    </>
  );
};

export default sso({
  basePath: "/internal/application/:outletId",
})(MainMenu);
