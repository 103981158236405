import React, { useEffect, useState } from "react";
import { DataForm, Hint } from "../../libs/react-mpk/components";
import { cloneDeep, set } from "lodash";
import t from "counterpart";
import { Text } from "react-md";
import {
  defaultQueryParameter,
  sorts,
} from "../../libs/react-mpk/config/constant";
import employeeService from "../Employee/Employee.service";
import { http } from "../../libs/react-mpk/services";
import productService from "../Product/Product.service";
import purchaseService from "../Purchase/Purchase.service";

const defaultData = () => ({
  produk: "",
  availableStock: "",
  physicalStock: "",
  adjustment: "",
  productId: 0,
});

const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const AddNewProduct = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  ...props
}) => {
  let [item, setItem] = useState(defaultData());
  let [id, setId] = useState(null);
  let [ready, setReady] = useState(true);
  let [disabled, setDisabled] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [query, setQuery] = useState(
    defaultQuery({
      page: 0,
      size: 9999,
      sort: "tanggalDibuat,DESC",
    })
  );

  let [postData, setPostData] = useState([]);
  const [products, setProduct] = useState([]);
  const [view, setView] = useState(false);
  const [viewPhy, setViewPhy] = useState(false);

  console.log(props);

  let outletId = props.match.params.outletId;

  const initData = () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        // for (let i =0; i < props.item.length; i++){
        //   console.log(props.item[i])
        // }
        // console.log(props.item)

        setPostData(props.item);
      }
      setItem(selectedItem);

      if (props.product) {
        setProduct(props.product);
      }

      // if(props.isBahanBaku != null || props.isBahanBaku != undefined){
      //   getProduct(query)
      // }
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
    setDisabled(true);
    let temp = [...postData];
    temp.push(item);
    sessionStorage.setItem("stockAdjustmentTemporary", JSON.stringify(temp));
    callback("success", false);
    setDisabled(false);
    setView(false);
    setViewPhy(false);
    onSubmitRequest();
    // window.location.reload(false);
  };

  const closeForm = () => {
    setView(false);
    setViewPhy(false);
    onRequestClose();
  };

  return (
    ready && (
      <DataForm
        baseId="inventory-form"
        title={"Pilih Produk"}
        visible={visible}
        // data={item}
        onRequestClose={() => closeForm()}
        asDialog={true}
        defaultData={item}
        hintMessage={t.translate("words.addAdjustmentHint")}
        definitions={[
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("words.produk"),
            key: "produk",
            // validation: "required",
            labelKey: "label",
            valueKey: "label",
            data: products,
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.availableStock"),
            type: "number",
            key: "availableStock",
            disabled: true,
            // required: true
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.physicalStock"),
            type: "number",
            key: "physicalStock",
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.adjustment"),
            type: "number",
            key: "adjustment",
            disabled: true,
            // required: true
          },
          // {
          //   inputType: DataForm.inputTypes.LIST,
          //   label: t.translate("words.produk"),
          //   key: "product",
          //   defaultData: {
          //     produk: "",
          //     availableStock: "",
          //     physicalStock: "",
          //     adjustment: "",
          //   },
          //   definitions: [

          //     {
          //       inputType: DataForm.inputTypes.PRE_DATA,
          //     },
          //   ],
          // },
        ]}
        onChange={(data, key, value) => {
          if (key == "produk") {
            for (var i = 0; i < products.length; i++) {
              if (products[i].isTrackingPersediaan) {
                if (products[i].label == value) {
                  data.productId = products[i].value;
                  data.availableStock = products[i].stock;
                  setView(true);
                  i = products.length;
                } else {
                  data.availableStock = 0;
                }
              } else {
                alert("this item does not tracking stock");
              }
            }
            // products.map((d) => {
            //   if (d.isTrackingPersediaan) {
            //     if (d.label == value) {
            //       console.log(value, "true");
            //       data.productId = d.value;
            //       data.availableStock = d.stock;
            //       setView(true);
            //     } else {
            //       console.log(value, "false");
            //       data.availableStock = 0;
            //     }
            //   } else {
            //     alert("this item does not tracking stock");
            //   }
            // });
          }

          if (key === "physicalStock") {
            if (value <= "0") {
              alert("Stock tidak boleh atau dibawah 0");
              data.physicalStock = "";
            } else {
              data.adjustment = data.physicalStock - data.availableStock;
            }
            // if(data.adjustment < 0){
            //   data.adjustment *= -1
            // }
            setViewPhy(true);
          }
          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default AddNewProduct;
