import { crud } from '../../libs/react-mpk/services';
import http from '../../libs/react-mpk/services/http.service'

let outletOwnerService = new crud('/pemilik_outlet');
let serviceUrl = '/pemilik_outlet'
let currYear = new Date().getFullYear()

outletOwnerService.createOutletOwner = async (data) => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}`,
        data
    })
}

outletOwnerService.checkOutletOwnerExist = async () => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/cek_pemilik_outlet_exist/${currYear}`
      })
}

outletOwnerService.getNpwpHead = async () => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/get_one_by_id_iam/${currYear}`
      })
}

outletOwnerService.generateCoa = async (data, npwpHeadId) => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}/generate_akun`,
        data,
        config: {
            params: {
                npwpHeadId: npwpHeadId,
                tahun: currYear
            }
        }
    })
}

outletOwnerService.migration = async() => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}/migrasi`,
        data: {},
    })
}


export default outletOwnerService;