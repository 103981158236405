import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import { inject, observer } from "mobx-react";
import penjualanService from "./Penjualan.service";
import { format } from "../../libs/react-mpk/services/number.service";
import { TextField } from "react-md";
import { defaultQueryParameter } from "../../libs/react-mpk/config/constant";
import iziToast from "izitoast";

const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const Penjualan = ({
  className = "",
  showCommandbar = true,
  navigationStore,
  modalStore,
  envStore,
  ...props
}) => {
  const outletId = props.match.params.outletId;
  const npwpHeadId = props.match.params.npwpHeadId;
  const baseId = "penjualan-table";
  const date = new Date();
  const [query2, setQuery2] = useState(
    defaultQuery({
      tanggalPenjualanAwal: "",
      tanggalPenjualanAkhir: "",
    })
  );
  const BatalPembelian = async (item) => {
    modalStore.showConfirm({
      title: t.translate("words.batalkan"),
      children: t.translate("words.confirmBatalkan"),
      onSubmit: (callback) => {
        CancelItem(item);
        callback();
      },
    });
  };

  const CancelItem = async (item) => {
    try {
      let res = await penjualanService.TransactionCancel(item.id);

      let { data, status } = res;

      if (status == 200) {
        iziToast.success({
          message: 'berhasil',
        });

        TableWrapper.updateDataItem("penjualan-table", { id: data.id }, data);
        TableWrapper.reload("penjualan-table");
      } else {
        iziToast.info({
          message: 'berhasil',
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };
  const changeDate = (e) => {
    setQuery2({ ...query2, [e.target.name]: e.target.value });
  };
  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate("modules.penjualan.title")}
        className={className}
        hintMessage={t.translate("modules.penjualan.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.penjualan.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        showFilter={false}
        extraFilter={[
          <TextField
            id="startDatePenjualan"
            label="Tanggal Penjualan Awal"
            type="date"
            name="tanggalPenjualanAwal"
            key="tanggalPenjualanAwal"
            value={query2.tanggalPenjualanAwal}
            onChange={(e) => changeDate(e)}
            max={moment(date).format("YYYY-MM-DD")}
            style={{ marginBottom: "10%" }}
          />,
          <TextField
            id="endDatePenjualan"
            label="Tanggal Penjualan Akhir"
            type="date"
            name="tanggalPenjualanAkhir"
            key="tanggalPenjualanAkhir"
            value={query2.tanggalPenjualanAkhir}
            onChange={(e) => changeDate(e)}
            min={query2.tanggalPenjualanAwal}
            max={new Date()}
          />
        
  ]}
        columns={[
          {
            label: "No Penjualan",
            searchable: true,
            key: "noTransaksi",
            render: (item) => item.noTransaksi,
          },
          {
            label: "Metode Pembayaran",
            searchable: false,
            key: "metodePembayaran",
            render: (item) =>
              item.jenisTransaksi == 1 ? "Bayar Nanti" : "Tunai",
          },
          {
            label: "Status",
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.sbkFk.pembayaran.status < 0 ||
                  item.sbkFk.pembayaran.status == 4
                    ? TableWrapper.Status.types.ERROR
                    : item.sbkFk.pembayaran.status == 1
                    ? TableWrapper.Status.types.IDLE
                    : item.sbkFk.pembayaran.status == 2
                    ? TableWrapper.Status.types.PROGRESS
                    : TableWrapper.Status.types.SUCCESS
                }
              >
                {item.sbkFk.pembayaran.status < 0
                  ? "Terlambat bayar"
                  : item.sbkFk.pembayaran.status == 1
                  ? "Belum Dibayar"
                  : item.sbkFk.pembayaran.status == 2
                  ? " Dibayar Sebagian"
                  : item.sbkFk.pembayaran.status == 4
                  ? "Batal"
                  : "Lunas"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Nominal",
            searchable: false,
            key: "total",
            render: (item) => "Rp. " + format(item.jenisTransaksi == 1?item.nominalTagihan:item.nominalDiterima),
          },
          {
            label: "Nama Karyawan",
            searchable: true,
            key: "posKaryawanNama",
            render: (item) => item.posKaryawan.nama,
          },
          {
            label: "Tanggal",
            searchable: false,
            sortable: true,
            key: "tanggalDibuat",
            definition: {
              criterias: ["equals", "greaterThan", "lessThan"],
              inputType: TableWrapper.inputTypes.DATE,
            },
            render: (item) => moment(item.tanggalDibuat).format("DD MMMM YYYY"),
          },
          {
            label: "Waktu",
            searchable: false,
            sortable: false,
            key: "waktu",
            render: (item) => moment(item.tanggalDibuat).format("HH:mm"),
          },
        ]}
        // actions={[
        //   new TableWrapper.action(
        //     "Add",
        //     "mdi mdi-plus",
        //     () => {
        //       navigationStore.redirectTo(
        //         `/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/penjualan/new`
        //       );
        //       //   setSelectedItem(null);
        //       //   setShowForm(true);
        //     },
        //     true
        //   ),
        // ]}
        itemActions={[
          new TableWrapper.action(
            "Detail",
            "mdi mdi-magnify",
            (item) => {
              navigationStore.redirectTo(
                `/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/penjualan/${item.id}/detail`
              );
            },
            true
          ),
          new TableWrapper.action(
            "Batalkan",
            "mdi mdi-delete",
            (item) => BatalPembelian(item),
            (item) =>
              item.sbkFk && item.sbkFk.pembayaran.status !== 4 ? true : false
          ),
        ]}
        onFetchData={async (query) => {
          // if (query["tanggalDibuat.lessThan"]){
          //   query["tanggalDibuat.lessThan"] = moment(new Date(query["tanggalDibuat.lessThan"])).format('YYYY-MM-DD HH:mm:ss')
          // }

          for (let tgl of Object.keys(query)) {
            if (
              tgl.includes("tanggalDibuat") ||
              tgl.includes("tanggalPenjualan")
            ) {
              query[tgl] = new Date(
                moment(new Date(query[tgl])).format("YYYY-MM-DD HH:mm:ss")
              );
            }
          }
          query = {
            ...query,
          };
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "posOutletId.equals": outletId,
              };
              let page = await penjualanService.penjualanGetPage(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];
              resolve(page);
            } catch (err) {}
          });
        }}
        showCommandbar={showCommandbar}
      />
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(Penjualan));
