import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { defaultQueryParameter } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import PenjualanService from "./Penjualan.service";
import { format } from "../../libs/react-mpk/services/number.service";
import { inject, observer } from "mobx-react";

const defaultData = () => ({
  total: "",
});

const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const ModalLunasForm = ({
  envStore,
  navigationStore,
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  ...props
}) => {
  const outletId = props.match.params.outletId;

  const npwpHeadId = props.match.params.npwpHeadId;

  const id = props.match.params.id;

  const { inputTypes } = FormWrapper;
  let [item, setItem] = useState(defaultData());
  let [ready, setReady] = useState(true);
  const [total, setTotal] = useState(0);
  const [dataSubmit, setDataSubmit] = useState({
    nilaiTagihan: 0,
    tanggalTransaksi: null,
    outletPenjualan: {
      id: 0,
    },
    npwpHead: {
      id: 0,
    },
  });
  const initData = async () => {
    if (visible) {
      if (props.totalProduk) {
        setTotal(props.totalProduk);
      }
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
   
    let tempData = dataSubmit;
    tempData.nilaiTagihan = item.nilaiTagihan;
    tempData.tanggalTransaksi = item.tanggalTransaksi;
    tempData.outletPenjualan.id = id;
    tempData.npwpHead.id = npwpHeadId;
    try {
      let res = await PenjualanService.historyPayment(tempData);
      let { status } = res;
      if (status === 200) {
        navigationStore.redirectTo(
          `/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/penjualan`
        );
      }
      callback("success", false);
    } catch (err) {
      callback(err, true, false);
      console.log(err);
    }
  };

  const closeForm = () => {
    onRequestClose();
  };

  return (
    ready && (
      <DataForm
        baseId="inventory-form"
        title={"Pembayaran"}
        visible={visible}
        data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Tunai",
            key: "nilaiTagihan",
            required: true,
            options: [
              {
                value: total,
                label: `Rp ${format(total)} (Uang pas)`,
              },
              {
                value: Math.ceil(total / 10000) * 10000,
                label: `Rp ${format(Math.ceil(total / 10000) * 10000)}`,
              },
              {
                value: Math.ceil(total / 50000) * 50000,
                label: `Rp ${format(Math.ceil(total / 50000) * 50000)}`,
              },
              {
                value: Math.ceil(total / 100000) * 100000,
                label: `Rp ${format(Math.ceil(total / 100000) * 100000)}`,
              },
            ],
          },
          {
            inputType: DataForm.inputTypes.DATEPICKER,
            label: "Tanggal Pembayaran",
            key: "tanggalTransaksi",
            required: true,
          },
        ]}
        onChange={(data, key, value) => {
          return data;
        }}
        submitLabel={'Bayar'}
        onSubmit={submitData}
      />
    )
  );
};

export default inject("envStore", "navigationStore")(observer(ModalLunasForm));
