import { crud, http } from "../../libs/react-mpk/services";

let service = new crud("/report");
let serviceUrl = "/report";
let npwpHeadId = sessionStorage.getItem('npwpHeadId')
let outletId = sessionStorage.getItem("outletId");

service.getAllPage = async(query) => {
    query = {
        ...query,
        npwpHeadId: npwpHeadId,
        outletId: outletId
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/mutasi_persediaan`,
        query
    });
};


export default service;
