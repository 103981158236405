import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import { AreaService } from "../Area/AreaService";
import outletOwnerService from "./PemilikOutlet.service";

const defaultData = () => ({
  npwpHead: {
    area: {},
  },
  npwpOpInt: null,
  noKtp: "",
  namaNpwp: "",
  noTelepon: "",
  alamat: "",
  postalCode: "",
  havingNpwp: null,
  npwp: "",
  email: "",
  noEfin: "",
});

const businessOwnerList = [
  { id: "op", name: "op" },
  { id: "badan", name: "badan" },
];

const yesNo = [
  { id: "ya", name: "yes" },
  { id: "tidak", name: "no" },
];

const OutletOwnerForm = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [id, setId] = useState(null);
  let [ready, setReady] = useState(true);
  let [isDisabled, setIsDisabled] = useState(false);
  let [havingNpwp, setHavingNpwp] = useState(false);
  let [isOp, setIsOp] = useState(false);

  let [postData, setPostData] = useState({
    npwpHead: {
      npwpOp: true,
      namaNpwp: "Wahyu Kusnadi",
      npwp: "000000000000000",
      alamat: "Jalan Kemanggisan Utama Raya",
      area: {
        id: 1,
      },
      email: "wahyu@gmail.com",
      pengurusanEfin: 1,
      noEfin: "1234567890",
      noKtp: "1234567890123663",
      noTelepon: "0819263129631",
      noTeleponB: "",
      pkp: true,
      tanggalPkp: "2021-08-14 15:55:00",
    },
    jumlahTanggungan: 0,
    npwpPasangan: "",
    pegawai: false,
    pekerjaBebas: false,
    pemilikUsaha: true,
    pisahHarta: true,
    statusNikah: 2,
    tahun: 2021,
  });

  useEffect(() => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        // setId(props.item.id)
        setIsDisabled(true);
        selectedItem = cloneDeep(props.item);
        selectedItem.number = String(selectedItem.number);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
    setIsDisabled(false);
  }, [visible]);

  const getPostalCode = async (postalCode) => {
    try {
      let res = await AreaService.getByKodePos(postalCode);
      let { data } = res;
      return data;
    } catch (e) {
      return e.message;
    }
  };

  const submitData = async (item, callback) => {
    let postItem = postData;

    setIsDisabled(true);

    postItem.npwpHead.npwpOp = item.npwpOpInt === "op" ? true : false;
    postItem.npwpHead.namaNpwp = item.namaNpwp;
    postItem.npwpHead.npwp =
      item.memilikiNpwp === "ya" ? item.npwp : "000000000000000";
    postItem.npwpHead.alamat = item.alamat;
    postItem.npwpHead.email = item.email;
    postItem.npwpHead.pengurusanEfin = 1;
    postItem.npwpHead.noEfin = '0000000000';
    postItem.npwpHead.noTelepon = item.noTelepon;
    postItem.npwpHead.noKtp = item.noKtp ? item.noKtp : "";
    postItem.npwpHead.area.id = item.npwpHead.area.id;
    postItem.npwpHead.pkp = false;
    postItem.npwpHead.tanggalPkp = null;
    postItem.jumlahTanggungan = 0;
    postItem.npwpPasangan = "";
    postItem.pegawai = false;
    postItem.pekerjaBebas = false;
    postItem.pemilikUsaha = true;
    postItem.pisahHarta = true;
    postItem.statusNikah = 2;
    postItem.tahun = new Date().getFullYear();

    if (!postItem.npwpHead.npwpOp) {
      if (
        postItem.npwpHead.npwp === "000000000000000" ||
        postItem.npwpHead.npwp === null
      ) {
        callback(`${t.translate("words.npwpNull")}`, true, false);
      }
    }

    if (postItem.npwpHead.noKtp) {
      if (postItem.npwpHead.noKtp.length != 16) {
        callback(`${t.translate("words.nikLength")}`, true, false);
      }
    }
    // console.log(postItem)

    try {
      const res = await outletOwnerService.createOutletOwner(postItem);
      let { data, status } = res;
      localStorage.setItem('npwpHeadId', data.npwpHead.id)
      
      // console.log(data)
      if (status == 200){
        sessionStorage.setItem("outletOwner", JSON.stringify(postItem));

        let ress = await outletOwnerService.generateCoa({}, data.npwpHead.id)
        
        setIsDisabled(false)
      }
      // console.log(res, 'res')
      callback("success", false);
      onSubmitRequest()
    } catch (err) {
      // console.log(err, 'err')
      setIsDisabled(false)
      callback(err, true, false);
    }

  };

  return (
    ready && (
      <DataForm
        baseId="outlet-form"
        title={t.translate("modules.outlet.setOwner")}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("words.opOrBadan"),
            key: "npwpOpInt",
            theme: "filled",
            options: businessOwnerList.map((d) => ({
              value: d.id,
              children: t.translate(`words.${d.name}`),
            })),
            labelKey: "children",
            valueKey: "value",
            disabled: isDisabled,
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("words.memilikiNpwp"),
            key: "memilikiNpwp",
            theme: "filled",
            options: yesNo.map((d) => ({
              value: d.id,
              children: t.translate(`words.${d.name}`),
            })),
            labelKey: "children",
            valueKey: "value",
            disabled: isDisabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.identityNik"),
            key: "noKtp",
            minLength: 16,
            maxLength: 16,
            required: true,

            disabled: isDisabled || !isOp,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.npwp"),
            key: "npwp",
            minLength: 15,
            maxLength: 15,
            required: true,
            disabled: isDisabled || !havingNpwp,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: `Nama`,
            key: "namaNpwp",
            required: true,
            disabled: isDisabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.email"),
            key: "email",
            type: "email",
            validation: "email",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.phone"),
            key: "noTelepon",
            type: "number",
            required: true,
          },
          // {
          //   inputType: DataForm.inputTypes.INPUT,
          //   label: t.translate("words.noEfin"),
          //   key: "noEfin",
          //   type: "number",
          //   required: true,
          // },
          {
            inputType: DataForm.inputTypes.TEXTAREA,
            label: t.translate("words.address"),
            key: "alamat",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.postalCode"),
            key: "postalCode",
            type: "number",
            required: true,
          },
        ]}
        onChange={(data, key, value) => {
          if (key === "npwp") {
            data.npwp = String(value).replace(/\D/g, "");
          }
          if ( key === "noKtp"){
            data.ktp = String(value).replace(/\D/g, "");
          }

          if (key === "npwpOpInt") {
            if (value === "badan") {
              setIsOp(false);
            } else {
              setIsOp(true);
            }
          }

          if (key === "memilikiNpwp") {
            if (value === "tidak") {
              setHavingNpwp(false);
            } else {
              setHavingNpwp(true);
            }
          }

          if (key === "postalCode") {
            if (value.length === 5) {
              let temp = getPostalCode(value);
              temp
                .then((res) => {
                  if (typeof res === "object") {
                    data.npwpHead.area.id = res.id;
                  } else {
                    alert(res);
                  }
                })
                .catch((err) => {
                  alert(err);
                });
            }
          }

          return data;
        }}
        watchDefaultData={false}
        onSubmit={!isDisabled ? submitData : null}
      />
    )
  );
};

export default OutletOwnerForm;
