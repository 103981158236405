import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find, cloneDeep } from "lodash";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import Hint from "../../libs/react-mpk/components/Hint/Hint";
import {
  Button,
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
} from "react-md";
import {
  DataForm,
  LoaderInfo,
  Pagination,
} from "../../libs/react-mpk/components";
import {
  defaultQueryParameter,
  sorts,
} from "../../libs/react-mpk/config/constant";
import { format } from "../../libs/react-mpk/services/number.service";
import purchaseService from "./Purchase.service";
import { toast } from "../../libs/react-mpk/services";

const defaultData = () => ({
  keterangan: "",
});
const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const PurchaseDetail = ({ envStore, navigationStore, ...props }) => {
  const { inputTypes } = FormWrapper;
  const outletId = props.match.params.outletId;
  const npwpHeadId = props.match.params.npwpHeadId;
  const pembelianId = sessionStorage.getItem("pembelianId");
  const status = [
    { id: 1, name: "Simpan" },
    { id: 2, name: "Simpan dan Terima" },
  ];
  const [item, setItem] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [supplier, setSupplier] = useState([]);
  const [purchaseProduct, setPurchaseProduct] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    if (props.match.params.id) {
      try {
        setLoading(true)
        let selectedItem = defaultData();
        let res = await purchaseService.purchaseGetDetail(
          props.match.params.id
        );
        let { data } = res;

        selectedItem.id = data.posPembelian.id;
        selectedItem.nama = data.posPembelian.lawanTransaksi.nama;
        selectedItem.alamat = data.posPembelian.lawanTransaksi.alamat;
        if (data.posPembelian.status == 1) {
          selectedItem.status = "Belum Diterima";
        } else {
          selectedItem.status = "Sudah Diterima";
        }
        selectedItem.noPo = data.posPembelian.nomorPo;
        setPurchaseProduct(data.listPosPembelianProduk);
        setTotalPrice(format(data.posPembelian.total));
        setItem(selectedItem);
        setLoading(false)
      } catch (error) {
        toast.errorRequest(error)
        setLoading(false)
      }
    }
  };

  return (
    <>
      <FormWrapper
        loading={loading}
        backTo={`/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/pembelian`}
        baseId="purchase-form"
        title="Detail Pembelian"
        hintShowIcon={false}
        defaultData={item}
        definitions={[
          {
            id: "po",
            inputType: inputTypes.INPUT,
            label: "Nomor PO",
            key: "noPo",
            disabled: true,
          },
          {
            id: "nama",
            inputType: inputTypes.INPUT,
            label: "Nama",
            key: "nama",
            disabled: true,
          },
          {
            id: "alamat",
            inputType: inputTypes.INPUT,
            label: "Alamat",
            key: "alamat",
            disabled: true,
          },
          {
            id: "status",
            inputType: inputTypes.INPUT,
            label: "Status",
            key: "status",
            options: status.map((d) => ({
              value: d.id,
              children: d.name,
            })),
            labelKey: "children",
            disabled: true,
            require: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                {/* <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message={t.translate('words.')}
                /> */}

                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCell>{t.translate("words.non")}</TableCell>
                      <TableCell>{t.translate("words.namaProduk")}</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Harga Satuan</TableCell>
                      <TableCell>{t.translate("words.total")}</TableCell>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {!loading ? (
                      purchaseProduct.map((d, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>
                              {d.posOutletProduk.sbkProduk.nama}
                            </TableCell>
                            <TableCell>{d.qty}</TableCell>
                            <TableCell>Rp. {format(d.hargaSatuan)}</TableCell>
                            <TableCell>Rp. {format(d.total)}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <div style={{ marginLeft: "120%" }}>
                        <LoaderInfo />
                      </div>
                    )}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TableCell>Total:</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Rp. {totalPrice}
                        {/* {format({ totalPrice })} */}
                      </TableCell>
                    </TableRow>
                    {/* <Pagination
                      baseId={"adjusment-form-pagination"}
                      className="flex"
                      page={query.page}
                      size={query.size}
                      totalData={totalData}
                      onChange={(newQuery) => setNewQuery(newQuery)}
                    /> */}
                  </TableFooter>
                </Table>
              </div>
            ),
          },
          // {
          //   render: (
          //     <div style={{ width: "100%" }}>
          //       {/* <Hint
          //         className="mpk-margin-N margin-top margin-bottom"
          //         message={t.translate('words.')}
          //       /> */}
          //       <div style={{ display: "flex" }}>
          //         <Button
          //           theme="primary"
          //           themeType="contained"
          //           style={{ margin: 15 }}
          //           onClick={() => purchaseType()}
          //         >
          //           <FontIcon iconClassName="mdi mdi-plus" />
          //           Simpan
          //         </Button>
          //       </div>
          //     </div>
          //   ),
          // },
        ]}
        onChange={(data, key, value) => {
          if (key === "supplier") {
            supplier.map((d) => {
              if (d.label == value) {
                data["id"] = d.value;
                data["alamat"] = d.alamat;
              }
            });

            if (key === "status") {
              if (value == 1) {
                data.status = 1;
              } else if (value == 2) {
                data.status = 2;
              }
            }
          }

          return data;
        }}
      />
    </>
  );
};

export default inject("envStore", "navigationStore")(observer(PurchaseDetail));
