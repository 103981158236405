import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import iziToast from "izitoast";

const defaultData = () => ({
  produk: "",
  qty: 0,
  price: 0,
  total: 0,
  productId: 0,
});

const AddPurchaseItem = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  ...props
}) => {
  let [item, setItem] = useState(defaultData());
  let [ready, setReady] = useState(true);
  let [disabled, setDisabled] = useState(false);
  let [postData, setPostData] = useState([]);
  const [products, setProduct] = useState([]);

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        setPostData(props.item);
      }
      setItem(selectedItem);

      if (props.product) {
        setProduct(props.product);
      }
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
    if (item.qty == 0) {
      callback("Jumlah Barang Tidak Boleh 0", true, false);
    } else if (item.price == 0) {
      callback("Harga Satuan Tidak Boleh 0", true, false);
    } else if (item.total == 0) {
      callback("Total Tidak Boleh 0", true, false);
    } else {
      setDisabled(true);
      let temp = [...postData];
      temp.push(item);
      sessionStorage.setItem("purchaseTemporary", JSON.stringify(temp));
      callback("success", false);
      setDisabled(false);
      onSubmitRequest();
    }
  };

  return (
    ready && (
      <DataForm
        baseId="inventory-form"
        title={"Pilih Produk"}
        visible={visible}
        data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("words.produk"),
            key: "produk",
            labelKey: "label",
            valueKey: "label",
            data: products,
            disabled: disabled,
            validation: "required",
            require: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.jumlahBarang"),
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            key: "qty",
            disabled: disabled,
          },
          {
            id: "price",
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: "Harga Satuan",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            key: "price",
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.total"),
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            key: "total",
            disabled: true,
          },
        ]}
        onChange={async (data, key, value) => {
          if (data.qty || data.price) {
            data.total = data.qty * data.price;
          }

          if (key === "produk") {
            products.map((d) => {
              if (d.label == value) {
                data.productId = d.value;
              }
            });
          }
          setItem(data);
          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default AddPurchaseItem;
