import { crud, http } from "../../libs/react-mpk/services";

let customerService = new crud("/pos_pelanggan");
let serviceUrl = "/pos_pelanggan";
let npwpHeadId = localStorage.getItem('npwpHeadId')

customerService.createCustomer = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

customerService.updateCustomer = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

customerService.getOneCustomer = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

customerService.deleteCustomer = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

customerService.getPageCustomer = async (query) => {
  query = { 
    ...query,
    // npwpHeadId: npwpHeadId,
    'kategori.equals': 1,
  };

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

export default customerService;
