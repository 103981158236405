import { crud, http } from "../../libs/react-mpk/services";

let categoryService = new crud("/produk/kategori");
let serviceUrl = "/produk/kategori";
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let posOutletId = sessionStorage.getItem("posOutletId");

categoryService.createCategory = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

categoryService.updateCategory = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

categoryService.getCategoryById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

categoryService.deleteCategory = async (id) => {
    return http.request({
      method: http.methods.DELETE,
      url: `${serviceUrl}/${id}`,
    });
  };

categoryService.getPageCategory = async (query) => {
  query = {
    ...query,
    npwpHeadId: npwpHeadId,
    "posOutletId.equals": posOutletId,
  };

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

categoryService.generateCode = async () => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/generate_kode/${npwpHeadId}`
  });
};


export default categoryService