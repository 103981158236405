import { crud, http } from "../../libs/react-mpk/services";

let bahanBakuService = new crud("/outlet_produk/bahan_baku");
let serviceUrl = "/outlet_produk/bahan_baku";
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let outletId = sessionStorage.getItem("outletId");

bahanBakuService.getPageBahanBaku = async (query) => {
  query = {
    ...query,
    "posOutletId.equals": outletId,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

bahanBakuService.getBahanBakuById = async (id) => {
  return http.request({
    method:http.methods.GET,
    url:`${serviceUrl}/${id}`
  })
}

bahanBakuService.createBahanBaku = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
    
  });
}

bahanBakuService.updateBahanBaku = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

bahanBakuService.deleteBahanBaku = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};


export default bahanBakuService