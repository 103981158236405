import React, { useEffect, useState } from "react";
import { DataForm, Hint, LoaderInfo } from "../../libs/react-mpk/components";
import { cloneDeep, set } from "lodash";
import t from "counterpart";
import {
  Button,
  Table,
  FontIcon,
  TableHeader,
  TableBody,
  TableCell,
  TableRow,
} from "react-md";
import productService from "./Product.service";
import { inject, observer } from "mobx-react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { text } from "body-parser";
import ProductBahanBakuForm from "./ProductBahanBakuForm";

const defaultData = () => ({
  namaProduk: "",
  jenisProduk: "",
  kodeRak: "",
  kodeOutlet: sessionStorage.getItem("outletCode"),
  rakId: null,
  kategori: "",
  kategoriId: null,
  unit: "",
  unitId: null,
  hargaJual: "",
  deskripsi: "",
  isTrackingPersediaan: false,
  stokPersediaan: "",
  isTrackingHargaPokok: false,
  hargaPokok: "",
  kodeProduk: "",
  image: null,
  isMenggunakanFormula: false,
});

const productTypeList = [
  { id: 1, name: "barang" },
  { id: 2, name: "jasa" },
];

var thousandSeparator = ".";
var decimalSeparator = ",";

const ProductForm = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  modalStore,
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [id, setId] = useState(null);
  let [ready, setReady] = useState(true);
  let [disabled, setDisabled] = useState(false);
  let [showImageForm, setShowImageForm] = useState(false);
  let [bahanBakuQty,setBahanBakuQty] = useState(0)
  let [imageList, setImageList] = useState([]);
  let [deleteImageList, setDeleteImageList] = useState([]);
  let [deleteBahanBakuList, setDeleteBahanBakuList] = useState([]);
  let [rakList, setRakList] = useState([]);
  let [categoryList, setCategoryList] = useState([]);
  let [unitList, setUnitList] = useState([]);
  let [isEdit, setIsEdit] = useState(false);
  let [isKodeOutlet, setIsKodeOutlet] = useState(false);
  let [isFormula, setIsFormula] = useState(false);
  let [showBahanBakuForm, setShowBahanBakuForm] = useState(false);
  let [isJasa, setIsJasa] = useState(false)
  const [productBahanBaku, setProductBahanBaku] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  let outletCode = sessionStorage.getItem("outletCode");

  let [postData, setPostData] = useState({
    posOutletProdukDTO: {
      isTrackingPersediaan: false,
      stokPersediaan: 0,
      isTrackingHargaPokok: false,
      hargaPokok: 0,
      posOutlet: null,
      kodeOutlet: "",
      manajemenRak: {
        id: null,
        namaRak: "",
        kodeRak: "",
      },
      sbkProduk: {
        id: null,
        kode: "PP010",
        nama: "Macbook Pro 2016 10",
        jenis: 1,
        deskripsi: "",
        kategori: {
          id: null,
        },
        unit: {
          id: null,
        },
        npwpHead: null,
        pengaturan: {
          beliProduk: false,
          akunPembelian: null,
          hargaBeli: 0,
          jualProduk: false,
          akunPenjualan: null,
          hargaJual: 0,
          monitoringPersediaan: false,
          akunPersediaan: null,
          batasStokMinimum: 0,
        },
      },
    },
    bahanBakuHapusList: [],
    produkGambarList: [
      {
        id: null,
        subPath: "",
        nama: "",
        dibuatOleh: null,
        tanggalDibuat: null,
        produk: null,
      },
    ],
    produkGambarHapusList: [],
    bahanBakuList: []
  });

  const getRak = async () => {
    setTimeout(async () => {
      let res = await productService.getRakList(props.match.params.outletId);
      var rak = [];

      if (res.data.length === 0) {
        rak.unshift({
          value: null,
          label: `${t.translate("words.unrak")}`,
        });
      } else {
        res.data.map((d) => {
          rak.push({
            value: d.id,
            label: `${d.kodeRak} - ${d.namaRak}`,
          });
        });
      }
      setRakList(rak);
    }, 500);
  };

  const getCategory = async () => {
    setTimeout(async () => {
      let res = await productService.getCategoryList();
      var categories = [];

      if (res.data.length === 0) {
        categories.unshift({
          value: null,
          label: `${t.translate("words.uncategory")}`,
        });
      } else {
        res.data.map((d) => {
          categories.push({
            value: d.id,
            label: `${d.kode} - ${d.nama}`,
          });
        });
      }

      setCategoryList(categories);
      // setShowTaxobjectCode(true)
    }, 500);
  };

  const getUnit = async () => {
    setTimeout(async () => {
      let res = await productService.getUnitList();
      // console.log(res);
      var units = [];

      if (res.data.length === 0) {
        units.unshift({
          value: null,
          label: `${t.translate("words.ununit")}`,
        });
      } else {
        res.data.map((d) => {
          units.push({
            value: d.id,
            label: `${d.kode} - ${d.nama}`,
          });
        });
      }

      setUnitList(units);
      // setShowTaxobjectCode(true)
    }, 500);
  };

  const getBahanBaku = async () => {
    let temp = JSON.parse(sessionStorage.getItem("productBahanBaku"));
    if (temp) {
      setProductBahanBaku(temp);
      setTotalData(temp.length);
    }
    sessionStorage.removeItem("productBahanBaku");
  };

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        try {
          let res = await productService.getProductById(props.item);
          let { data } = res;
          console.log("🚀 ~ file: ProductForm.js:220 ~ initData ~ data:", data)

          selectedItem.isMenggunakanFormula =
            data.posOutletProdukDTO.menggunakanFormula;
          // console.log(data.bahanBakuList);


          if (selectedItem.isMenggunakanFormula) {
            setProductBahanBaku(data.bahanBakuList);
            setDeleteBahanBakuList(
              data.bahanBakuHapusList ? data.bahanBakuHapusList : []
            );
            setBahanBakuQty(data.bahanBakuList.length)
          }
          setPostData(data);
          setImageList(data.produkGambarList);
          setDeleteImageList(
            data.produkGambarHapusList ? data.produkGambarHapusList : []
          );

          selectedItem.id = data.posOutletProdukDTO.id;
          selectedItem.namaProduk = data.posOutletProdukDTO.sbkProduk.nama;

          selectedItem.jenisProduk = String(
            data.posOutletProdukDTO.sbkProduk.jenis
          );

          selectedItem.kodeRak = data.posOutletProdukDTO.manajemenRak
            ? data.posOutletProdukDTO.manajemenRak.kodeRak
            : null;

          selectedItem.namaRak = data.posOutletProdukDTO.manajemenRak
            ? data.posOutletProdukDTO.manajemenRak.kodeRak +
              " - " +
              data.posOutletProdukDTO.manajemenRak.namaRak
            : null;

          selectedItem.rakId = data.posOutletProdukDTO.manajemenRak
            ? String(data.posOutletProdukDTO.manajemenRak.id)
            : null;

          selectedItem.kategori =
            data.posOutletProdukDTO.sbkProduk.kategori.nama;

          selectedItem.kategoriId =
            data.posOutletProdukDTO.sbkProduk.kategori.id;

          selectedItem.unit = data.posOutletProdukDTO.sbkProduk.unit.nama;
          selectedItem.unitId = data.posOutletProdukDTO.sbkProduk.unit.id;
          selectedItem.deskripsi = data.posOutletProdukDTO.sbkProduk.deskripsi;

          selectedItem.hargaJual =
            data.posOutletProdukDTO.sbkProduk.defaultHarga;

          selectedItem.isTrackingPersediaan =
            data.posOutletProdukDTO.isTrackingPersediaan;

          selectedItem.stokPersediaan = data.posOutletProdukDTO.stokPersediaan;

          selectedItem.kodeOutlet = sessionStorage.getItem("outletCode");
          if (outletCode) {
            // console.log("masuk validasi");
            setIsKodeOutlet(true);
          } else {
            // console.log("masuk validasi else");
            setIsKodeOutlet(false);
          }
          selectedItem.kodeProduk =
            data.posOutletProdukDTO.sbkProduk.kode.substring(
              data.posOutletProdukDTO.sbkProduk.kode.indexOf("-") + 1
            );

          selectedItem.isTrackingHargaPokok =
            data.posOutletProdukDTO.isTrackingHargaPokok;
          selectedItem.hargaPokok = data.posOutletProdukDTO.hargaPokok;
          
          setIsFormula(true)
          setIsEdit(true)
        } catch (err) {}
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
      setIsFormula(false)
      setIsEdit(false)
      if (outletCode) {
        setIsKodeOutlet(true);
      } else {
        setIsKodeOutlet(false);
      }
    }

    getRak();
    getCategory();
    getUnit();

    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const deleteImage = (index) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var imageListNew = imageList;

        //tambah di list gambar hapus
        var deleteImageListNew = deleteImageList;
        deleteImageListNew.push(imageListNew[index]);
        setDeleteImageList(deleteImageListNew);

        //hapus di list gambar
        imageListNew.splice(index, 1);
        setImageList(imageListNew);
        callback();
      },
    });
  };

  const deleteBahanBakuItem = (e, i) => {
    let temp =  [...productBahanBaku];
    setDeleteBahanBakuList([...deleteBahanBakuList, temp[i]]);
    temp.splice(i,1);
    setProductBahanBaku(temp)
  };

  const submitData = async (item, callback) => {
    setDisabled(true);

    let tempData = postData;

    tempData.bahanBakuList = [];
    productBahanBaku.map((d) => {
      tempData.bahanBakuList.push({
        id: d.id,
        jumlah: d.jumlah,
        posOutletBahanBaku: {
          id: d.bahanBakuId ? d.bahanBakuId : d.posOutletBahanBaku.id,
        },
      });
    });
    // console.log(tempData.bahanBakuList);

    tempData.bahanBakuHapusList = deleteBahanBakuList;
    
    tempData.posOutletProdukDTO.menggunakanFormula = item.isMenggunakanFormula;

    tempData.posOutletProdukDTO.kodeOutlet = item.kodeOutlet;
    tempData.posOutletProdukDTO.posOutlet = {
      id: props.match.params.outletId,
    };
    tempData.posOutletProdukDTO.sbkProduk.npwpHead = {
      id: props.match.params.npwpHeadId,
    };
    tempData.posOutletProdukDTO.isTrackingPersediaan =
      item.isTrackingPersediaan;

    // tempData.posOutletProdukDTO.stokPersediaan = item.isTrackingPersediaan
    //   ? item.stokPersediaan
    //   : 0;
    if (item.isTrackingPersediaan) {
      tempData.posOutletProdukDTO.stokPersediaan = item.stokPersediaan
        ? item.stokPersediaan
        : 0;
    } else {
      tempData.posOutletProdukDTO.stokPersediaan = 0;
    }

    tempData.posOutletProdukDTO.isTrackingHargaPokok =
      item.isTrackingHargaPokok;
    // tempData.posOutletProdukDTO.hargaPokok = item.isTrackingHargaPokok
    //   ? item.hargaPokok
    //   : 0;
    if (item.isTrackingHargaPokok) {
      tempData.posOutletProdukDTO.hargaPokok = item.hargaPokok
        ? item.hargaPokok
        : 0;
    } else {
      tempData.posOutletProdukDTO.hargaPokok = 0;
    }

    tempData.posOutletProdukDTO.sbkProduk.nama = item.namaProduk;
    tempData.posOutletProdukDTO.sbkProduk.deskripsi = item.deskripsi;
    tempData.posOutletProdukDTO.sbkProduk.defaultHarga = item.hargaJual
      ? item.hargaJual
      : 0;
    tempData.posOutletProdukDTO.sbkProduk.jenis = item.jenisProduk;
    tempData.posOutletProdukDTO.sbkProduk.kode =
      item.kodeOutlet + "-" + item.kodeProduk;
    // tempData.posOutletProdukDTO.sbkProduk.pengaturan.monitoringPersediaan =
    //   item.isTrackingPersediaan;

    tempData.produkGambarList =
      imageList && imageList.length > 0 ? imageList : [];
    tempData.produkGambarHapusList =
      deleteImageList && deleteImageList.length > 0 ? deleteImageList : [];

    tempData.posOutletProdukDTO.manajemenRak = {
      id: item.rakId ? item.rakId : null,
    };
    // if (item.rakId) {
    //   tempData.posOutletProdukDTO.manajemenRak.id = item.rakId;
    // }
    // else{
    //   tempData.posOutletProdukDTO.manajemenRak.id = null;
    // }


    if (item.kategoriId) {
      tempData.posOutletProdukDTO.sbkProduk.kategori.id = item.kategoriId;
    } else {
      tempData.posOutletProdukDTO.sbkProduk.kategori.id = null;
    }

    if (item.unitId) {
      tempData.posOutletProdukDTO.sbkProduk.unit.id = item.unitId;
    } else {
      tempData.posOutletProdukDTO.sbkProduk.unit.id = null;
    }

    // if (!tempData.produkGambarList) {
    //   // callback("success", false);
    //   callback(t.translate("modules.error.imgNotNull"), true, false);
    //   setDisabled(false);
    // } else {
    if (item.id) {
      try {

        if (tempData.bahanBakuList.length > bahanBakuQty ){
          let tempBhnBaku = []
          let tempBhnBakuHps = []

          console.log(productBahanBaku);
          productBahanBaku.map(d => {
            if (d.currBahanBaku){
              // console.log(d.currBahanBaku)
              d.postOutletBahanBaku = {}
              tempBhnBaku.push({
                posOutletBahanBaku: d.currBahanBaku,
                jumlah: d.jumlah,
              })
            }
            else{
              // tempBhnBakuHps.push(d)
                tempBhnBaku.push({
                  id: d.id,
                  dibuatOleh: d.dibuatOleh,
                  diperbaharuiOleh: d.diperbaharuiOleh,
                  jumlah: d.jumlah,
                  posOutletBahanBaku: d.posOutletBahanBaku,
                  posOutletProduk: d.posOutletProduk,
                  tanggalDibuat: d.tanggalDibuat,
                  tanggalDiperbaharui: d.tanggalDiperbaharui
              })
            }
          })

          tempBhnBakuHps.map(d => {
            if(d.id){
              tempData.bahanBakuHapusList.push(d)
            }
          })
          // tempBhnBaku.map(d => {
          //   delete d.id
          // })
          tempData.bahanBakuHapusList = tempBhnBakuHps
          tempData.bahanBakuList = tempBhnBaku
        }

        const res = await productService.updateProduct(tempData, item.id);
        let { data, status } = res;
        // let status = 200
        

        setDisabled(false);
        if (status == 200) {
          callback("success", false, true);

          // TableWrapper.updateDataItem("inventory-table", { id: data.id }, data);
          TableWrapper.reload("inventory-table");
        }
      } catch (err) {
        // console.log(err, 'err')
        setDisabled(false);
        callback(err, true, false);
      }
    } else {
      try {
        const res = await productService.createProduct(tempData);
        let { data, status } = res;
        // console.log(data);
        setDisabled(false);
        if (status == 200) {
          callback("success", false);

          // TableWrapper.updateDataItem("inventory-table", { id: data.id }, data);
          TableWrapper.reload("inventory-table");
        }
      } catch (err) {
        // console.log(err, 'err')
        setDisabled(false);
        callback(err, true, false);
      }
    }
    // }

    onSubmitRequest();
  };

  const closeForm = () => {
    setImageList([]);
    setPostData({
      posOutletProdukDTO: {
        isTrackingPersediaan: false,
        stokPersediaan: 0,
        isTrackingHargaPokok: false,
        hargaPokok: 0,
        posOutlet: null,
        manajemenRak: {
          id: null,
          namaRak: "",
          kodeRak: "",
        },
        sbkProduk: {
          id: null,
          kode: "PP010",
          nama: "Macbook Pro 2016 10",
          jenis: 1,
          deskripsi: "",
          kategori: {
            id: null,
          },
          unit: {
            id: null,
          },
          npwpHead: null,
          pengaturan: {
            beliProduk: false,
            akunPembelian: null,
            hargaBeli: 0,
            jualProduk: false,
            akunPenjualan: null,
            hargaJual: 0,
            monitoringPersediaan: false,
            akunPersediaan: null,
            batasStokMinimum: 0,
          },
        },
      },
      produkGambarList: [
        {
          id: null,
          subPath: "",
          nama: "",
          dibuatOleh: null,
          tanggalDibuat: null,
          produk: null,
        },
      ],
      produkGambarHapusList: [],
    });
    setProductBahanBaku([]);
    onRequestClose();
  };

  return (
    ready && (
      <>
        <DataForm
          baseId="inventory-form"
          title={t.translate("modules.produk.form")}
          visible={visible}
          // data={item}
          onRequestClose={() => closeForm()}
          asDialog={true}
          defaultData={item}
          hintMessage={
            <div>
              <b>{`${t.translate("words.note")}`}</b>{" "}
              {t.translate("words.productNote")}{" "}
            </div>
          }
          definitions={[
            {
              inputType: DataForm.inputTypes.FILE_INPUT,
              label: t.translate("words.gambar"),
              key: "image",
              type: "file",
              accept: ".jpg,.jpeg,.png",
              show: showImageForm,
            },

            {
              label: "List Gambar",
              key: "imageList",
              render: (
                <div style={{ width: "100%" }}>
                  <Button
                    theme="primary"
                    themeType="contained"
                    style={{ margin: 15 }}
                    onClick={() => {
                      setShowImageForm(true);
                    }}
                  >
                    <FontIcon iconClassName="mdi mdi-plus" />
                    {t.translate("words.add")}
                  </Button>
                  <Table style={{ width: "100%", marginBottom: "25px" }}>
                    <TableHeader>
                      <TableRow>
                        <TableCell style={{ width: "10%" }}>
                          {t.translate("words.non")}
                        </TableCell>
                        <TableCell style={{ width: "90%" }}>
                          {t.translate("words.namaFile")}
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {imageList.map((d, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{d.nama}</TableCell>
                            <TableCell>
                              {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                              <Button onClick={() => deleteImage(index)}>
                                {t.translate("words.delete")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              ),
            },

            {
              inputType: DataForm.inputTypes.INPUT,
              label: t.translate("words.namaProduk"),
              key: "namaProduk",
              disabled: disabled,
              required: true,
            },
            {
              inputType: DataForm.inputTypes.INPUT,
              label: t.translate("words.outletCode"),
              key: "kodeOutlet",
              disabled: isKodeOutlet,
              required: true,
              style: { width: "25%", marginRight: "2%" },
            },
            {
              inputType: DataForm.inputTypes.INPUT,
              label: `${t.translate("words.kodeProduk")} (${t.translate(
                "words.alphanumeric"
              )})`,
              key: "kodeProduk",
              disabled: disabled,
              required: true,
              style: { width: "73%" },
            },
            {
              inputType: DataForm.inputTypes.SELECT,
              label: t.translate("words.jenisProduk"),
              key: "jenisProduk",
              theme: "filled",
              options: productTypeList.map((d) => ({
                value: d.id,
                children: t.translate(`words.${d.name}`),
              })),
              labelKey: "children",
              valueKey: "value",
              disabled: isEdit || disabled,
              required: true,
            },

            {
              inputType: DataForm.inputTypes.AUTOCOMPLETE,
              label: t.translate("words.rak"),
              key: "namaRak",
              labelKey: "label",
              valueKey: "label",
              data: rakList,
              disabled: disabled || isJasa,
              required: true,
            },

            {
              inputType: DataForm.inputTypes.AUTOCOMPLETE,
              label: t.translate("words.kategori"),
              key: "kategori",
              // validation: "required",
              labelKey: "label",
              valueKey: "label",
              data: categoryList,
              disabled: disabled,
              required: true,
            },

            {
              inputType: DataForm.inputTypes.AUTOCOMPLETE,
              label: t.translate("words.unit"),
              key: "unit",
              // validation: "required",
              labelKey: "label",
              valueKey: "label",
              data: unitList,
              disabled: disabled,
              required: true,
            },

            // {
            //   inputType: DataForm.inputTypes.SELECT,
            //   label: t.translate("words.jenisProduk"),
            //   key: "kategori",
            //   theme: "filled",
            //   options: productCategory.map((d) => ({
            //     value: d.id,
            //     children: t.translate(`words.${d.name}`),
            //   })),
            //   labelKey: "children",
            //   valueKey: "value",
            //   disabled: disabled,
            // },
            {
              inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
              label: t.translate("words.hargaJualSatuan"),
              thousandSeparator: thousandSeparator,
              decimalSeparator: decimalSeparator,
              isNumericString: true,
              type: "text",
              key: "hargaJual",
              disabled: disabled,
              required: true,
            },
            {
              inputType: DataForm.inputTypes.TEXTAREA,
              label: t.translate("words.deskripsi"),
              key: "deskripsi",
              disabled: disabled,
            },
            {
              inputType: DataForm.inputTypes.CHECKBOX,
              label: t.translate("words.menggunakanFormula"),
              key: "isMenggunakanFormula",
              disabled: isFormula,
              show: (item) => item.jenisProduk == 1,
            },
            {
              label: "List Bahan Baku",
              key: "formulaBahanBaku",
              render: (item) =>
                <>
                  {(item.isMenggunakanFormula && item.jenisProduk == 1) && (
                    <div style={{ width: "100%" }}>
                      {/* {!isFormula && (
                      )} */}

                      <Table>
                        <>
                          <div style={{ display: "flex" }}>
                            <Button
                              theme="primary"
                              themeType="contained"
                              style={{ margin: 15 }}
                              onClick={() => {
                                setShowBahanBakuForm(true);
                                // setShowImageForm(true);
                              }}
                            >
                              <FontIcon iconClassName="mdi mdi-plus" />
                              {t.translate("words.add")}
                            </Button>
                          </div>
                          <TableHeader>
                            <TableRow>
                              <TableCell>{t.translate("words.non")}</TableCell>
                              <TableCell>
                                {t.translate("words.namaBahanBaku")}
                              </TableCell>
                              <TableCell>
                                {t.translate("words.jumlah")}
                              </TableCell>
                            </TableRow>
                          </TableHeader>

                          <TableBody>
                            {!loading ? (
                              productBahanBaku.map((d, i) => {
                                return (
                                  <TableRow key={i}>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>
                                      {console.log(d, i)}
                                      {d.currBahanBaku
                                        ? d.currBahanBaku.sbkProduk.nama
                                        : d.posOutletBahanBaku.sbkProduk.nama}
                                    </TableCell>
                                    <TableCell>{d.jumlah}</TableCell>
                                    <TableCell>
                                      <Button
                                        buttonType="icon"
                                        style={{ marginTop: "1.5vh" }}
                                        onClick={(e) => deleteBahanBakuItem(e, i)}
                                      >
                                        <FontIcon iconClassName="mdi mdi-delete" />
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <div style={{ marginLeft: "120%" }}>
                                <LoaderInfo />
                              </div>
                            )}
                          </TableBody>
                        </>
                      </Table>
                    </div>
                  )}
                </>
            },
            {
              inputType: DataForm.inputTypes.CHECKBOX,
              label: t.translate("words.monitorPersediaanBarang"),
              key: "isTrackingPersediaan",
              disabled: isEdit,
              show: (item) => (!item.isMenggunakanFormula && item.jenisProduk == 1),
            },
            {
              inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
              label: t.translate("words.availableStock"),
              thousandSeparator: thousandSeparator,
              decimalSeparator: decimalSeparator,
              isNumericString: true,
              key: "stokPersediaan",
              disabled: (item) => isEdit || !item.isTrackingPersediaan,
              show: (item) => (!item.isMenggunakanFormula && item.jenisProduk == 1),
            },
            {
              inputType: DataForm.inputTypes.CHECKBOX,
              label: t.translate("words.CogsTracking"),
              key: "isTrackingHargaPokok",
              disabled: (item) => isEdit || !item.isTrackingPersediaan,
              show: (item) => (!item.isMenggunakanFormula && item.jenisProduk == 1),
            },
            {
              inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
              label: t.translate("words.CogsMeans"),
              thousandSeparator: thousandSeparator,
              decimalSeparator: decimalSeparator,
              isNumericString: true,
              key: "hargaPokok",
              disabled: (item) => isEdit || !item.isTrackingPersediaan || !item.isTrackingHargaPokok,
              show: (item) => (!item.isMenggunakanFormula && item.jenisProduk == 1),
            }
          ]}
          onChange={async (data, key, value) => {
            if (key == "image") {
              let res = await productService.uploadImage(value);
              if (res.status == 200) {
                let newImageList = imageList;
                newImageList.push(res.data);
                setImageList(newImageList);
                setShowImageForm(false);
                data["image"] = null;
              }
            }


            if (key =="jenisProduk"){
              if (value==2){
                data.isTrackingPersediaan = false;
                data.stokPersediaan = 0;
                data.isTrackingHargaPokok = false;
                data.hargaPokok = 0;
                setIsJasa(true)
              } else {
                setIsJasa(false)
              }
            }

            if (key === "kategori") {
              categoryList.map((d) => {
                if (d.label === value) {
                  data.kategoriId = d.value;
                }
              });
            }

            if (key === "unit") {
              unitList.map((d) => {
                if (d.label === value) {
                  data.unitId = d.value;
                }
              });
            }

            if (key === "namaRak") {
              rakList.map((d) => {
                if (d.label === value) {
                  data.rakId = d.value;
                }
              });
            }

            if (key === "hargaJual") {
              if (value <0){
                data.hargaJual = 0;
              }
            }

            if (key === "stokPersediaan") {
              if (value <0){
                data.stokPersediaan = 0;
              }
            }

            if (key === "hargaPokok") {
              if (value <=0){
                data.hargaPokok = 1;
              }
            }

            if (key === "isTrackingPersediaan") {
              if(value===false){
                data.isTrackingHargaPokok = false;
              }
            }

            return data;
          }}
          onSubmit={!disabled ? submitData : null}
        />
        <ProductBahanBakuForm
          visible={showBahanBakuForm}
          onRequestClose={() => {
            setShowBahanBakuForm(false);
            setSelectedItem(null);
          }}
          item={productBahanBaku}
          onSubmitRequest={getBahanBaku}
        />
      </>
    )
  );
};

export default inject("modalStore")(observer(ProductForm));
