import { crud, http } from "../../libs/react-mpk/services";

let PenjualanService = new crud("/penjualan");
let serviceUrl = "/outlet_penjualan";
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let posOutletId = sessionStorage.getItem("outletId");

PenjualanService.createPenjualan = (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};
PenjualanService.historyPayment = (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/update_pembayaran`,
    data,
  });
};
PenjualanService.TransactionCancel = (id) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/cancel_pembayaran/${id}`,
  });
};
PenjualanService.penjualanGetPage = (query) => {
  query = {
    ...query,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};


PenjualanService.getLawanTransaksi = async (query) => {
  query = {
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url : `/pos_pelanggan`,
    query
  })
}
PenjualanService.getProductList = async (query) => {
  query = {
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url : `/outlet_produk`,
    query
  })
}
PenjualanService.penjualanGetDetail = async (id) => {
  return http.request({
    method: http.methods.GET,
    url : `${serviceUrl}/${id}`,
  })
}
PenjualanService.getCategory = async (query) => {
  query = {
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url : `/produk/kategori`,
    query
  })
}
PenjualanService.getKaryawan = async (query) => {
  query = {
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url : `/karyawan`,
    query
  })
}

export default PenjualanService;
