import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { cloneDeep } from "lodash";
import { FormWrapper } from "../../libs/react-mpk/wrapper";

import { DataForm, DividerLabel } from "../../libs/react-mpk/components";

import {
  defaultQueryParameter,
  sorts,
} from "../../libs/react-mpk/config/constant";
import { http } from "../../libs/react-mpk/services";
import AddPenjualanItem from "./AddPenjualanItem";
import PenjualanService from "./Penjualan.service";
import iziToast from "izitoast";
import {
  Button,
  Dialog,
  DialogContent,
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
  Text,
  TextContainer,
  TextField,
} from "react-md";
import { format } from "../../libs/react-mpk/services/number.service";
import { validateSpecOption } from "babel-preset-env/lib/normalize-options";

const defaultData = () => ({
  customer: null,
  pembayaran: null,
  tunai: null,
  isTerutangPajak: null,
  BatasPembayaran: null,
  posKaryawan: null,
});
const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const PenjualanForm = ({ envStore, navigationStore, ...props }) => {
  const { inputTypes, definition } = FormWrapper;
  const outletId = props.match.params.outletId;
  const npwpHeadId = props.match.params.npwpHeadId;
  const [item, setItem] = useState(defaultData);
  const [pelanggan, setPelanggan] = useState([]);
  const [karyawan, setKaryawan] = useState([]);
  const [penjualanProduct, setPenjualanProduct] = useState([]);
  const [product, setProduct] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTunai, setTotalTunai] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [dataSubmit, setDataSubmit] = useState({
    lawanTransaksi: {
      id: "",
    },
    nominalTagihan: "",
    nominalDiterima: "",
    isTerutangPajak: true,
    produkPenjualanList: [],
    isBayarNanti: false,
    batasPembayaran: "",
    catatan: "",
    posOutlet: {
      id: "",
    },
    npwpHead: {
      id: "",
    },
    posKaryawan: {
      id: null,
    },
  });

  const [query, setQuery] = useState(
    defaultQuery({
      page: 1,
      size: 100,
      sort: sorts.descending,
      sortBy: "tanggalDibuat",
    })
  );
  const initData = async () => {
    let temp = cloneDeep(defaultData());

    try {
      setItem(temp);
    } catch (err) {}
  };

  const getCustomer = async (query) => {
    setDisabled(true);
    query.page = 0;
    query = {
      ...query,
      "kategori.equals": 1,
      npwpHeadId: props.match.params.npwpHeadId,
    };
    try {
      let res = await PenjualanService.getLawanTransaksi(query);
      let { data } = res;
      let item = [];
      data.map((d) => {
        item.push({
          value: d.id,
          label: d.nama,
        });
      });
      setPelanggan(item);
      setDisabled(false);
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };
  const getKaryawan = async (query) => {
    setDisabled(true);
    query.page = 0;
    query = {
      ...query,
      npwpHeadId: props.match.params.npwpHeadId,
    };
    try {
      let res = await PenjualanService.getKaryawan(query);
      let { data } = res;

      let item = [];
      data.map((d) => {
        item.push({
          value: d.id,
          label: d.nama,
        });
      });
      setKaryawan(item);
      setDisabled(false);
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const getProduct = async (query) => {
    let temp = JSON.parse(sessionStorage.getItem("purchaseTemporary"));
    if (temp) {
      setPenjualanProduct(temp);
      temp.map((d) => {
        setTotalPrice(totalPrice + d.total);
      });
      sessionStorage.removeItem("purchaseTemporary");
    }
  };
  const fetchProduct = async () => {
    query.page = 0;
    query.size = 10000;
    let newQuery = http.mapQueryCriteria(query);
    newQuery.npwpHeadId = props.match.params.npwpHeadId;
    newQuery["posOutletId.equals"] = outletId;
    // newQuery["produkKategoriId.equals"] = id;

    try {
      let res = await PenjualanService.getProductList(newQuery);
      let { data } = res;
      let temp = [];
      data.map((d) => {
        temp.push({
          value: d.id,
          label: d.sbkProduk.nama,
          price: d.sbkProduk.defaultHarga,
          stokPersediaan: d.stokPersediaan,
        });
      });
      setProduct(temp);
    } catch (err) {}
  };
  useEffect(() => {
    initData();
    getKaryawan(query);
    getProduct(query);
    getCustomer(query);
    fetchProduct()
  }, []);

  const submitData = async (data, callback) => {
    setDisabled(true);
    let tempData = dataSubmit;
    pelanggan.map((d) => {
      if (d.label == data.customer) {
        tempData.lawanTransaksi.id = d.value;
      }
    });
    karyawan.map((d) => {
      if (d.nama == data.karyawan) {
        tempData.posKaryawan.id = d.value;
      }
    });

    tempData.nominalTagihan = parseInt(totalPrice);
    tempData.nominalDiterima = parseInt(totalTunai);
    tempData.isTerutangPajak = data.isTerutangPajak == 0 ? true : false;
    tempData.isBayarNanti = data.pembayaran == 2 ? true : false;
    tempData.batasPembayaran = data.pembayaran == 1?0:parseInt(data.BatasPembayaran);
    tempData.catatan = data.catatan;
    tempData.posOutlet.id = parseInt(outletId);
    tempData.npwpHead.id = parseInt(npwpHeadId);
    tempData.produkPenjualanList = penjualanProduct;
    setDisabled(false);
    try {
      let res = await PenjualanService.createPenjualan(tempData);
      let { status } = res;
      if (status === 200) {
        navigationStore.redirectTo(
          `/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/penjualan`
        );
      }
      callback("success", false);
      setDisabled(false);
    } catch (err) {
      // setDisabled(false);
      callback(err, true, false);
    }
  };

  const addItem = () => {
    setShowForm(true);
  };
  const deletePurchaseItem = (e, i) => {
    setTotalPrice(totalPrice - penjualanProduct[i].total);
    let temp = [...penjualanProduct];
    temp.splice(i, 1);
    setPenjualanProduct(temp);
  };

  return (
    <>
      <FormWrapper
        backTo={`/${envStore.env.applicationType}/application/${npwpHeadId}/outlet/${outletId}/penjualan`}
        baseId="penjualan-form"
        title={t.translate("modules.penjualan.formTitle")}
        hintShowIcon={false}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("words.customer"),
            key: "customer",
            labelKey: "label",
            valueKey: "label",
            data: pelanggan,
            require: true,
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("words.employee"),
            key: "posKaryawan",
            labelKey: "label",
            valueKey: "label",
            data: karyawan,
            require: true,
            disabled: disabled,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <Button
                    theme="primary"
                    themeType="contained"
                    style={{ margin: 15 }}
                    onClick={(data) => addItem(data)}
                  >
                    <FontIcon iconClassName="mdi mdi-plus" />
                    {t.translate("words.add")} Produk
                  </Button>
                </div>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCell>{t.translate("words.non")}</TableCell>
                      <TableCell>{t.translate("words.namaProduk")}</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Harga Satuan</TableCell>
                      <TableCell>{t.translate("words.total")}</TableCell>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {penjualanProduct.map((d, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{d.produk}</TableCell>
                          <TableCell>{d.jumlah}</TableCell>
                          <TableCell>Rp. {format(d.price)}</TableCell>
                          <TableCell>Rp. {format(d.total)}</TableCell>

                          <TableCell>
                            <Button
                              buttonType="icon"
                              style={{ marginTop: "1.5vh" }}
                              onClick={(e) => deletePurchaseItem(e, i)}
                            >
                              <FontIcon iconClassName="mdi mdi-delete" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TableCell>Total</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Rp. {format(totalPrice)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            ),
          },
          {
            render: (
              <DividerLabel
                style={{ marginTop: "2%", marginBottom: "-1%" }}
                children={
                  <Text style={{ fontSize: 16 }}>Pilih Pembayaran</Text>
                }
              />
            ),
          },
          {
            inputType: inputTypes.RADIO,
            label: "Tunai",
            key: "pembayaran",
            name: "pembayaran",
            value: '1',
            style: {
              width: "25%",
            },
          },
          {
            inputType: inputTypes.RADIO,
            label: "Bayar Nanti",
            key: "pembayaran",
            name: "pembayaran",
            value: '2',
            style: {
              width: "25%",
            },
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Tunai",
            key: "tunai",
            options: [
              {
                value: totalPrice,
                label: "Uang Pas",
              },
              {
                value: 40000,
                label: "Rp. 40.000",
              },
              {
                value: 50000,
                label: "Rp. 50.000",
              },
              {
                value: 100000,
                label: "Rp. 100.000",
              },
            ],
            style: {
              marginRight: "10%",
            },
            show: (item) => item.pembayaran == 1,
          },
          {
            render: (
              <Table>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", fontSize: 20 }}>
                    Total Payment
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", fontSize: 20 }}>
                    Rp. {format(totalTunai)}
                  </TableCell>
                </TableRow>
              </Table>
            ),
            style: { width: "100%" },
            show: (item) => item.pembayaran == 1,
          },
          {
            render: (
              <DividerLabel
                style={{ marginTop: "2%" }}
                children={
                  <Text style={{ fontSize: 16 }}>Apakah Terutang Pajak ? </Text>
                }
              />
            ),
            show: (item) => item.pembayaran == 1,
          },
          {
            inputType: inputTypes.SELECT,
            label: "Batas Pembayaran",
            key: "BatasPembayaran",
            options: [
              { label: "Custom Jangka Waktu Pembayaran", value: 0 },
              { label: "Hari ini", value: 1 },
              { label: "7 Hari", value: 7 },
              { label: "14 Hari", value: 14 },
              { label: "30 Hari", value: 30 },
            ],
            show: (item) => item.pembayaran == 2,
          },
          {
            id: "CustomWaktu",
            inputType: inputTypes.INPUT,
            label: "Jangka waktu Pembayaran",
            key: "CustomWaktu",
            show: (item) => item.pembayaran == 2 && item.BatasPembayaran == 0,
          },
          {
            id: "Catatan",
            inputType: inputTypes.INPUT,
            label: "Catatan",
            key: "Catatan",
            show: (item) => item.pembayaran == 2,
          },
          {
            inputType: inputTypes.SELECT,
            label: "Apakah Terutang Pajak",
            key: "isTerutangPajak",
            options: [
              {
                value: 0,
                label: "Ya",
              },
              {
                value: 1,
                label: "Tidak",
              },
            ],
            show: (item) => item.pembayaran == 1 || item.pembayaran == 2,
          },
        ]}
        onChange={(data, key, value) => {
          if (key == "customer") {
            pelanggan.map((d) => {
              if (d.label == value) {
                // data.customer = d.value
              }
            });
          }
          if (key === "tunai") {
            setTotalTunai(value);
          }
          return data;
        }}
        submitLabel="Bayar"
        onSubmit={!disabled ? submitData : null}
      />
      <AddPenjualanItem
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
        }}
        item={penjualanProduct}
        onSubmitRequest={getProduct}
        product={product}
        {...props}
      />
    </>
  );
};

export default inject("envStore", "navigationStore")(observer(PenjualanForm));
