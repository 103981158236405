import React from 'react'
import { Button, FontIcon } from 'react-md'
import Flex from '../Flex'
import { action } from '../../class'
import Link from '../Link'
import './Commandbar.scss'
import { TooltipHoverModeConfig, Tooltipped } from '@react-md/tooltip';

const Commandbar = ({
  actions       = null,
  className     = '',
  rightCorner   = null,
  leftCorner    = null,
  title         = null,
  subtitle      = null,
  backTo        = null,
  ...props
}) => {
  return (
    <Flex 
      className={`mpk-commandbar mpk-padding-N padding-left padding-right ${className}`}
      align={Flex.properties.align.CENTER}
      justify={Flex.properties.justify.BETWEEN}
      {...props}
    >
      <Flex 
        fit
        align={Flex.properties.align.CENTER}
      >
        {leftCorner ? 
          <div className="mpk-margin-N margin-right">
             {backTo && <Link.IconButton to={backTo} iconClassName="mdi mdi-arrow-left"/>}
            {leftCorner}
          </div>
        : (
          backTo && <Link.IconButton to={backTo} iconClassName="mdi mdi-arrow-left"/>
        )}
        {title && (
          <span className="mpk-font weight-B size-L">{title}</span>
        )}
        {subtitle && (
          <span className="mpk-font weight-L size-L mpk-margin-S margin-left">{subtitle}</span>
        )}
      </Flex>
      <Flex
        align={Flex.properties.align.CENTER}
      >
        {actions && (
          <div>
            {actions.filter(d => (typeof d.show === 'boolean' ? d.show : true)).map((d, i) => {
              return (
                <TooltipHoverModeConfig 
                  enabled={true} delayTimeout={2} defaultDelay={2}
                  key={`commandbar-action-${i}`}
                >
                  <Tooltipped
                    id={`auto-positioning-above ${d.label}`}
                    tooltip={`${d.label}`}
                    defaultPosition="below"
                  >
                    <Button
                      buttonType="icon"
                      key={`commandbar-action-${i}`}
                      onClick={d.onClick}
                    >
                      <FontIcon iconClassName={d.iconClassName}/>
                    </Button>
                  </Tooltipped>
                </TooltipHoverModeConfig>
              )
            })}
          </div>
        )}
        {rightCorner}
      </Flex>
    </Flex>
  )
}

Commandbar.action = action

export default Commandbar
