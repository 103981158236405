import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import { inject, observer } from "mobx-react";
import EmployeeForm from "./EmployeeForm";
import EmployeePinForm from "./EmployeePinForm";
import outletService from "../Outlet/Outlet.service";
import employeeService from "./Employee.service";
import iziToast from "izitoast";

const Employee = ({
  className = "",
  showCommandbar = true,
  showAll = false,
  navigationStore,
  modalStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let premium = sessionStorage.getItem('premium');

  const deleteEmployee = async (item) => {
    try {
      let res = await employeeService.deleteEmployee(item.id);

      let { data, status } = res;
      // console.log(res)

      if (status == 200) {
        iziToast.success({
          message: data.message,
        });

        TableWrapper.updateDataItem("employee-table", { id: data.id }, data);
        TableWrapper.reload("employee-table");
      } else {
        iziToast.info({
          message: data.message,
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const deleteItem = async (item) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        deleteEmployee(item);
        callback();
      },
    });
  };

  return (
    <>
      <TableWrapper
        baseId="employee-table"
        title={t.translate("modules.employee.title")}
        className={className}
        hintMessage={t.translate("modules.employee.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.employee.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        columns={[
          {
            label: t.translate("words.name"),
            searchable: true,
            sortable: false,
            key: "nama",
            render: (item) => item.nama,
          },
          {
            label: t.translate("words.tanggalDibuat"),
            sortable: true,
            searchable: false,
            key: "tanggalDibuat",
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },

          // {
          //   label: "Boolean",
          //   type: TableWrapper.dataTypes.BOOLEAN,
          //   render: (item) => (
          //     <TableWrapper.Status
          //       type={
          //         item.boolean
          //           ? TableWrapper.Status.types.SUCCESS
          //           : TableWrapper.Status.types.ERROR
          //       }
          //     >
          //       {item.boolean ? "TRUE" : "FALSE"}
          //     </TableWrapper.Status>
          //   ),
          // },
          // {
          //   label: "Date",
          //   type: TableWrapper.dataTypes.DATE,
          //   render: (item) => moment(item.date).format("lll"),
          // },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate("words.setPin")}`,
            "mdi mdi-account-lock",
            (item) => {
              setSelectedItem(item.id);
              setShowPin(true);
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate("words.delete")}`,
            "mdi mdi-trash-can",
            (item) => {
              deleteItem(item);
            },
            true
          ),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', () => console.log('request delete'), true)
        ]}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "posOutletId.equals": props.match.params.outletId,
              };

              let page = await employeeService.getAllPage(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];
              resolve(page);
            } catch (err) {
              // resolve(err);
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <EmployeeForm
        id="employee-form"
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />

      <EmployeePinForm
        visible={showPin}
        onRequestClose={() => {
          setShowPin(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        firstTime={false}
      />
    </>
  );
};

export default inject("navigationStore", "modalStore")(observer(Employee));
