import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find, cloneDeep } from "lodash";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import { LoaderInfo } from "../../libs/react-mpk/components";
import {
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
  TextField,
  Button,
  Text,
} from "react-md";
import {
  defaultQueryParameter,
  sorts,
} from "../../libs/react-mpk/config/constant";
import reportService from "./Report.service";
import moment from "moment";
import { format } from "../../libs/react-mpk/services/number.service";
import iziToast from "izitoast";

const defaultData = () => ({
  outletName: "",
  phoneNumber: "",
  email: "",
  address: "",
  postalCode: "",
  area: {
    id: null,
  },
});

const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const Report = ({ envStore, ...props }) => {
  const { inputTypes, definition } = FormWrapper;
  const [loading, setLoading] = useState(false);
  const [grossReport, setGrossReport] = useState({});
  const [item, setItem] = useState(false);
  const date = new Date();

  const [query, setQuery] = useState(
    defaultQuery({
      page: 1,
      size: 5,
      sort: sorts.descending,
      sortBy: "tanggalDibuat",
      outletId: props.match.params.outletId,
      tanggalAwal: moment(date).format("YYYY-MM-DD"),
      tanggalAkhir: moment(date).format("YYYY-MM-DD"),
    })
  );

  const initData = async () => {
    setLoading(true);

    try {
      let res = await reportService.getGrossReport(query);

      let { data } = res;

      setGrossReport(data);
      setLoading(false);
      //   temp.outletName = data.nama;
      //   temp.phoneNumber = data.noTelepon;
      //   temp.email = data.email;
      //   temp.address = data.alamat;
      //   temp.postalCode = data.area.kodepos;
      //   temp.area.id = data.area.id;
    } catch (err) {
      setLoading(false)
      console.log(err)
      iziToast.error({
        message: err.message
      })
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const changeDate = (e) => {
    if(e.target.name === 'tanggalAwal'){
      if(e.target.value > query['tanggalAkhir']){
          e.target.value = query['tanggalAkhir']
      }
  }
    setQuery({ ...query, [e.target.name]: e.target.value });
  };

  return (
    <FormWrapper
      //   actions={[
      //     {
      //       label: 'Reload',
      //       iconClassName: 'mdi mdi-reload'
      //     }
      //   ]}
      baseId="edit-outlet-form"
      title={`${t.translate("modules.laporanLabaRugiKotor.labaRugiKotor")}`}
      hintMessage={`${t.translate(
        "modules.laporanLabaRugiKotor.labaRugiKotor"
      )} (${t.translate("words.rupiah")})`}
      hintShowIcon={false}
      defaultData={item}
      definitions={[
        {
          render: (
            <div style={{ width: "100%" }}>
              <div style={{ width: "85%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <TextField
                    id="startDate"
                    label="StartDate"
                    type="date"
                    name="tanggalAwal"
                    value={query.tanggalAwal}
                    onChange={(e) => changeDate(e)}
                    max={moment(date).format("YYYY-MM-DD")}
                  />
                  <TextField
                    id="endDate"
                    label="endDate"
                    type="date"
                    name="tanggalAkhir"
                    value={query.tanggalAkhir}
                    onChange={(e) => changeDate(e)}
                    min={query.tanggalAwal}
                    // max={new Date()}
                  />

                  <Button
                    buttonType="icon"
                    style={{ marginTop: "1%" }}
                    onClick={() => initData()}
                  >
                    <FontIcon iconClassName="mdi mdi-magnify" />
                  </Button>
                </div>
              </div>

              {!loading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Table fullWidth>
                    <TableHeader>
                      <TableRow>
                        <TableCell>
                          <h3>{t.translate("words.netSales")}</h3>
                        </TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHeader>

                    <TableBody>
                      <TableRow disableBorders={true}></TableRow>
                      <TableRow disableBorders={true}>
                        <TableCell>
                          <p style={{ fontSize: "16", marginLeft: "7%" }}>
                            {t.translate("words.sales")}
                          </p>
                        </TableCell>
                        <TableCell>
                          {grossReport.nilaiPenjualan < 0
                            ? `(${format(grossReport.nilaiPenjualan * -1)})`
                            : format(grossReport.nilaiPenjualan)}
                        </TableCell>
                      </TableRow>
                      <TableRow disableBorders={true}>
                        <TableCell>
                          <p style={{ fontSize: "16", marginLeft: "7%" }}>
                            {t.translate("words.return")}
                          </p>
                        </TableCell>
                        <TableCell>
                          {grossReport.nilaiPembatalan < 0
                            ? `(${format(grossReport.nilaiPembatalan * -1)})`
                            : format(grossReport.nilaiPembatalan)}
                        </TableCell>
                      </TableRow>
                      <TableRow disableBorders={true}>
                        <TableCell>
                          <b>{t.translate("words.netSalesTotal")}</b>
                        </TableCell>
                        <TableCell>
                          <b style={{ fontSize: "16", marginLeft: "60%" }}>
                            {grossReport.nilaiPenjualanBersih < 0
                              ? `(${format(
                                  grossReport.nilaiPenjualanBersih * -1
                                )})`
                              : format(grossReport.nilaiPenjualanBersih)}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow disableBorders={true}>
                        <TableCell>
                        <TableCell>{t.translate("words.cogs")}</TableCell>
                          <p style={{ fontSize: "16", marginLeft: "60%" }}>
                            {grossReport.nilaiHargaPokok < 0
                              ? `(${format(grossReport.nilaiHargaPokok * -1)})`
                              : `(${format(grossReport.nilaiHargaPokok)})`}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow disableBorders={true}>
                        <TableCell>
                          <b>
                            {grossReport.nilaiLabaKotor < 0
                              ? t.translate("words.netProfit")
                              : t.translate("words.grossProfit")}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b style={{ fontSize: "16" }}>
                            {grossReport.nilaiLabaKotor < 0
                              ? `(${format(grossReport.nilaiLabaKotor * -1)})`
                              : format(grossReport.nilaiLabaKotor)}
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  {/* <Table>
                    <TableHeader>
                      <TableRow>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHeader>

                    <TableBody>
                      <TableRow disableBorders={true}></TableRow>

                      <TableRow disableBorders={true}>
                        <TableCell>
                          {grossReport.nilaiPenjualan < 0
                            ? `(${format(grossReport.nilaiPenjualan * -1)})`
                            : format(grossReport.nilaiPenjualan)}
                        </TableCell>
                      </TableRow>

                      <TableRow disableBorders={true}>
                        <TableCell>
                          <p style={{ fontSize: "16", marginLeft: "5%" }}>
                            {grossReport.nilaiPembatalan < 0
                              ? `(${format(grossReport.nilaiPembatalan * -1)})`
                              : format(grossReport.nilaiPembatalan)}
                          </p>
                        </TableCell>
                      </TableRow>

                      <TableRow disableBorders={true}>
                        <TableCell>
                          <b>
                            {grossReport.nilaiPenjualanBersih < 0
                              ? `(${format(
                                  grossReport.nilaiPenjualanBersih * -1
                                )})`
                              : format(grossReport.nilaiPenjualanBersih)}
                          </b>
                        </TableCell>
                      </TableRow>

                      <TableRow disableBorders={true}>
                        <TableCell>
                          {grossReport.nilaiHargaPokok < 0
                            ? `(${format(grossReport.nilaiHargaPokok * -1)})`
                            : `(${format(grossReport.nilaiHargaPokok)})`}
                        </TableCell>
                      </TableRow>

                      <TableRow disableBorders={true}>
                        <TableCell>
                          <b>
                            {grossReport.nilaiLabaKotor
                              ? `(${format(grossReport.nilaiLabaKotor * -1)})`
                              : format(grossReport.nilaiLabaKotor)}
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table> */}
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LoaderInfo />
                </div>
              )}
            </div>
          ),
        },
      ]}
      onChange={(data, key, value) => {
        return data;
      }}
      submitLabel="Submit"
      onSubmit={null}
    />
  );
};

export default inject("envStore")(observer(Report));
