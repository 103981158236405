import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import { inject, observer } from "mobx-react";
import ProductForm from "./ProductForm";
import productService from "./Product.service";
import iziToast from "izitoast";
import { format } from "../../libs/react-mpk/services/number.service";
import { times } from "lodash";
import {
  Button,
  Card,
  ClearSVGIcon,
  CloseSVGIcon,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "react-md";
import PremiumForm from "../Premium/PremiumForm";
import PremiumPackage from "../Premium/PremiumPackage";

const Product = ({
  className = "",
  showCommandbar = true,
  navigationStore,
  modalStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showFormPremium, setShowFormPremium] = useState(false);
  let [showDialogProduk, setShowDialogProduk] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [produkqty, setProdukQty] = useState(0);
  let [showDialogPremium, setShowDialogPremium] = useState(false);
  let outletId = props.match.params.outletId;
  let premium = sessionStorage.getItem("premium");

  const showProdukDialog = () => {
    modalStore.showInfo({ title: "DIALOG PRODUK" });
  };

  const deleteProduct = async (item) => {
    try {
      let res = await productService.deleteProduct(item.id);

      let { data, status } = res;

      if (status == 200) {
        iziToast.success({
          message: data.message,
        });

        // TableWrapper.updateDataItem("inventory-table", { id: data.id }, data);
        TableWrapper.reload("inventory-table");
      } else {
        iziToast.info({
          message: data.message,
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };
  const deleteItem = async (item) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        deleteProduct(item);
        callback();
      },
    });
  };

  return (
    <>
      <TableWrapper
        baseId="inventory-table"
        title={t.translate("modules.produk.title")}
        className={className}
        hintMessage={t.translate("modules.produk.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.produk.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        columns={[
          {
            label: t.translate("words.namaProduk"),
            searchable: true,
            sortable: false,
            key: "produkNama",
            definition: {
              criterias: ["contains", "equals", "notEquals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sbkProduk && item.sbkProduk.nama,
          },
          {
            label: t.translate("words.kategori"),
            searchable: true,
            sortable: true,
            key: "kategori",
            definition: {
              criterias: ["contains", "equals", "notEquals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sbkProduk && item.sbkProduk.kategori.nama,
          },
          {
            label: t.translate("words.kodeRak"),
            searchable: true,
            sortable: false,
            key: "kodeRak",
            definition: {
              criterias: ["contains", "equals", "notEquals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              item.manajemenRak ? item.manajemenRak.kodeRak : "Tanpa Rak",
          },
          {
            label: t.translate("words.namaRak"),
            searchable: true,
            sortable: false,
            key: "namaRak",
            definition: {
              criterias: ["contains", "equals", "notEquals"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              item.manajemenRak ? item.manajemenRak.namaRak : "Tanpa Rak",
          },
          {
            label: t.translate("words.hargaJualSatuan"),
            searchable: false,
            sortable: false,
            key: "defaultHarga",
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) =>
              item.sbkProduk && `Rp. ${format(item.sbkProduk.defaultHarga)}`,
          },
          {
            label: t.translate("words.menggunakanFormula"),
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.menggunakanFormula
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.menggunakanFormula
                  ? t.translate("words.yes")
                  : t.translate("words.no")}
              </TableWrapper.Status>
            ),
          },
          {
            label: t.translate("words.monitorPersediaanBarang"),
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.isTrackingPersediaan
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.isTrackingPersediaan
                  ? t.translate("words.yes")
                  : t.translate("words.no")}
              </TableWrapper.Status>
            ),
          },
          {
            label: t.translate("words.availableStock"),
            searchable: false,
            sortable: false,
            key: "stokPersediaan",
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => format(item.stokPersediaan),
          },
          {
            label: t.translate("words.CogsTracking"),
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.isTrackingHargaPokok
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.isTrackingHargaPokok
                  ? t.translate("words.yes")
                  : t.translate("words.no")}
              </TableWrapper.Status>
            ),
          },
          {
            label: t.translate("words.CogsMeans"),
            searchable: false,
            key: "hargaPokok",
            sortable: false,
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => `Rp. ${format(item.hargaPokok)}`,
          },
          // {
          //   label: "Date",
          //   type: TableWrapper.dataTypes.DATE,
          //   render: (item) => moment(item.date).format("lll"),
          // },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              if (premium === "USER PREMIUM") {
                setSelectedItem(null);
                setShowForm(true);
              } else if (
                premium == "User Ini Tidak Premium" &&
                produkqty < 100
              ) {
                setSelectedItem(null);
                setShowForm(true);
              } else if (
                premium == "User Ini Tidak Premium" &&
                produkqty >= 100
              ) {
                // showProdukDialog()
                setShowDialogProduk(true);
              }
              // console.log((premium !== 'USER PREMIUM' && produkqty > 5))
            },
            // !(premium !== 'USER PREMIUM' && produkqty > 5)
            // premium === 'USER PREMIUM' ? true : produkqty >= 7 ? false : true
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate("words.delete")}`,
            "mdi mdi-trash-can",
            (item) => {
              deleteItem(item);
            },
            true
          ),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', () => console.log('request delete'), true)
        ]}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "posOutletId.equals": props.match.params.outletId,
                npwpHeadId: props.match.params.npwpHeadId,
              };
              // console.log(query, query)

              let page = await productService.getProductPage(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];
              setProdukQty(page.headers["x-total-count"]);
              // console.log(showDialog);
              resolve(page);
            } catch (err) {
              // resolve(err)
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <ProductForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />
      <Dialog
        id="main-dialog"
        visible={showDialogProduk}
        onRequestClose={() => {}}
        aria-labelledby="main-dialog-title"
        style={{ backgroundColor: "white", width: "400px" }}
      >
        <DialogHeader style={{ paddingBottom: "0" }}>
          <Button
            id="custom-themed-button-2"
            buttonType="icon"
            themeType="outline"
            theme="primary"
            aria-label="Close"
            onClick={() => {
              setShowDialogProduk(false);
            }}
          >
            <ClearSVGIcon style={{ color: "red" }} />
          </Button>
        </DialogHeader>
        <DialogContent style={{ textAlign: "center" }}>
          {/* <Card style={{textAlign:'center',width:'400px',paddingTop:'20px',backgroundColor:'#c6d4e1'}}> */}
          <h3 style={{ textAlign: "center", margin: "0" }}>
            Anda sudah mencapai batas untuk jumlah penginputan produk (100/100
            Produk) Silahkan berlangganan premium untuk dapat memasukkan lebih
            banyak produk
          </h3>
          <Button
            id="lihat-premium"
            theme="primary"
            themeType="contained"
            style={{ color: "white", margin: "0", marginTop: "15px" }}
            onClick={() => {
              setShowDialogPremium(true);
            }}
          >
            Lihat Premium
          </Button>
         <PremiumPackage 
          visible={showDialogPremium}
          onRequestClose={()=>{
            setShowDialogPremium(false)
          }}
         />
          {/* </Card> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default inject("navigationStore", "modalStore")(observer(Product));
