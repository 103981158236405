import React from "react";
import "./Product.scss";

import developerPM_L from "../../assets/product_v2/developer-picturemark-l.svg";
import developerLT_L from "../../assets/product_v2/developer-logotype-l.svg";
import developerPM_D from "../../assets/product_v2/developer-picturemark-d.svg";
import developerLT_D from "../../assets/product_v2/developer-logotype-d.svg";
import efilingPM_L from "../../assets/product_v2/e-filing-picturemark-l.svg";
import efilingLT_L from "../../assets/product_v2/e-filing-logotype-l.svg";
import efilingPM_D from "../../assets/product_v2/e-filing-picturemark-d.svg";
import efilingLT_D from "../../assets/product_v2/e-filing-logotype-d.svg";
import epptPM_L from "../../assets/product_v2/e-ppt-picturemark-l.svg";
import epptLT_L from "../../assets/product_v2/e-ppt-logotype-l.svg";
import epptPM_D from "../../assets/product_v2/e-ppt-picturemark-d.svg";
import epptLT_D from "../../assets/product_v2/e-ppt-logotype-d.svg";
import halonaPM_L from "../../assets/product_v2/halonasoft-picturemark-l.svg";
import halonaLT_L from "../../assets/product_v2/halonasoft-logotype-l.svg";
import halonaPM_D from "../../assets/product_v2/halonasoft-picturemark-d.svg";
import halonaLT_D from "../../assets/product_v2/halonasoft-logotype-d.svg";
import ebillingPM_L from "../../assets/product_v2/id-billing-picturemark-l.svg";
import ebillingLT_L from "../../assets/product_v2/id-billing-logotype-l.svg";
import ebillingPM_D from "../../assets/product_v2/id-billing-picturemark-d.svg";
import ebillingLT_D from "../../assets/product_v2/id-billing-logotype-d.svg";
import tarraPM_L from "../../assets/product_v2/tarra-picturemark-l.svg";
import tarraLT_L from "../../assets/product_v2/tarra-logotype-l.svg";
import tarraPM_D from "../../assets/product_v2/tarra-picturemark-d.svg";
import tarraLT_D from "../../assets/product_v2/tarra-logotype-d.svg";
import tarraH2hPM_L from "../../assets/product_v2/tarra-h2h-picturemark-l.svg";
import tarraH2hLT_L from "../../assets/product_v2/tarra-h2h-logotype-l.svg";
import tarraH2hPM_D from "../../assets/product_v2/tarra-h2h-picturemark-d.svg";
import tarraH2hLT_D from "../../assets/product_v2/tarra-h2h-logotype-d.svg";
import ebupotPM_L from "../../assets/product_v2/e-bupot-picturemark-l.svg";
import ebupotLT_L from "../../assets/product_v2/e-bupot-logotype-l.svg";
import ebupotPM_D from "../../assets/product_v2/e-bupot-picturemark-d.svg";
import ebupotLT_D from "../../assets/product_v2/e-bupot-logotype-d.svg";
import payjakPM_L from "../../assets/product_v2/payjak-picturemark-l.svg";
import payjakLT_L from "../../assets/product_v2/payjak-logotype-l.svg";
import payjakPM_D from "../../assets/product_v2/payjak-picturemark-d.svg";
import payjakLT_D from "../../assets/product_v2/payjak-logotype-d.svg";
import mpnPM_L from "../../assets/product_v2/mpn-picturemark-l.svg";
import mpnLT_L from "../../assets/product_v2/mpn-logotype-l.svg";
import mpnPM_D from "../../assets/product_v2/mpn-picturemark-d.svg";
import mpnLT_D from "../../assets/product_v2/mpn-logotype-d.svg";
import sipPM_L from "../../assets/product_v2/sip-picturemark-l.svg";
import sipLT_L from "../../assets/product_v2/sip-logotype-l.svg";
import sipPM_D from "../../assets/product_v2/sip-picturemark-d.svg";
import sipLT_D from "../../assets/product_v2/sip-logotype-d.svg";
import ebunifikasiPM_L from "../../assets/product_v2/e-bunifikasi-picturemark-l.svg";
import ebunifikasiLT_L from "../../assets/product_v2/e-bunifikasi-logotype-l.svg";
import ebunifikasiPM_D from "../../assets/product_v2/e-bunifikasi-picturemark-d.svg";
import ebunifikasiLT_D from "../../assets/product_v2/e-bunifikasi-logotype-d.svg";
import opkuPM_L from "../../assets/product_v2/op-ku-picturemark-l.svg";
import opkuLT_L from "../../assets/product_v2/op-ku-logotype-l.svg";
import opkuPM_D from "../../assets/product_v2/op-ku-picturemark-d.svg";
import opkuLT_D from "../../assets/product_v2/op-ku-logotype-d.svg";
import scanBunifikasiPM_L from "../../assets/product_v2/scan-bunifikasi-picturemark-l.svg";
import scanBunifikasiLT_L from "../../assets/product_v2/scan-bunifikasi-logotype-l.svg";
import scanBunifikasiPM_D from "../../assets/product_v2/scan-bunifikasi-picturemark-d.svg";
import scanBunifikasiLT_D from "../../assets/product_v2/scan-bunifikasi-logotype-d.svg";
import sobatBukuWebPM_L from "../../assets/product_v2/sobat-buku-picturemark-l.png";
import sobatBukuWebLT_L from "../../assets/product_v2/sobat-buku-logotype-l.png";
import sobatBukuWebPM_D from "../../assets/product_v2/sobat-buku-picturemark-d.png";
import sobatBukuWebLT_D from "../../assets/product_v2/sobat-buku-logotype-d.png";
import posSobatPajakLT_D from "../../assets/product_v2/pos-sobatbuku-logotype-d.svg";
import posSobatPajakLT_L from "../../assets/product_v2/pos-sobatbuku-logotype-d.svg";
import { constant } from "lodash";
import Logo from "./Logo";
// import mpnPM_L from '../../assets/product_v3/mpn-logo.png';
// import mpnLT_L from '../../assets/product_v3/mpn-logo.png';
// import mpnPM_D from '../../assets/product_v3/mpn-logo.png';
// import mpnLT_D from '../../assets/product_v3/mpn-logo.png';

let appLogo = {
  developer: {
    inverse: [developerPM_L, developerLT_L],
    default: [developerPM_D, developerLT_D],
  },
  ebunifikasi: {
    inverse: [ebunifikasiPM_L, ebunifikasiLT_L],
    default: [ebunifikasiPM_D, ebunifikasiLT_D],
  },
  ebupot: {
    inverse: [ebupotPM_L, ebupotLT_L],
    default: [ebupotPM_D, ebupotLT_D],
  },
  efiling: {
    inverse: [efilingPM_L, efilingLT_L],
    default: [efilingPM_D, efilingLT_D],
  },
  eppt: {
    inverse: [epptPM_L, epptLT_L],
    default: [epptPM_D, epptLT_D],
  },
  halona: {
    inverse: [halonaPM_L, halonaLT_L],
    default: [halonaPM_D, halonaLT_D],
  },
  ebilling: {
    inverse: [ebillingPM_L, ebillingLT_L],
    default: [ebillingPM_D, ebillingLT_D],
  },
  mpn: {
    inverse: [mpnPM_L, mpnLT_L],
    default: [mpnPM_D, mpnLT_D],
  },
  opku: {
    inverse: [opkuPM_L, opkuLT_L],
    default: [opkuPM_D, opkuLT_D],
  },
  payjak: {
    inverse: [payjakPM_L, payjakLT_L],
    default: [payjakPM_D, payjakLT_D],
  },
  scanBunifikasi: {
    inverse: [scanBunifikasiPM_L, scanBunifikasiLT_L],
    default: [scanBunifikasiPM_D, scanBunifikasiLT_D],
  },
  sobatbukuweb: {
    inverse: [sobatBukuWebPM_L, sobatBukuWebLT_L],
    default: [sobatBukuWebPM_D, sobatBukuWebLT_D],
  },
  posSobatBukuWeb: {
    inverse: [posSobatPajakLT_L, sobatBukuWebLT_L],
    default: [posSobatPajakLT_D, sobatBukuWebLT_D],
  },
};

const Product = ({
  appname = null,
  inverse = false,
  style = {},
  subname = null,
  className = "",
  typeOnly = false,
  showPajakku = false,
}) => {
  const getSource = (idx) => {
    return appLogo[appname][inverse ? "inverse" : "default"][idx];
  };

  return typeOnly ? (
    <img style={style} src={getSource(1)} alt="logo" />
  ) : (
    <div
      className={`mpk-product-logo mpk-flex align-center ${
        inverse ? "inverse" : ""
      } ${className}`}
      style={style}
    >
      <img className="picture-mark" src={getSource(0)} alt="logo" />
      <div className="mpk-flex align-center">
        <img className="logo-type" src={getSource(1)} alt="logo" />
        {showPajakku && (
          <Logo
            light={inverse}
            style={{ height: 19, marginTop: 1, marginLeft: 2 }}
          />
        )}
        {subname ? (
          <div
            className="mpk-margin-N left mpk-font size-NS"
            style={{ opacity: 0.64 }}
          >
            {subname}
          </div>
        ) : null}
      </div>
    </div>
  );
};

Product.names = {
  DEVELOPER: "developer",
  EFILING: "efiling",
  SOBATBUKUWEB: "sobatBukuWeb",
  EPPT: "eppt",
  HALONA: "halona",
  EBILING: "ebilling",
  TARRA: "tarra",
  TARRA_H2H: "tarraH2h",
  EBUPOT: "ebupot",
  PAYJAK: "payjak",
  MPN: "mpn",
  SIP: "sip",
  UNIFIKASI: "unifikasi",
  OPKU: "opku",
  SCAN_BUNIFIKASI: "scanBunifikasi",
};

export default Product;
