import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import premiumService from "./Premium.service";
import service from "../AkunNpwp/AkunNpwp.service";
import iziToast from "izitoast";
import moment from "moment";
import {
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
  TextField,
  Button,
  Text,
  Dialog,
  DialogContent,
} from "react-md";

import {
  defaultQueryParameter,
  defauultQuery,
  sort,
} from "../../libs/react-mpk/config/constant";
import ManajemenRakForm from "../ManajemenRak/ManajemenRakForm";
import PremiumForm from "./PremiumForm";

const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const ON_REQUEST = 1
const AKTIF = 2
const DITOLAK = 3
const CANCEL = 4
const KADALUARSA = 5
const ALL_APP = 1
const SOBAT_POS = 7

const Premium = ({ className = "", showCommandbar = true, ...props }) => {
  const baseId = "premium-table";
  const date = new Date();
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAcc, setShowAcc] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const [showReject, setShowReject] = useState(false)
  const [message,setMessage] = useState('')
  // const dialogBatal = (item) => {
  //   setSelectedItem(item)
  //   setShowBatal(true)
  // }

  const [query2, setQuery2] = useState(
    defaultQuery({
      page: 0, 
      tanggalPremiumAwal: "",
      tanggalPremiumAkhir: "",
    })
  );

  const changeDate = (e) => {
    setQuery2({ ...query2, [e.target.name]: e.target.value });
    console.log(query2);
  };

  var itemActions = [
    new TableWrapper.action(
      `${t.translate('words.accept')}`, 
      "mdi mdi-import",
      (item) => {
        setSelectedItem(item)
        setShowAcc(true)
      },
      item => item.status == 1 ? true : false
    ),

    new TableWrapper.action(
      `${t.translate('words.cancel')}`,
      "mdi mdi-cancel",
      (item) => {
        setSelectedItem(item)
        setShowCancel(true)
      },
      item => item.status == 1 ? true : false
    ),

    new TableWrapper.action(
      `${t.translate('words.tolak')}`,
      "mdi mdi-cancel",
      (item) => {
        setSelectedItem(item)
        setShowReject(true)
      },
      item => item.status == 1 ? true : false
    )
    
  ]

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={"Premium"}
        className={className}
        hintMessage={t.translate("modules.premium.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.premium.hint.message")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        multiColumnFilter={false}
        useFilter={false}
        onFetchData={async (query) => {
          // for (let tgl of Object.keys(query)) {
          //   if (
          //     tgl.includes("tanggalPremiumAwal") ||
          //     tgl.includes("tanggalPremiumAkhir")
          //   ) {
          //     query[tgl] = new Date(
          //       moment(new Date(query[tgl])).format("YYYY-MM-DD HH:mm:ss")
          //     );
          //   }
          // }
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                // ...query2,
                'app.in':'1,7',
              };
              let page = await premiumService.getPagePremium(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];
              resolve(page);
            } catch (err) {
              iziToast.error({
                message: err.message,
              });
            }
          });
        }}
        sideHeader={
          <>
          {/* Dialog Acc */}
          <Dialog
            visible={showAcc}
          >
            <DialogContent>
            <Text>{t.translate(`words.confirmTerima`)}</Text>
            <TextField 
            id="keterangan"
            label="Keterangan"
            type="text"
            style={{margin:'20px'}}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            />
          <Button
            onClick={async (callback) => {
              try {
                let {data,res} = await premiumService.accPremium(selectedItem.id,{message:message})
                setShowAcc(false)
                document.getElementsByClassName('mdi-reload')[0].click()
              } catch (error) {
                iziToast.error(error.message)
              }
            }}
            theme="primary"
            themeType="contained"
            style={{ marginRight: '2%' }}
          >
            {t.translate(`words.submit`)}
          </Button>
          <Button
            onClick={() => {
              setShowAcc(false)
            }}
            theme="primary"
            themeType="contained"
          >
            {t.translate(`words.cancel`)}
          </Button>
            </DialogContent>
          </Dialog>

          {/* // Dialog Cancel */}
          <Dialog
            visible={showCancel}
          >
            <DialogContent>
              <Text>{t.translate(`words.confirmBatalkan`)}</Text>
              <TextField 
            id="keterangan"
            label="Keterangan"
            type="text"
            style={{margin:'20px'}}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            />
              <Button
            onClick={async (callback) => {
              try {
                let { data,res } = await premiumService.cancelPremium(selectedItem.id,{message:message})
                setShowCancel(false)
                document.getElementsByClassName('mdi-reload')[0].click()
              } catch (error) {
                iziToast.error(error.message)
              }
            }}
            theme="primary"
            themeType="contained"
            style={{ marginRight: '2%' }}
          >{t.translate(`words.yes`)}
          </Button>
          <Button
            onClick={() => {
              setShowCancel(false)
            }}
            theme="primary"
            themeType="contained"
          >
            {t.translate(`words.no`)}
          </Button>
            </DialogContent>
          </Dialog>
          {/* Dialog Reject */}
          <Dialog
            visible={showReject}
          >
            <DialogContent>
              <Text>{t.translate(`words.confirmTolak`)}</Text>
              <TextField 
            id="keterangan"
            label="Keterangan"
            type="text"
            style={{margin:'20px'}}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            />
              <Button
            onClick={async (callback) => {
              try {
                let {data,res} = await premiumService.rejectPremium(selectedItem.id,{message:message})
                setShowReject(false)
                document.getElementsByClassName('mdi-reload')[0].click()
              } catch (error) {
                iziToast.error(error.message)
              }
            }}
            theme="primary"
            themeType="contained"
            style={{ marginRight: '2%' }}
          >{t.translate(`words.yes`)}
          </Button>
          <Button
            onClick={() => {
              setShowReject(false)
            }}
            theme="primary"
            themeType="contained"
          >
            {t.translate(`words.no`)}
          </Button>
            </DialogContent>
          </Dialog>
          </>
        }
        // extraFilter={[
        //   <TextField
        //     id="startDatePembelian"
        //     label="Tanggal Premium Awal"
        //     type="date"
        //     name="tanggalPremiumAwal"
        //     key="tanggalPremiumAwal"
        //     value={query2.tanggalPremiumAwal}
        //     onChange={(e) => changeDate(e)}
        //     max={moment(date).format("YYYY-MM-DD")}
        //     style={{ marginBottom: "10%" }}
        //   />,
        //   <TextField
        //     id="endDatePembelian"
        //     label="Tanggal Premium Akhir"
        //     type="date"
        //     name="tanggalPremiumAkhir"
        //     key="tanggalPremiumAkhir"
        //     value={query2.tanggalPremiumAkhir}
        //     onChange={(e) => changeDate(e)}
        //     min={query2.tanggalPremiumAwal}
        //     // max={new Date()}
        //     style={{ marginBottom: "15%" }}
        //   />,
        // ]}
        columns={[
          // {
          //   label: "Nama Pengguna",
          //   searchable: true,
          //   sortable: false,
          //   key: "nama",
          //   definition: {
          //     criterias: ["contains", "equals", "in"],
          //     inputType: TableWrapper.inputTypes.INPUT,
          //   },
          //   render: (item) => item.pengguna && item.pengguna.nama,
          // },
          {
            label: "Nama Aplikasi",
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.app == ALL_APP
                  ? TableWrapper.Status.types.SUCCESS
                  : item.app == SOBAT_POS
                  ? TableWrapper.Status.types.ERROR
                  :  ""
                }
              >
                  {item.app ? 'SOBAT POS':'SEMUA APLIKASI'}
              </TableWrapper.Status>
            )
          },
          {
            label: "Status",
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.status == AKTIF
                    ? TableWrapper.Status.types.SUCCESS
                    : item.status == CANCEL
                    ? TableWrapper.Status.types.ERROR
                    : item.status == ON_REQUEST
                    ? TableWrapper.Status.types.PROGRESS
                    : item.status == KADALUARSA
                    ? TableWrapper.Status.types.IDLE
                    : TableWrapper.Status.types.ERROR
                    ? item.status == DITOLAK
                    : ""
                }
              >
                {item.status == AKTIF
                  ? "AKTIF"
                  : item.status == CANCEL
                  ? "CANCEL"
                  : item.status == ON_REQUEST
                  ? "ON REQUEST"
                  : item.status == KADALUARSA
                  ? "KADALUARSA"
                  : item.status == DITOLAK
                  ? "DITOLAK"
                  : ""}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Tanggal Request",
            searchable: false,
            sortable: false,
            key: "tanggalPremiumAwal",
            definition: {
              criterias: ["equals", "greaterThan", "lessThan"],
              inputType: TableWrapper.inputTypes.DATE,
            },
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },
          {
            label: "Durasi (Bulan)",
            searchable: true,
            sortable: false,
            key: "durasi",
            definition: {
              criterias: ["contains", "equals", "in"],
              inputType: TableWrapper.inputTypes.INPUT,
            },
            render: (item) => item.bulan + " Bulan",
          }
          // {
          //   label: "Tanggal Berakhir",
          //   searchable: false,
          //   sortable: false,
          //   key: "tanggalPremiumAkhir",
          //   definition: {
          //     criterias: ["equals", "greaterThan", "lessThan"],
          //     inputType: TableWrapper.inputTypes.DATE,
          //   },
          //   render: (item) => moment(item.tanggalDibuat).add(item.bulan,'M').format("lll"),
          // },
          
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
                setShowForm(true);
                setSelectedItem(null);
            },
            true
          ),
        ]}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
        <PremiumForm
            visible={showForm}
            onRequestClose={() => {
                setShowForm(false);
                setSelectedItem(null);
            }}
            item={selectedItem}
        />
    </>
  );
};

export default Premium;
