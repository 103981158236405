import { crud, http } from "../../libs/react-mpk/services";

let multiOutletService = new crud("/outlet");
let serviceUrl = "/outlet";
let npwpHeadId = localStorage.getItem('npwpHeadId')

multiOutletService.createMultiOutlet = async (data) => {
  return http.request({ 
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

multiOutletService.updateMultiOutlet = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

multiOutletService.updateStatus = async (id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/update_status/${id}`,
  });
};

multiOutletService.getOneMultiOutlet = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

multiOutletService.deleteMultiOutlet = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

multiOutletService.getPageMultiOutlet = async (query) => {
  query = { 
    ...query,
    // 'npwpHeadId.equals': npwpHeadId
  };

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

export default multiOutletService;
